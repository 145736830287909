const mutations = {
	setUser(state, data) {
		state.user = data;
	},
	setOrganization(state, data) {
		state.organization = data;
	},
	setOrganizationUser(state, data) {
		state.organizationUser = data;
	},
	setBusiness(state, data) {
		state.business = data;
	},
	setNotification(state, data) {
		state.notification = data;
	},
};

export default mutations;
