<template>
	<div>
		<label v-if="label" class="form-control-label">{{ label }}</label>
		<div class="form-control-auto form-control">
			<div v-for="(option, index) in options" :key="index">
				<div class="form-check">
					<input
						class="form-check-input"
						type="radio"
						name="formRadioGroup"
						v-model="model"
						:disabled="disabled"
						:value="option.value"
						:id="option.name"
					/>
					<label class="form-check-label" :for="option.name">{{
						option.name
					}}</label>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'button-radio-group',
	props: {
		options: {
			type: Array,
			description:
				'Radio options. Should be an array of objects {value: "", name: ""}',
			default: () => [],
		},
		disabled: {
			type: Boolean,
		},
		value: {
			type: String,
			description: 'Radio value',
		},
		buttonClasses: {
			type: [String, Object],
			description: 'Inner button css classes',
		},
		label: {
			type: String,
			default: null,
		},
	},
	model: {
		prop: 'value',
		event: 'change',
	},
	computed: {
		model: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('change', val);
			},
		},
	},
};
</script>
<style></style>
