import { format } from 'date-fns-tz';
import getters from '../store/getters';
import { Timestamp } from 'firebase/firestore';

export default {
	isBlank(str) {
		return !str || /^\s*$/.test(str);
	},
	returnTimestamp() {
		return Timestamp.now();
	},
	maskPhoneNumber(value) {
		var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
		return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
	},
	formatDate(value, formatString) {
		if (value == null) {
			return null;
		}
		var dateString = formatString;
		if (dateString == null) {
			dateString = 'MMMM dd, yyyy';
		}
		return format(value, dateString);
	},
	userReturnSignedWaiver(userData, activeWaiverId) {
		var activeWavier = null;
		if (userData.waiverForms != null && activeWaiverId != null) {
			let waiverSigned = Object.values(userData.waiverForms).find(
				(waiver) => waiver.waiverId === activeWaiverId
			);
			activeWavier = waiverSigned;
			return activeWavier;
		} else {
			return activeWavier;
		}
	},
	userReturnSubscription(userData) {
		var activeSubscription = null;
		if (userData.subscription) {
			Object.values(userData.subscription).forEach((subscription) => {
				if (subscription.subscriptionIsActive) {
					activeSubscription = subscription;
				}
			});
			return activeSubscription;
		} else {
			return activeSubscription;
		}
	},
	amountToString(amount) {
		return Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
		}).format(amount);
	},
	getReoccurrenceDates(startDate, endDate, reoccurrenceString) {
		var orgTimezone = this.getters.getOrganization();
		console.log(orgTimezone);
		var date = new Date(startDate);
		var result = [];
		result.push(new Date(date));

		if (reoccurrenceString == 'everyDay') {
			var newDate = date;
			for (let index = 1; index < 730; index++) {
				newDate.setDate(newDate.getDate() + 1);
				result.push(new Date(newDate));
			}
			return result;
		} else if (reoccurrenceString == 'everyWeek') {
			var newDate = date;
			for (let index = 1; index < 104; index++) {
				newDate.setDate(newDate.getDate() + 7);
				result.push(new Date(newDate));
			}
			return result;
		} else if (reoccurrenceString == 'everyMonth') {
			var newDate = date;
			for (let index = 1; index < 24; index++) {
				newDate.setMonth(newDate.getMonth() + 1);
				result.push(new Date(newDate));
			}
			return result;
		} else if (reoccurrenceString == 'everyYear') {
			var newDate = date;
			for (let index = 1; index < 2; index++) {
				newDate.setFullYear(newDate.getFullYear() + 1);
				result.push(new Date(newDate));
			}
			return result;
		} else if (reoccurrenceString == 'biWeekly') {
			var newDate = date;
			for (let index = 1; index < 52; index++) {
				newDate.setDate(newDate.getDate() + 14);
				result.push(new Date(newDate));
			}
			return result;
		} else {
			return result;
		}
	},
	timeSince(date) {
		var seconds = Math.floor((new Date() - date) / 1000);

		var interval = seconds / 31536000;

		if (interval > 1) {
			const years = Math.floor(interval);
			if (years == 1) {
				return years + ' year';
			}
			return years + ' years';
		}
		interval = seconds / 2592000;
		if (interval > 1) {
			const months = Math.floor(interval);
			if (months == 1) {
				return months + ' month';
			}
			return months + ' months';
		}
		interval = seconds / 86400;
		if (interval > 1) {
			const days = Math.floor(interval);
			if (days == 1) {
				return days + ' day';
			}
			return days + ' days';
		}
		interval = seconds / 3600;
		if (interval > 1) {
			const hours = Math.floor(interval);
			if (hours == 1) {
				return hours + ' hour';
			}
			return hours + ' hours';
		}
		interval = seconds / 60;
		if (interval > 1) {
			const minutes = Math.floor(interval);
			if (minutes == 1) {
				return minutes + ' minute';
			}
			return minutes + ' minutes';
		}
		return Math.floor(seconds) + ' seconds';
	},
};
