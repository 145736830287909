<template>
	<div>
		<Card>
			<b-row align-v="center" slot="header">
				<b-col>
					<h3 class="mb-0">{{ eventData.eventTitle }}</h3>
				</b-col>
			</b-row>
			<b-row v-if="eventData.eventSpeaker" align-v="center" slot="footer">
				<b-col>
					<div class="d-flex justify-content-start align-items-center">
						<b-avatar
							:src="
								allSpeakers.find((e) => e.uid == eventData.eventSpeaker[0])
									.speakerPhoto
							"
							class="avatar-sm"
							style="background-color: transparent"
						/>
						<h5 class="my-0 mx-2">
							{{
								allSpeakers.find((e) => e.uid == eventData.eventSpeaker[0])
									.speakerName
							}}
						</h5>
					</div>
				</b-col>
			</b-row>
			<b-row class="d-flex align-items-center" style="gap: 10px">
				<b-col cols="auto" class="flex-grow-1 p-0">
					<div class="align-items-left text-left flex-grow-1">
						<div class="d-flex">
							<i class="fas fa-calendar mr-2"></i>
							<h5>
								{{
									`${$helpers.formatDate(
										eventData.eventStart.toDate(),
										'EEEE, MMMM dd, yyyy - hh:mm a'
									)}`
								}}
							</h5>
						</div>
						<div class="d-flex">
							<i class="fas fa-location-arrow mr-2"></i>
							<h5>
								{{ this.eventLocation }}
							</h5>
						</div>
					</div>
				</b-col>
			</b-row>
		</Card>
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import Badge from '../../../components/Badge.vue';
import reoccuringOptions from '../../../data/reoccuringOptions';
export default {
	props: {
		eventData: {
			type: Object,
			default: null,
		},
		ticketsSold: {
			type: Number,
			default: 0,
		},
		allSpeakers: {
			type: Array,
			default: [],
		},
	},
	components: {
		Card,
		Badge,
	},
	data() {
		return {
			reoccuringOptions,
		};
	},
};
</script>
