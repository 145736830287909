<template>
	<footer class="footer px-4 mx-2">
		<b-row align-v="center" class="justify-content-lg-between">
			<b-col lg="10">
				<b-nav
					v-if="false"
					class="nav-footer justify-content-lg-start justify-content-center"
				>
					<b-nav-item
						:href="DominionInformation.dominionWebsite"
						target="_blank"
					>
						© {{ year }} {{ DominionInformation.dominionFullName }}
					</b-nav-item>
				</b-nav>
			</b-col>
			<b-col lg="2" class="py-0">
				<a
					target="_blank"
					class="nav-footer justify-content-lg-end justify-content-center"
					:href="DominionInformation.dominionWebsite"
				>
					<b-img fluid :src="SNLogoHorizontalBW" style="opacity: 0.5" />
				</a>
			</b-col>
		</b-row>
	</footer>
</template>
<script>
import { DominionInformation } from '../../../resources/DominionInformation';
import SNLogoHorizontalBW from '../../../../public/img/brand/SNLogoHorizontalBW.png';

export default {
	data() {
		return {
			DominionInformation,
			SNLogoHorizontalBW,
			year: new Date().getFullYear(),
		};
	},
};
</script>
<style></style>
