import Excel from 'exceljs';
import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import {
	getOrganizationFirestore,
	getOrganizationStorage,
} from '../resources/organizationFirebase';
import helpers from '../helpers/helpers';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

export default class ReportsManager {
	static generateLeadList(organization, businessId) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore(organization);
			const businessRef = doc(firestore, `business/${businessId}`);

			var date = new Date();

			const leadListRef = collection(firestore, `${businessRef.path}/leadList`);

			getDocs(leadListRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						var allUserData = [];
						const total = snapshot.docs.length;
						var counter = 0;
						snapshot.docs.forEach((document) => {
							const uid = document.data().uid;
							const uploadTimestamp = document.data().uploadTimestamp;
							getDoc(doc(firestore, `users/${uid}`))
								.then((userSnap) => {
									if (userSnap.exists()) {
										const userSnapData = userSnap.data();
										allUserData.push({
											data: userSnapData,
											timestamp: uploadTimestamp.toDate(),
										});
									}
									if (counter == total - 1) {
										return this.exportUsers(organization, businessId, allUserData)
											.then((data) => {
												resolve(data);
											})
											.catch((error) => {
												reject(error);
											});
									}
									counter += 1;
								})
								.catch((error) => {
									if (counter == total - 1) {
										return this.exportUsers(organization, businessId, allUserData)
											.then((data) => {
												resolve(data);
											})
											.catch((error) => {
												reject(error);
											});
									}
									counter += 1;
								});
						});
					} else {
						resolve('No users found.');
					}
				})
				.catch((error) => {
					resolve(error.message);
				});
		});
	}

	static exportUsers(organization, businessId, allUsers) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore(organization);
			const businessRef = doc(firestore, `business/${businessId}`);
			const reportRef = collection(firestore, `${businessRef.path}/reports`);
			const fileName = `lead-list_report-${helpers.formatDate(
				new Date(),
				'yyyy-MM-dd'
			)}.csv`;

			const workbook = new Excel.Workbook();
			const worksheet = workbook.addWorksheet();

			worksheet.columns = [
				{ header: 'First Name', key: 'firstName' },
				{ header: 'Last Name', key: 'lastName' },
				{ header: 'Email', key: 'email' },
				{ header: 'Username', key: 'username' },
				{ header: 'Created', key: 'uploadTimestamp' },
			];

			//set columns length to atleast the size of header
			worksheet.columns.forEach((column) => {
				column.width = column.header.length < 12 ? 12 : column.header.length;
			});
			//set header row to bold
			worksheet.getRow(1).font = { bold: true };
			allUsers.forEach((item) => {
				const userData = item.data;
				const userTimestamp = item.timestamp;

				var data = {
					firstName: userData.firstName,
					lastName: userData.lastName,
					email: userData.email,
					username: userData.username,
					uploadTimestamp: userTimestamp,
				};
				worksheet.addRow(data);
			});
			return this.exportReport(organization, workbook, reportRef, fileName)
				.then((data) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	static exportReport(organization, workbook, reportRef, fileName) {
		return new Promise((resolve, reject) => {
			workbook.csv
				.writeBuffer()
				.then((buffer) => {
					const blob = new Blob([buffer], { type: 'text/csv' });
					const storage = getOrganizationStorage(organization);
					const newReportRef = doc(reportRef);
					const newReportStorageRef = ref(storage, `${reportRef.path}/${fileName}`);
					//saveAs(blob, fileName);
					return uploadBytes(newReportStorageRef, blob)
						.then((snapshot) => {
							return getDownloadURL(snapshot.ref)
								.then((downloadURL) => {
									const reportData = {
										reportId: newReportRef.id,
										reportTitle: fileName,
										reportDownloadLink: downloadURL,
										uploadTimestamp: helpers.returnTimestamp(),
									};
									return setDoc(newReportRef, reportData)
										.then(() => {
											return resolve(reportData);
										})
										.catch((error) => {
											return reject(error.message);
										});
								})
								.catch((error) => {
									return reject(error.message);
								});
						})
						.catch((error) => {
							return reject(error.message);
						});
				})
				.catch((error) => {
					return reject(error);
				});
		});
	}
}
