<template>
	<div>
		<DashboardHeader :title="$route.name" :buttonItems="buttonItems" />
		<div class="container-fluid mt--6">
			<VideoTable
				:isLoading="isLoading"
				:tableData="allVideosItems"
				@adjustLockClicked="adjustLockClicked"
				@deleteClicked="askToDelete"
			/>
		</div>
	</div>
</template>
<script>
import DashboardHeader from '../HeaderFooterComponents/DashboardHeader.vue';
import VideoTable from '../../Components/VideoViews/VideoTable.vue';
import { getOrganizationFirestore } from '../../../resources/organizationFirebase';
import { mapActions, mapGetters } from 'vuex';
import { collection, doc, getDocs, setDoc } from '@firebase/firestore';

export default {
	components: { DashboardHeader, VideoTable },
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	data() {
		return {
			isLoading: true,
			allVideosItems: [],
			buttonItems: [],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		getVideos() {
			this.isLoading = true;
			this.allVideosItems = [];
			const firestore = getOrganizationFirestore(this.organization);
			const firestoreRf = collection(firestore, 'videos');
			getDocs(firestoreRf).then((snapshot) => {
				if (!snapshot.empty) {
					snapshot.forEach((item) => {
						var video = item.data();
						this.allVideosItems.push(video);
					});
					this.isLoading = false;
				} else {
					this.isLoading = false;
				}
			});
		},
		adjustLockClicked(item) {
			const isLocked = item.isLocked != null ? item.isLocked : true;
			const firestore = getOrganizationFirestore(this.organization);
			const videoRef = doc(firestore, `videos/${item.videoId}`);
			var videoItem = this.allVideosItems.find((e) => e.videoId == item.videoId);
			videoItem['isLocked'] = !isLocked;
			setDoc(videoRef, { isLocked: !isLocked }, { merge: true })
				.then(() => {})
				.catch((error) => {
					videoItem['isLocked'] = isLocked;
					this.showNotification({
						type: 'danger',
						message: error.message,
					});
				});
		},
		askToDelete(item) {
			this.$emit('deleteClicked', item);
		},
	},
	mounted() {
		this.getVideos();
	},
};
</script>
