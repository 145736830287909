import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
	apiKey: 'AIzaSyBDDjampXfS1fIjkdYDnsWk_RHckMblUNc',
	authDomain: 'conferenceadmin.firebaseapp.com',
	projectId: 'conferenceadmin',
	storageBucket: 'conferenceadmin.appspot.com',
	messagingSenderId: '304135510724',
	appId: '1:304135510724:web:34fe2eda175975008a8791',
	measurementId: 'G-EPCDFGDJ81',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Authentication
const auth = getAuth(app);

// Firestore
const firestore = getFirestore(app);

// Functions
const functions = getFunctions(app);

export { auth, firestore, functions };
