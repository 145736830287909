<template>
	<div>
		<Card no-body>
			<b-row align-v="center" slot="header">
				<b-col cols="8">
					<h3 class="mb-0">
						{{ isActive ? 'Active' : 'Inactive' }} Subscriptions
					</h3>
				</b-col>
				<b-col cols="4" class="text-right">
					<h3 class="mb-0">{{ allSubscriptions.length }} Total</h3>
				</b-col>
			</b-row>
			<b-row v-if="allSubscriptions.length > 0" align-v="center" slot="footer">
				<h6 class="text-muted m-0 px-2">{{ getFooterInformation() }}</h6>
			</b-row>
			<b-list-group>
				<b-list-group-item
					v-for="subscription in allSubscriptions"
					:key="subscription.userSubscription.subscriptionId"
					:style="!isActive ? 'opacity: 0.65' : 'opacity:1'"
					@click="askSubscriptionQuestion(subscription)"
				>
					<div class="d-flex justify-content-between align-items-center">
						<div class="d-flex align-items-center">
							<b-img-lazy
								:src="subscription.product.productMedia.productImage"
								rounded
								class="mr-2 avatar-lg"
							/>
							<div>
								<h5
									class="mb-0 mr-2"
									style="
										overflow: hidden;
										text-overflow: ellipsis;
										display: -webkit-box;
										-webkit-line-clamp: 1;
										-webkit-box-orient: vertical;
									"
								>
									{{ subscription.product.productTitle }}
								</h5>
								<h6
									class="text-muted m-0"
									style="
										overflow: hidden;
										text-overflow: ellipsis;
										display: -webkit-box;
										-webkit-line-clamp: 1;
										-webkit-box-orient: vertical;
									"
								>
									{{ getMiddleLabel(subscription) }}
								</h6>
								<h6
									class="text-muted m-0"
									style="
										overflow: hidden;
										text-overflow: ellipsis;
										display: -webkit-box;
										-webkit-line-clamp: 1;
										-webkit-box-orient: vertical;
									"
								>
									{{ getBottomLabel(subscription) }}
								</h6>
							</div>
						</div>
						<div class="d-flex justify-content-end align-items-center m-0 p-0">
							<i class="fas fa-chevron-right ml-2 text-muted"></i>
						</div>
					</div>
				</b-list-group-item>
			</b-list-group>
		</Card>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			:buttonData="modalButtonData"
			@close="showQuestionModal = false"
			@buttonClicked="questionButtonClicked"
		/>
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
export default {
	props: {
		allSubscriptions: {
			type: Array,
			default: [],
		},
		isActive: true,
	},
	components: { Card, QuestionModal },
	data() {
		return {
			showQuestionModal: false,
			subscription: null,
			modalQuestion: '',
			modalButtonData: [
				{
					id: 0,
					title: 'Adjust Next Charge Date',
				},
				{
					id: 1,
					title: 'Adjust Subscription Tokens For Cycle',
				},
				{
					id: 2,
					title: 'Cancel Subscription',
					type: 'danger',
				},
			],
		};
	},
	methods: {
		askSubscriptionQuestion(subscription) {
			if (this.isActive) {
				this.modalQuestion =
					'Do you want to edit this subscription for this user?';
				this.subscription = subscription;
				this.showQuestionModal = true;
			}
		},
		questionButtonClicked(buttonItem) {
			switch (buttonItem.id) {
				case 0:
					this.adjustNextChargeDate();
					break;
				case 1:
					this.adjustSubscriptionTokens();
					break;
				case 2:
					this.cancelSubscription();
					break;
				default:
					return;
			}
		},
		adjustNextChargeDate() {
			console.log(
				`Adjust Next Charge Date: ${this.subscription.product.productTitle}`
			);
		},
		adjustSubscriptionTokens() {
			console.log(`Adjust Tokens: ${this.subscription.product.productTitle}`);
		},
		cancelSubscription() {
			console.log(`Cancel: ${this.subscription.product.productTitle}`);
		},
		getFooterInformation() {
			if (this.isActive) {
				return 'All active subscriptions will be billed approximately at 12:00am PST. You can unsubscribe anytime by clicking on the active subscription.';
			} else {
				return 'Inactive subscriptions can only be re-activated by the user on the mobile app.';
			}
		},
		getMiddleLabel(subscription) {
			if (subscription.userSubscription.subscriptionNextChargeDate) {
				return `Next Charge: ${this.$helpers.formatDate(
					subscription.userSubscription.subscriptionNextChargeDate
				)}`;
			} else if (subscription.userSubscription.subscriptionCancelDate) {
				return `Cancellation Date: ${this.$helpers.formatDate(
					subscription.userSubscription.subscriptionCancelDate
				)}`;
			}
		},
		getBottomLabel(subscription) {
			if (
				subscription.userSubscription.subscriptionTokenQuantity != null &&
				subscription.userSubscription.subscriptionTokenUsed != null
			) {
				return `Tokens Remaining in Cycle: ${
					subscription.userSubscription.subscriptionTokenQuantity -
					subscription.userSubscription.subscriptionTokenUsed
				}`;
			} else if (
				subscription.userSubscription.subscriptionTokenQuantity != null
			) {
				return `Tokens Remaining in Cycle: ${subscription.userSubscription.subscriptionTokenQuantity}`;
			} else if (subscription.userSubscription.subscriptionType == 'event') {
				return `Tokens Remaining in Cycle: Unlimited`;
			} else if (subscription.userSubscription.subscriptionType == 'classes') {
				return `Class Membership Subscription`;
			}
		},
	},
	mounted() {},
};
</script>
