<template>
	<div>
		<DashboardHeaderVue :title="title" />

		<div class="container-fluid mt--6">
			<div class="row">
				<div class="col">
					<div class="card">
						<b-list-group>
							<b-list-group-item v-for="notification in allNotifications">
								<b-row>
									<b-col class="pl-3 pr-1 pb-0" cols="auto">
										<!-- Avatar -->
										<b-img
											alt="Image placeholder"
											:src="organization.appLogo"
											class="avatar"
											rounded="circle"
										/>
									</b-col>
									<b-col class="pl-2 pr-0 pb-0">
										<div class="d-flex align-items-center">
											<div>
												<h4 class="mb-0 mr-1 text-sm">
													{{ organization.appName }}
												</h4>
											</div>
											<div class="text-right text-muted">
												<small>{{ notification.notificationTimestamp }}</small>
											</div>
										</div>
										<p class="text-sm mb-0">
											{{ notification.message }}
										</p>
									</b-col>
									<b-col class="px-2 pb-0" cols="auto">
										<b-dropdown
											:id="notification.messageId"
											variant="link"
											size="sm"
											class="m-2"
											dropleft
											no-caret
										>
											<template v-slot:button-content>
												<span class="btn btn-sm btn-icon-only text-light">
													<i class="fas fa-ellipsis-v mt-2"></i>
												</span>
											</template>
											<b-dropdown-item-button
												v-on:click="editNotification(notification)"
												>Edit</b-dropdown-item-button
											>
											<b-dropdown-item-button
												variant="danger"
												v-on:click="deletetNotification(notification)"
												>Delete</b-dropdown-item-button
											>
										</b-dropdown>
									</b-col>
								</b-row>
							</b-list-group-item>
						</b-list-group>
					</div>
					<b-overlay :show="isLoading" rounded="sm" no-wrap></b-overlay>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import DashboardHeaderVue from '../HeaderFooterComponents/DashboardHeader.vue';
import Modal from '../../../components/Modals/Modal.vue';
import NewNotification from '../../mainForms/NewNotification.vue';
import { mapGetters } from 'vuex';
import { getOrganizationFirestore } from '../../../resources/organizationFirebase';
import { collection, getDocs } from '@firebase/firestore';

export default {
	name: 'notifications',
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	props: {
		title: {
			type: String,
			default: 'Admin Notifications',
		},
	},
	components: {
		DashboardHeaderVue,
		Modal,
		NewNotification,
	},
	data() {
		return {
			isLoading: false,
			showModal: false,
			buttonItems: [],
			allNotifications: [],
		};
	},
	methods: {
		/*
		getNotifications() {
			const firestore = getOrganizationFirestore(this.organization);
			const firestoreRef = collection(firestore, 'adminNotifications');
			getDocs(firestoreRef).then((snapshot) => {
				if (snapshot.exists()) {
					snapshot.forEach((item) => {
						var notification = item.val();
						var data = {
							messageId: notification.messageId,
							message: notification.message,
							notificationTimestamp: this.$helpers.timeSince(
								new Date(notification.timestamp)
							),
							timestamp: notification.timestamp,
						};
						this.allNotifications.push(data);
					});
					this.allNotifications.sort((a, b) =>
						a.timestamp < b.timestamp ? 1 : -1
					);
					this.isLoading = false;
				}
			});
		},
		editNotification(notification) {
			console.log(`Moses edit on ${notification.messageId}`);
		},
		deletetNotification(notification) {
			console.log(`Moses delete on ${notification.messageId}`);
		},
		*/
	},
	mounted() {
		this.getNotifications();
	},
};
</script>
