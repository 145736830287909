<template>
	<div>
		<DashboardHeaderVue
			v-if="userData"
			:title="userData.firstName + ' ' + userData.lastName"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<div v-if="userData" class="container-fluid mt--6">
			<div class="row">
				<div class="col-md-9">
					<Card body-classes="p-3">
						<div
							class="text-center d-flex justify-content-between align-items-center"
						>
							<div class="d-flex justify-content-start align-items-center">
								<b-img-lazy
									:src="userImage"
									:text="userData.firstName + ' ' + userData.lastName"
									rounded="circle"
									class="mr-3 avatar-xl"
								/>

								<div class="align-items-left text-left">
									<h2 class="title m-0">
										{{ userData.firstName }} {{ userData.lastName }}
									</h2>
									<h5 class="m-0">@{{ userData.username }}</h5>
									<h6 class="m-0 text-muted">{{ userData.email }}</h6>
								</div>
							</div>
						</div>
					</Card>
				</div>
				<div class="col-md-3">
					<AccountLevelCard :userData="userData" />
				</div>
			</div>
			<div class="row">
				<div class="col">
					<BasicInformationCard :userData="userData" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getOrganizationFirestore } from '../../../../resources/organizationFirebase';
import { DashboardChildren } from '../../../../routes/routesNames';
import DashboardHeaderVue from '../../HeaderFooterComponents/DashboardHeader.vue';
import Card from '../../../../components/Cards/Card.vue';
import BaseButton from '../../../../components/BaseButton.vue';
import BaseSwitch from '../../../../components/BaseSwitch.vue';
import BasicInformationCard from '../../../Components/MemberViews/BasicInformationCard.vue';
import SubscriptionCard from '../../../Components/MemberViews/SubscriptionCard.vue';
import Badge from '../../../../components/Badge.vue';
import CreditCard from '../../../Components/MemberViews/CreditCard.vue';
import PaymentHistoryCard from '../../../Components/MemberViews/PaymentHistoryCard.vue';
import { DominionTerminalFunctions } from '../../../../resources/DominionInformation';
import { doc, getDoc } from '@firebase/firestore';
import AccountLevelCard from '../../../Components/MemberViews/AccountLevelCard.vue';

export default {
	name: 'member',
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	components: {
		DashboardHeaderVue,
		Card,
		BaseButton,
		BaseSwitch,
		BasicInformationCard,
		SubscriptionCard,
		Badge,
		CreditCard,
		PaymentHistoryCard,
		AccountLevelCard,
	},
	props: {
		uid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isLoading: true,
			userData: null,
			userImage: null,
			buttonItems: [
				{
					index: 0,
					title: 'Contact User',
					outline: true,
					type: 'primary',
					icon: 'fas fa-envelope',
				},
			],
		};
	},
	methods: {
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.openEmail();
			}
		},
		getUser() {
			const firebase = getOrganizationFirestore(this.organization);
			const firebaseRef = doc(firebase, `users/${this.uid}`);
			getDoc(firebaseRef).then((snapshot) => {
				if (snapshot.exists) {
					const user = snapshot.data();
					this.userData = user;
					this.userImage = user.profilePhotoURL
						? user.profilePhotoURL
						: 'https://firebasestorage.googleapis.com/v0/b/mysunday-v2.appspot.com/o/users%2FdefaultProfilePicture.png?alt=media&token=8488a06a-64ee-44b1-a4e3-10250d478545';
				} else {
					console.log(`Moses We Got No USER!`);
				}
			});
		},
		openEmail() {
			if (this.userData.email != null) {
				window.open(`mailto:${this.userData.email}`, '_blank').focus();
			}
		},
		getMemberSince() {
			return this.$helpers.formatDate(this.userData.timestamp, 'MMMM yyyy');
		},
	},
	mounted() {
		if (!this.uid) {
			this.$router.replace({
				name: DashboardChildren.home.name,
			});
		} else {
			this.getUser();
		}
	},
};
</script>
