<template>
	<div class="mx-2 mt-1">
		<div class="d-flex justify-content-between">
			<div class="d-flex align-items-center">
				<b-img
					fluid
					style="height: 40px; border: 1px solid lightgray"
					class="mr-2 rounded"
					:src="business.businessLogo"
				/>
				<div>
					<div class="d-flex align-items-center">
						<h5 class="m-0">{{ business.businessName }}</h5>
						<h6 v-if="false" class="ml-1 mb-0 text-muted"></h6>
					</div>
					<h6 class="m-0 text-muted">{{ timestamp }}</h6>
				</div>
			</div>
			<BaseButton icon="fas fa-ellipsis-h" type="neutral" link></BaseButton>
		</div>
		<p v-if="description" class="mt-1 small mb-0">{{ description }}</p>
	</div>
</template>
<script>
import BaseButton from '../BaseButton.vue';

export default {
	props: {
		business: {
			type: Object,
			default: null,
		},
		timestamp: {
			type: String,
			default: 'null',
		},
		description: {
			type: String,
			default:
				'Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.',
		},
	},
	components: { BaseButton },
};
</script>
