<template>
	<div>
		<Modal
			:show="showModal"
			@close="closeModal"
			body-classes="m-0 pt-0 pb-0"
			:hideFooter="true"
			footer-classes="pt-0"
			header-classes="pb-1"
			size="lg"
		>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
			</template>

			<b-overlay :show="isLoading" rounded="sm">
				<div class="pt-2">
					<h5 style="flex: auto; text-align: center">
						Upload a new featured item
						{{ businessData ? businessData.businessName : '' }}.
					</h5>
					<h6 v-if="errorMessage" style="color: red; flex: auto; text-align: center">
						{{ errorMessage }}
					</h6>
				</div>

				<form ref="form" class="pt-1 pb-1">
					<VueDropZone
						class="p-0 m-0"
						id="logo"
						style="border: 0; z-index: 1"
						v-on:vdropzone-files-added="addedItem"
						:options="dropzoneOptions"
					>
					</VueDropZone>
					<BaseInput placeholder="Index">
						<select class="form-control" id="index" v-model="index">
							<option v-for="value in arrayOfNumbers" :key="value">
								{{ value }}
							</option>
						</select>
					</BaseInput>
					<BaseInput
						label="Featured Link"
						id="featured Link"
						placeholder="Featured Link"
						v-model="featuredLink"
					/>

					<BaseInput
						label="Featured Image Link"
						id="featured Link"
						placeholder="Featured Image Link"
						v-model="featuredImageLink"
					/>

					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<BaseButton
							type="primary"
							:loading="isLoading"
							:shake="shake"
							@click="submit"
							>Add Featured</BaseButton
						>
					</b-row>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import VueDropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Modal from '../../components/Modals/Modal.vue';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import CurrencyInput from '../../components/Inputs/CurrencyInput.vue';
import BaseSwitch from '../../components/BaseSwitch.vue';
import ButtonRadioGroup from '../../components/ButtonRadioGroup.vue';
import { mapGetters } from 'vuex';
import {
	getOrganizationFirestore,
	getOrganizationStorage,
} from '../../resources/organizationFirebase';
import {
	uploadBytes,
	getDownloadURL,
	ref as firebaseStorageRef,
} from 'firebase/storage';

import BaseButton from '../../components/BaseButton.vue';
import { collection, doc, setDoc } from '@firebase/firestore';

export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	props: {
		headerTitle: {
			type: String,
			default: 'New Featured',
		},
		showModal: {
			type: Boolean,
			default: false,
		},
		businessData: {
			type: Object,
			default: null,
		},
	},
	components: {
		VueDropZone,
		Modal,
		BaseInput,
		CurrencyInput,
		BaseSwitch,
		ButtonRadioGroup,
		BaseButton,
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			dropzoneOptions: {
				url: 'https://httpbin.org/post',
				thumbnailWidth: 100,
				height: 100,
				addRemoveLinks: true,
				acceptedFiles: 'image/*',
				dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
			},
			arrayOfNumbers: [],
			imageItem: null,
			featuredLink: null,
			featuredImageLink: null,
			index: 0,
			errorMessage: null,
		};
	},
	methods: {
		resetView() {
			this.imageItem = null;
			this.index = 0;
			this.featuredLink = null;
			this.featuredImageLink = null;
			this.errorMessage = null;
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		addedItem(file) {
			this.imageItem = file;
		},
		submit() {
			this.shake = false;

			if (this.imageItem == null && this.featuredImageLink == null) {
				this.errorMessage = 'Please select a file.';
				this.shake = true;
				return;
			} else {
				this.errorMessage = null;

				const storage = getOrganizationStorage(this.organization);
				const firestore = getOrganizationFirestore(this.organization);
				var businessRef = collection(firestore, `featured`);
				if (this.businessData != null) {
					businessRef = collection(
						firestore,
						`business/${this.businessData.businessId}/featuredItems`
					);
				}
				const newFileRef = doc(businessRef);
				var storageRef = firebaseStorageRef(storage, `featured/${newFileRef.id}`);
				if (this.businessData != null) {
					storageRef = firebaseStorageRef(
						storage,
						`business/${this.businessData.businessId}/featuredItems/${newFileRef.id}`
					);
				}

				this.isLoading = true;

				if (this.imageItem != null) {
					this.uploadImage(storageRef, newFileRef);
				} else {
					this.sendFileToFirebase(this.featuredImageLink, newFileRef);
				}
			}
		},
		uploadImage(storageRef, newFileRef) {
			uploadBytes(storageRef, this.imageItem[0])
				.then((result) => {
					const resultRef = result.ref;
					getDownloadURL(resultRef).then((downloadURL) => {
						this.sendFileToFirebase(downloadURL, newFileRef);
					});
				})
				.catch((error) => {
					//error uploading
					this.isLoading = false;
					this.errorMessage = error.message;
					this.shake = true;
				});
		},
		sendFileToFirebase(downloadURL, newFileRef) {
			const featuredId = newFileRef.id;
			var featuredInfo = {
				featuredId: featuredId,
				imageItem: {
					imageUrl: downloadURL,
				},
				index: parseInt(this.index),
			};

			if (this.featuredLink != null) {
				featuredInfo['featuredLink'] = this.featuredLink;
			}

			setDoc(newFileRef, featuredInfo)
				.then(() => {
					this.isLoading = false;
					this.$emit('newFeatured', featuredInfo);
					this.resetView();
					this.closeModal();
				})
				.catch((error) => {
					this.isLoading = false;
					this.errorMessage = error.message;
					this.shake = true;
				});
		},
	},
	mounted() {
		for (let index = 0; index < 4; index++) {
			this.arrayOfNumbers.push(`${index}`);
		}
	},
};
</script>
