<template>
	<div class="wrapper">
		<Notifications />
		<DashboardSideMenu />
		<div class="main-content">
			<DashboardNavbar />
			<div @click="$sidebar.displaySidebar(false)">
				<fade-transition :duration="200" origin="center top" mode="out-in">
					<!-- your content here -->
					<router-view> </router-view>
				</fade-transition>
			</div>
			<DashboardFooter v-if="!$route.meta.hideFooter" />
		</div>
	</div>
</template>
<script>
/* eslint-disable no-new */
import DashboardNavbar from '../mainViews/DashboardNavbar.vue';
import DashboardSideMenu from '../mainViews/HeaderFooterComponents/DashboardSideMenu.vue';
import DashboardFooter from '../mainViews/HeaderFooterComponents/DashboardFooter.vue';
import Notifications from '../../components/NotificationPlugin/Notifications.vue';
import { FadeTransition } from 'vue2-transitions';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { mapActions, mapGetters } from 'vuex';

function hasElement(className) {
	return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
	if (hasElement(className)) {
		new PerfectScrollbar(`.${className}`);
	} else {
		// try to init it later in case this component is loaded async
		setTimeout(() => {
			initScrollbar(className);
		}, 100);
	}
}

export default {
	components: {
		DashboardNavbar,
		DashboardFooter,
		DashboardSideMenu,
		FadeTransition,
		Notifications,
	},
	computed: {
		...mapGetters({
			notification: 'getNotification',
		}),
	},
	watch: {
		notification(newNotification, oldNotification) {
			if (newNotification) {
				this.$notify({
					verticalAlign: 'top',
					horizontalAlign: 'center',
					type: newNotification.type,
					message: newNotification.message,
				});
			}
		},
	},
	methods: {
		...mapActions(['showNotification', 'removeNotification']),
		closeNotificationModal() {
			this.removeNotification();
		},
		initScrollbar() {
			let isWindows = navigator.platform.startsWith('Win');
			if (isWindows) {
				initScrollbar('sidenav');
			}
		},
	},
	mounted() {
		this.initScrollbar();
	},
};
</script>
<style lang="scss"></style>
