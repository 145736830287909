<template>
	<div>
		<DashboardHeader :title="$route.name" />
		<div class="container-fluid mt--6">
			<BasicInformationCard
				:businessData="business"
				@updatedProfile="getBusiness"
			/>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BasicInformationCard from '../../Components/VendorViews/BasicInformationCard.vue';
import DashboardHeader from '../HeaderFooterComponents/DashboardHeader.vue';

export default {
	components: { DashboardHeader, BasicInformationCard },
	computed: {
		...mapGetters({
			business: 'getBusiness',
		}),
	},
	methods: {
		...mapActions(['showNotification', 'getAppUserData']),
		getBusiness() {
			this.getAppUserData()
				.then(() => {})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: error.message,
					});
				});
		},
	},
};
</script>
