<template>
	<div>
		<Modal
			:show="showModal"
			@close="closeModal"
			body-classes="m-0 pt-0 pb-0"
			:hideFooter="true"
			footer-classes="pt-0"
			header-classes="pb-1"
			size="lg"
		>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
			</template>

			<b-overlay :show="isLoading" rounded="sm">
				<div class="pt-2">
					<h5 style="flex: auto; text-align: center">Upload your file.</h5>
					<h6 v-if="errorMessage" style="color: red; flex: auto; text-align: center">
						{{ errorMessage }}
					</h6>
				</div>

				<form ref="form" class="pt-1 pb-1">
					<VueDropZone
						class="p-0 m-0"
						id="logo"
						style="border: 0; z-index: 1"
						v-on:vdropzone-files-added="addedItem"
						:options="dropzoneOptions"
					>
					</VueDropZone>

					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<BaseButton
							type="primary"
							:loading="isLoading"
							:shake="shake"
							@click="submit"
							>Upload</BaseButton
						>
					</b-row>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import VueDropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Modal from '../../components/Modals/Modal.vue';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import CurrencyInput from '../../components/Inputs/CurrencyInput.vue';
import BaseSwitch from '../../components/BaseSwitch.vue';
import ButtonRadioGroup from '../../components/ButtonRadioGroup.vue';
import { mapGetters } from 'vuex';
import BaseButton from '../../components/BaseButton.vue';

export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	props: {
		headerTitle: {
			type: String,
			default: 'Upload File',
		},
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		VueDropZone,
		Modal,
		BaseInput,
		CurrencyInput,
		BaseSwitch,
		ButtonRadioGroup,
		BaseButton,
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			dropzoneOptions: {
				url: 'https://httpbin.org/post',
				thumbnailWidth: 100,
				height: 100,
				addRemoveLinks: true,
				acceptedFiles: 'text/csv',
				dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
			},
			fileItemDocument: null,
			errorMessage: null,
		};
	},
	methods: {
		resetView() {
			this.fileItemDocument = null;
			this.errorMessage = null;
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		addedItem(file) {
			this.fileItemDocument = file;
		},
		submit() {
			this.shake = false;
			if (this.fileItemDocument == null) {
				this.errorMessage = 'Please select a file.';
				this.shake = true;
				return;
			} else {
				this.$emit('addedFile', this.fileItemDocument[0]);
			}
		},
	},
	mounted() {},
};
</script>
