<template>
	<div>
		<!-- Header -->
		<div class="bg-gradient-info" style="min-height: 100vh">
			<b-container class="py-7 py-lg-8 pt-lg-9">
				<div class="header-body text-center mb-7">
					<b-row class="justify-content-center">
						<b-col xl="5" lg="6" md="6" class="px-5">
							<h1 class="text-light">Ooops!</h1>
							<p class="text-lead text-white">
								Page not found. Don't worry though, let's get you back on track.
							</p>
							<BaseButton @click="navigateToDashboard">
								Back to dashboard</BaseButton
							>
						</b-col>
					</b-row>
				</div>
			</b-container>
		</div>
		<!-- Page content -->
	</div>
</template>

<script>
import BaseButton from '../../components/BaseButton.vue';
import { DashboardChildren } from '../../routes/routesNames';
export default {
	name: 'not-found',
	components: { BaseButton },
	methods: {
		navigateToDashboard() {
			this.$router.push({ name: DashboardChildren.home.name });
		},
	},
};
</script>
