<template>
	<div>
		<Modal
			:show="showModal"
			@close="closeModal"
			body-classes="m-0 pt-0 pb-0"
			:hideFooter="true"
			footer-classes="pt-0"
			header-classes="pb-1"
			size="lg"
		>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
			</template>

			<b-overlay :show="isLoading" rounded="sm">
				<div class="pt-2">
					<h5 style="flex: auto; text-align: center">
						Upload a new speaker to the app.
					</h5>
					<h6 v-if="errorMessage" style="color: red; flex: auto; text-align: center">
						{{ errorMessage }}
					</h6>
				</div>

				<form ref="form" class="pt-1 pb-1">
					<VueDropZone
						v-if="editSpeakerData == null"
						class="p-0 m-0"
						id="logo"
						style="border: 0; z-index: 1"
						v-on:vdropzone-files-added="addedImage"
						:options="dropzoneOptions"
					>
					</VueDropZone>

					<BaseInput
						label="Speaker Name"
						id="speaker name"
						placeholder="Speaker Name"
						required
						v-model="speakerData.speakerName"
					/>
					<BaseInput label="Speaker Bio">
						<textarea
							placeholder="Enter speaker bio..."
							v-model="speakerData.speakerBio"
							class="form-control"
							id="speaker description"
							rows="6"
							required
						/>
					</BaseInput>
					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<base-button
							type="primary"
							:loading="isLoading"
							:shake="shake"
							@click="submit"
							>Add Speaker</base-button
						>
					</b-row>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import { collection, doc, setDoc } from '@firebase/firestore';
import {
	uploadBytes,
	getDownloadURL,
	ref as firebaseStorageRef,
} from 'firebase/storage';
import VueDropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { mapGetters } from 'vuex';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import Modal from '../../components/Modals/Modal.vue';
import {
	getOrganizationFirestore,
	getOrganizationStorage,
} from '../../resources/organizationFirebase';

export default {
	components: { Modal, BaseInput, VueDropZone },
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	props: {
		headerTitle: {
			type: String,
			default: 'New Speaker',
		},
		showModal: {
			type: Boolean,
			default: false,
		},
		editSpeakerData: {
			type: Object,
			default: null,
		},
	},
	watch: {
		editSpeakerData: {
			handler(val) {
				if (val) {
					this.speakerData.speakerName = val.speakerName;
					this.speakerData.speakerBio = val.speakerBio;
					return;
				} else {
					return this.resetView();
				}
			},
			deep: true,
		},
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			speakerData: {
				speakerName: null,
				speakerBio: null,
				speakerPhoto: null,
			},
			errorMessage: null,
			dropzoneOptions: {
				url: 'https://httpbin.org/post',
				thumbnailWidth: 100,
				height: 100,
				addRemoveLinks: true,
				acceptedFiles: 'image/*',
				dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
			},
		};
	},
	methods: {
		resetView() {
			this.speakerData.speakerPhoto = null;
			this.speakerData.speakerName = null;
			this.speakerData.speakerBio = null;
		},
		addedImage(file) {
			this.speakerData.speakerPhoto = file;
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		submit() {
			this.shake = false;

			const speakerPhoto = this.speakerData.speakerPhoto;
			const speakerName = this.speakerData.speakerName;
			const speakerBio = this.speakerData.speakerBio;

			if (this.editSpeakerData == null && speakerPhoto == null) {
				this.errorMessage = "Please add this speaker's profile picture.";
				this.shake = true;
				return;
			} else if (
				this.$helpers.isBlank(speakerName) ||
				this.$helpers.isBlank(speakerBio)
			) {
				this.errorMessage = 'Please complete all the necessary fields.';
				this.shake = true;
				return;
			} else {
				this.errorMessage = null;

				const storage = getOrganizationStorage(this.organization);
				const firestore = getOrganizationFirestore(this.organization);
				const speakerRef = collection(firestore, 'speakers');

				var speakerIdRef = null;

				if (this.editSpeakerData != null) {
					speakerIdRef = doc(
						firestore,
						`${speakerRef.path}/${this.editSpeakerData.speakerId}`
					);
					this.sendSpeakerToFirebase(null, speakerIdRef);
				} else {
					speakerIdRef = doc(speakerRef);
					const storageRef = firebaseStorageRef(
						storage,
						`speakers/${speakerIdRef.id}`
					);
					this.uploadImage(storageRef, speakerIdRef);
				}

				this.isLoading = true;
			}
		},
		uploadImage(storageRef, speakerIdRef) {
			const metadata = {
				contentType: 'image/jpg',
			};

			uploadBytes(storageRef, this.speakerData.speakerPhoto[0], metadata)
				.then((result) => {
					const resultRef = result.ref;
					getDownloadURL(resultRef).then((downloadURL) => {
						this.sendSpeakerToFirebase(downloadURL, speakerIdRef);
					});
				})
				.catch((error) => {
					//error uploading
					this.isLoading = false;
					this.errorMessage = error.message;
					this.shake = true;
				});
		},
		sendSpeakerToFirebase(imageURL, speakerIdRef) {
			const speakerId = speakerIdRef.id;
			var speakerInfo = {
				speakerId: speakerId,
				speakerBio: this.speakerData.speakerBio,
				speakerName: this.speakerData.speakerName,
			};

			if (imageURL) {
				speakerInfo['speakerPhoto'] = imageURL;
			}

			setDoc(speakerIdRef, speakerInfo, { merge: true })
				.then(() => {
					this.isLoading = false;
					this.$emit('newSpeaker', speakerInfo);

					this.closeModal();
					this.resetView();
				})
				.catch((error) => {
					this.isLoading = false;
					this.errorMessage = error.message;
					this.shake = true;
				});
		},
		isEmpty(str) {
			return this.$helpers.isBlank(str);
		},
	},
};
</script>
