<template>
	<div>
		<div style="position: relative">
			<VueAspectRatio ar="16:9">
				<b-img
					fluid
					src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/Aspect-ratio-16x9.svg/1200px-Aspect-ratio-16x9.svg.png"
				/>
			</VueAspectRatio>
			<BaseButton
				style="position: absolute; top: 0; right: 0"
				class="m-2"
				size="sm"
				icon="fas fa-edit"
				@click="editButtonClicked"
				>edit</BaseButton
			>
		</div>

		<div class="py-2 mx-2">
			<b-row style="margin-top: -50px">
				<b-col
					cols="auto"
					@mouseover="imageHover = true"
					@mouseleave="imageHover = false"
				>
					<div class="b-img-helpers card-img">
						<div
							style="height: 100px; width: 100px; overflow: hidden"
							:style="{ opacity: imageHover ? 0.4 : 1 }"
						>
							<b-img
								fluid
								style="
									min-width: 100%;
									min-height: 100%;
									object-fit: contain;
									border: 3px solid lightgray;
									background-color: white;
								"
								class="rounded"
								:src="business.businessLogo"
							/>
						</div>
						<div
							v-if="imageHover"
							style="
								height: 100%;
								width: 100%;
								border: dashed 1px black;
								display: flex;
								flex-wrap: wrap;
								align-content: center;
							"
							class="justify-content-center b-img-base"
						>
							<BaseButton type="primary" size="sm" block @click="editLogoButtonClicked"
								>Edit</BaseButton
							>
						</div>
					</div>
				</b-col>
				<b-col style="top: 50px" class="p-2 m-1"> </b-col>
				<b-col style="top: 50px" class="p-2 m-1">
					<BaseButton block style="background-color: gray; border: 0" disabled
						>Message</BaseButton
					>
				</b-col>
			</b-row>
			<h2 class="mt-1 mb-0">{{ business.businessName }}</h2>
			<h5 class="mb-0 text-muted">{{ business.businessSocial.website }}</h5>
			<p class="small mb-0">{{ business.businessBio }}</p>
		</div>
	</div>
</template>
<script>
import VueAspectRatio from 'vue-aspect-ratio';
import BaseButton from '../BaseButton.vue';

export default {
	components: { VueAspectRatio, BaseButton },
	props: {
		business: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			imageHover: false,
		};
	},
	methods: {
		editButtonClicked() {
			this.$emit('editButtonClicked');
		},
		editLogoButtonClicked() {
			this.$emit('editLogoButtonClicked');
		},
	},
};
</script>
