const getters = {
	getUser(state) {
		return state.user;
	},
	getOrganization(state) {
		return state.organization;
	},
	getOrganizationUser(state) {
		return state.organizationUser;
	},
	getBusiness(state) {
		return state.business;
	},
	getNotification(state) {
		return state.notification;
	},
	isFullAdmin(state) {
		if (state.user) {
			return state.user.isAdmin ? state.user.isAdmin : false;
		}
		return false;
	},
	isBusinessAdmin(state) {
		return !!state.business;
	},
};
export default getters;
