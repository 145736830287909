<template>
	<div>
		<b-row class="mt-2">
			<b-col cols="auto">
				<BaseButton icon="fas fa-heart" type="null" size="sm" disabled link
					>15 Likes</BaseButton
				>
			</b-col>
			<b-col cols="auto">
				<BaseButton icon="fas fa-comment" type="null" size="sm" disabled link
					>15 Comments</BaseButton
				>
			</b-col>
		</b-row>
		<hr class="mb-0 mt-1 mx-3" />
		<b-row class="text-center">
			<b-col>
				<BaseButton icon="fas fa-heart" type="null">Like</BaseButton>
			</b-col>
			<b-col>
				<BaseButton icon="fas fa-comment" type="null">Comment</BaseButton>
			</b-col>
			<b-col>
				<BaseButton icon="fas fa-share" type="null">Share</BaseButton>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import BaseButton from '../BaseButton.vue';

export default { components: { BaseButton } };
</script>
