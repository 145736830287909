<template>
	<div>
		<div v-if="isFullAdmin">
			<DashboardHeaderVue
				:title="$route.name"
				:buttonItems="buttonItems"
				@click="handleHeaderClick"
			/>
			<div class="container-fluid mt--6">
				<b-row>
					<b-col>
						<FeaturedCard
							:allFeaturedItems="allFeaturedItems"
							@deleteFeaturedItem="deleteFeaturedItem"
						/>
					</b-col>
				</b-row>
			</div>
			<NewFeatured
				:headerTitle="buttonItems[0].title"
				:showModal="showFeaturedModal"
				@close="showFeaturedModal = false"
				@newFeatured="onNewFeatured"
			/>
		</div>
		<div v-else>
			<DashboardHeaderVue
				v-if="business"
				:title="business.businessName"
				:buttonItems="buttonItems"
				@childClick="childClick"
			/>
			<div v-if="business" class="container-fluid mt--6">
				<b-row>
					<b-col style="max-width: 1000px">
						<VenderProfile
							:business="business"
							@editButtonClicked="openFeaturedModal"
							@updateBusiness="getBusiness"
						/>
					</b-col>
					<b-col class="d-none d-md-inline" md="6">
						<b-row>
							<b-col>
								<FeaturedCard
									:allFeaturedItems="allFeaturedItems"
									@deleteFeaturedItem="deleteFeaturedItem"
								/>
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<ResourceCard :businessId="business.businessId" />
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<FAQCard :businessData="business" />
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<BaseInformationCard :businessData="business" />
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</div>
			<NewResource
				:headerTitle="buttonItems[0].children[this.newResourceId].title"
				:showModal="showVendorModal"
				:businessData="business"
				@close="showVendorModal = false"
				@newResource="onNewResource"
			/>
			<NewFeatured
				:headerTitle="buttonItems[0].children[this.newFeaturedId].title"
				:showModal="showFeaturedModal"
				:businessData="business"
				@close="showFeaturedModal = false"
				@newFeatured="onNewFeatured"
			/>
			<NewFAQ
				:headerTitle="buttonItems[0].children[this.newFaqId].title"
				:showModal="showFAQModal"
				:businessData="business"
				@close="showFAQModal = false"
				@newFaq="onNewFAQ"
			/>
		</div>
	</div>
</template>
<script>
import DashboardHeaderVue from '../HeaderFooterComponents/DashboardHeader.vue';
import StatsCard from '@/components/Cards/StatsCard';
import {
	getOrganizationFirestore,
	getOrganizationStorage,
} from '../../../resources/organizationFirebase';
import { mapActions, mapGetters } from 'vuex';
import FeaturedCard from '../../Components/VendorViews/FeaturedCard.vue';
import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
} from '@firebase/firestore';
import VenderProfile from '../../Components/VendorViews/VenderProfile.vue';
import BaseInformationCard from '../../Components/VendorViews/BasicInformationCard.vue';
import ResourceCard from '../../Components/VendorViews/ResourceCard.vue';
import { deleteObject, ref } from '@firebase/storage';
import { httpsCallable } from '@firebase/functions';
import { functions } from '../../../resources/firebase';
import NewResource from '../../mainForms/NewResource.vue';
import NewFeatured from '../../mainForms/NewFeatured.vue';
import VendorView from './SecondaryViews/VendorView.vue';
import FAQCard from '../../Components/VendorViews/FAQCard.vue';
import NewFAQ from '../../mainForms/NewFAQ.vue';

export default {
	name: 'dashboard',
	computed: {
		...mapGetters({
			isFullAdmin: 'isFullAdmin',
			business: 'getBusiness',
			organization: 'getOrganization',
		}),
	},
	components: {
		DashboardHeaderVue,
		StatsCard,
		FeaturedCard,
		NewFeatured,
		VenderProfile,
		ResourceCard,
		BaseInformationCard,
		NewResource,
		NewFeatured,
		VendorView,
		FAQCard,
		NewFAQ,
	},
	data() {
		return {
			allFeaturedItems: [],
			showFeaturedModal: false,
			showVendorModal: false,
			showFAQModal: false,
			contactId: 3,
			newFeaturedId: 0,
			newResourceId: 1,
			newFaqId: 2,
			buttonItems: [
				{
					index: 0,
					title: 'New Featured',
					type: 'primary',
					icon: 'fas fa-plus',
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification', 'getAppUserData']),
		handleHeaderClick() {
			this.showFeaturedModal = true;
		},
		setButtons() {
			this.buttonItems = [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [],
				},
			];
			this.buttonItems[0].children = [
				{
					index: this.newFeaturedId,
					title: 'Add New Featured',
				},
				{
					index: this.newResourceId,
					title: 'Add New Resource',
				},
				{
					index: this.newFaqId,
					title: 'Add New FAQ',
				},
			];
			this.getFeatured();
		},
		onNewFeatured(item) {
			this.allFeaturedItems.push(item);
			this.allFeaturedItems.sort((a, b) => {
				return a.index - b.index;
			});
			this.showNotification({
				type: 'success',
				message: `New Featured item has been added.`,
			});
		},
		onNewResource(item) {
			this.business = this.business;
			this.showNotification({
				type: 'success',
				message: `New Resource item has been added.`,
			});
		},
		onNewFAQ() {
			this.business = this.business;
			this.showNotification({
				type: 'success',
				message: `New FAQ item has been added. Please refresh.`,
			});
		},
		deleteFeaturedItem(featuredItem) {
			const firestore = getOrganizationFirestore(this.organization);
			const storage = getOrganizationStorage(this.organization);

			var featuredRef = null;
			var featuredStoreRef = null;

			if (this.isFullAdmin) {
				featuredRef = doc(firestore, `featured/${featuredItem.featuredId}`);
				featuredStoreRef = ref(storage, `featured/${featuredItem.featuredId}`);
			} else if (this.business) {
				featuredRef = doc(
					firestore,
					`business/${this.business.businessId}/featuredItems/${featuredItem.featuredId}`
				);
				featuredStoreRef = ref(
					storage,
					`business/${this.business.businessId}/featuredItems/${featuredItem.featuredId}`
				);
			}

			deleteObject(featuredStoreRef)
				.finally(() => {
					deleteDoc(featuredRef)
						.then(() => {
							this.getFeatured();
							this.showNotification({
								type: 'success',
								message: `Featured item has been deleted.`,
							});
						})
						.catch((error) => {
							this.showNotification({
								type: 'danger',
								message: error.message,
							});
						});
				})
				.catch((error) => {
					console.log(error.message);
				});
		},
		getFeatured() {
			this.allFeaturedItems = [];
			const firestore = getOrganizationFirestore(this.organization);
			var featuredRef = null;
			if (this.isFullAdmin) {
				featuredRef = collection(firestore, `featured`);
			} else if (this.business) {
				featuredRef = collection(
					firestore,
					`business/${this.business.businessId}/featuredItems`
				);
			}
			getDocs(featuredRef).then((snapshot) => {
				if (!snapshot.empty) {
					snapshot.forEach((doc) => {
						this.allFeaturedItems.push(doc.data());
					});
					console.log(`featured`, this.allFeaturedItems.length);
				}
			});
		},
		getBusiness() {
			this.getAppUserData()
				.then(() => {})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: error.message,
					});
				});
		},
		childClick(childClicked) {
			this.selectedId = childClicked.index;
			switch (childClicked.index) {
				case this.newResourceId:
					this.showVendorModal = true;
					break;
				case this.newFeaturedId:
					this.openFeaturedModal();
					break;
				case this.newFaqId:
					this.openFAQModal();
					break;
				case this.generateLeadListId:
					this.generateLeadList();
					break;
				default:
					break;
			}
		},
		openFeaturedModal() {
			this.showFeaturedModal = true;
		},
		openFAQModal() {
			this.showFAQModal = true;
		},
	},
	mounted() {
		if (this.isFullAdmin) {
			this.getFeatured();
		} else if (this.business) {
			this.setButtons();
		} else {
		}
	},
};
</script>
//TEST= HJ9xq7jbeVdziyovsUE1 //DOMINIOn = r0QzwXPfC3DXlNiaRWfz
