<template>
	<div>
		<Card no-body>
			<b-row align-v="center" slot="header">
				<b-col cols="8">
					<h3 class="mb-0">FAQs</h3>
				</b-col>
				<b-col cols="4" class="text-right">
					<h3 class="mb-0">{{ allFaqItems.length }} Total</h3>
				</b-col>
			</b-row>
			<b-row v-if="allFaqItems.length > 0" align-v="center" slot="footer">
				<h6 class="text-muted m-0 px-2">Please limit these items to five.</h6>
			</b-row>
			<FAQTable
				:isLoading="isLoading"
				:tableData="allFaqItems"
				:tableHeaders="faqTitles"
				@removeClicked="askFAQuestion"
				@editClicked="editClicked"
			/>
		</Card>
		<NewFAQ
			:showModal="showModal"
			:editFaqItem="faqItem"
			:businessData="businessData"
			@close="showModal = false"
			@newFaq="onNewFAQ"
		/>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@yesClicked="deleteFAQItem"
			@close="showQuestionModal = false"
		/>
	</div>
</template>
<script>
import { collection, deleteDoc, doc, getDocs } from '@firebase/firestore';
import { mapActions, mapGetters } from 'vuex';
import Card from '../../../components/Cards/Card.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import { getOrganizationFirestore } from '../../../resources/organizationFirebase';
import NewFAQ from '../../mainForms/NewFAQ.vue';
import FAQTable from './FAQTable.vue';
export default {
	props: {
		businessId: {
			type: String,
			default: null,
		},
		businessData: {
			type: Object,
			default: null,
		},
	},
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	components: { Card, QuestionModal, FAQTable, NewFAQ },
	data() {
		return {
			isLoading: false,
			showQuestionModal: false,
			showModal: false,
			allFaqItems: [],
			faqItem: null,
			modalQuestion: '',
			modalButtonData: [
				{
					id: 0,
					title: 'Delete FAQ',
				},
			],
			faqTitles: [
				{
					key: 'faqTitle',
					label: 'Title',
					tdClass: 'align-middle',
					thStyle: { width: 'auto' },
				},
				{
					key: 'actions',
					label: '',
					tdClass: 'align-middle',
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		getFAQItems() {
			this.allFaqItems = [];
			this.isLoading = true;
			const firestore = getOrganizationFirestore(this.organization);
			var faqItemRef = collection(
				firestore,
				`business/${this.businessData.businessId}/faqItems`
			);
			getDocs(faqItemRef).then((snapshot) => {
				this.isLoading = false;
				if (!snapshot.empty) {
					snapshot.forEach((document) => {
						this.allFaqItems.push(document.data());
					});
				}
			});
		},
		onNewFAQ(faqData) {
			this.getFAQItems();

			this.showNotification({
				type: 'success',
				message: `FAQ has been added.`,
			});
		},
		askFAQuestion(faqItem) {
			this.modalQuestion = 'Do you want to delete this FAQ?';
			this.faqItem = faqItem;
			this.showQuestionModal = true;
		},
		editClicked(faqItem) {
			this.faqItem = faqItem;
			this.showModal = true;
			console.log('edit: ', faqItem.faqTitle);
		},
		deleteFAQItem() {
			const firestore = getOrganizationFirestore(this.organization);
			var faqItemRef = doc(
				firestore,
				`business/${this.businessData.businessId}/faqItems/${this.faqItem.faqId}`
			);
			deleteDoc(faqItemRef)
				.then(() => {
					this.isLoading = true;
					this.allFaqItems = this.allFaqItems.filter(
						(item) => item.faqId != this.faqItem.faqId
					);

					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: `${this.faqItem.faqTitle} Deleted.`,
					});
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: error.message,
					});
				});
		},
	},
	mounted() {
		this.getFAQItems();
	},
};
</script>
