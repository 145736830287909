<template>
	<div>
		<!-- Dropdown header -->
		<div class="px-3 pt-3">
			<h6 class="text-sm text-muted m-0">Admin Notifications</h6>
		</div>
		<!-- List group -->
		<b-list-group flush>
			<b-list-group-item
				v-for="notification in allAdminNotifications"
				action
				@click="navigateToLink"
			>
				<b-row align-v="center">
					<b-col md="auto">
						<!-- Avatar -->
						<b-img
							alt="Image placeholder"
							:src="organization.appLogo"
							class="avatar"
							rounded="circle"
						/>
					</b-col>
					<b-col class="ml--2">
						<div class="d-flex justify-content-between align-items-center">
							<div>
								<h4 class="mb-0 text-sm">{{ organization.appName }}</h4>
							</div>
							<div class="text-right text-muted">
								<small>{{ notification.timestamp }}</small>
							</div>
						</div>
						<p class="text-sm mb-0">{{ notification.message }}</p>
					</b-col>
				</b-row>
			</b-list-group-item>
		</b-list-group>
		<!-- View all -->
		<!-- This item dont have <b-dropdown-item> because item have styles " text-center text-primary font-weight-bold py-3" on tag <a> wich we cant add -->
		<a
			href=""
			v-on:click.prevent="navigateToLink"
			class="dropdown-item text-center text-primary font-weight-bold py-3"
			>View all</a
		>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import adminNotifications from '../../../data/adminNotifications';
import { DashboardChildren } from '../../../routes/routesNames';

export default {
	props: {},
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
		routeName() {
			const { name } = this.$route;
			return this.capitalizeFirstLetter(name);
		},
	},
	data() {
		return {
			isLoading: false,
			activeNotifications: false,
			showMenu: false,
			adminNotifications: adminNotifications,
			allAdminNotifications: [],
			DashboardChildren,
		};
	},
	methods: {
		getNotifications() {
			var notifications = [];
			this.adminNotifications.forEach((notification) => {
				Object.keys(notification).forEach((key) => {
					var item = notification[key];
					var data = {
						message: item.message,
						notificationTimestamp: item.timestamp,
						timestamp: this.$helpers.timeSince(new Date(item.timestamp)),
					};
					notifications.push(data);
				});
			});

			notifications.sort((a, b) => {
				return b.notificationTimestamp - a.notificationTimestamp;
			});

			for (let index = 0; index < 5; index++) {
				this.allAdminNotifications.push(notifications[index]);
			}
		},
		toggleNotificationDropDown() {
			this.activeNotifications = !this.activeNotifications;
		},
		closeDropDown() {
			this.activeNotifications = false;
		},
		navigateToLink() {
			if (this.$route.name != DashboardChildren.adminNotifications.name) {
				this.$router.replace({
					path: DashboardChildren.adminNotifications.path,
				});
			}
		},
	},
	mounted() {
		this.getNotifications();
	},
};
</script>
