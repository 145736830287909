import Vue from 'vue';
import VueRouter from 'vue-router';
import { LoginPaths, DashboardPaths, DashboardChildren } from './routesNames';
import { auth } from '../resources/firebase';
import store from '../store/store';

Vue.use(VueRouter);

var routes = [];

Object.values(LoginPaths).forEach((entry) => {
	routes.push(entry);
});
Object.values(DashboardPaths).forEach((entry) => {
	routes.push(entry);
});

routes.forEach((route) => {
	var components = {};
	if (route.view) {
		components['default'] = route.view;
	}
	if (route.header) {
		components['header'] = route.header;
	}
	if (route.footer) {
		components['footer'] = route.footer;
	}
	if (route.id == 'home') {
		var children = [];
		Object.values(DashboardChildren).forEach((entry) => {
			var child = entry;
			var childComponents = {};
			if (child.view) {
				childComponents['default'] = child.view;
			}
			child['components'] = childComponents;
			children.push(child);
		});
		route['children'] = children;
	}
	route['components'] = components;
});

const router = new VueRouter({
	mode: 'history',
	routes,
});

router.beforeEach((to, from, next) => {
	let user = auth.currentUser;
	let isAdmin = store.getters.isFullAdmin;
	if (to.matched[0].meta.requiresAuth) {
		if (user) {
			if (!isAdmin && !to.meta.isBusiness) {
				next({
					name: DashboardPaths.home.name,
				});
			} else {
				next();
			}
		} else {
			next({
				name: LoginPaths.login.name,
			});
		}
	} else if (to.matched[0].meta.alreadyAuth) {
		if (user) {
			next({
				name: DashboardPaths.home.name,
			});
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router;
