<template>
	<div>
		<Card no-body>
			<b-row align-v="center" slot="header">
				<b-col cols="8">
					<h3 class="mb-0">Featured</h3>
				</b-col>
				<b-col cols="4" class="text-right">
					<h3 class="mb-0">{{ allFeaturedItems.length }} Total</h3>
				</b-col>
			</b-row>
			<b-row v-if="allFeaturedItems.length > 0" align-v="center" slot="footer">
				<h6 class="text-muted m-0 px-2">Please limit these items to three.</h6>
			</b-row>
			<b-row class="mx-1">
				<b-col
					class="my-2"
					lg="4"
					v-for="featuredItem in allFeaturedItems"
					:key="featuredItem.featuredItem"
					@click="askFeaturedQuestion(featuredItem)"
				>
					<VueAspectRatio ar="16:9">
						<div class="b-img-helpers card-img">
							<b-img-lazy
								fluid-grow
								rounded
								:src="featuredItem.imageItem.imageUrl"
								class="b-img-background"
							/>
							<div class="b-img-overlay"></div>
							<b-img-lazy
								fluid-grow
								:src="featuredItem.imageItem.imageUrl"
								class="b-img-base"
							/>
							<div
								class="b-img-center text-white"
								v-if="checkFeaturedLink(featuredItem)"
							>
								<b-icon
									icon="play-circle"
									font-scale="2.5"
									class="rounded-circle bg-danger"
								></b-icon>
							</div>
						</div>
					</VueAspectRatio>
				</b-col>
			</b-row>
		</Card>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@yesClicked="deleteFeaturedItem"
			@close="showQuestionModal = false"
		/>
	</div>
</template>
<script>
import VueAspectRatio from 'vue-aspect-ratio';
import { mapActions, mapGetters } from 'vuex';
import Card from '../../../components/Cards/Card.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
export default {
	props: {
		allFeaturedItems: {
			default: [],
		},
		businessId: {
			type: String,
			default: null,
		},
	},
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	components: { Card, QuestionModal, VueAspectRatio },
	data() {
		return {
			isLoading: false,
			showQuestionModal: false,
			featuredItem: null,
			modalQuestion: '',
			modalButtonData: [
				{
					id: 0,
					title: 'Delete Featured',
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		askFeaturedQuestion(featuredItem) {
			this.modalQuestion = 'Do you want to delete this featured item?';
			this.featuredItem = featuredItem;
			this.showQuestionModal = true;
		},
		deleteFeaturedItem() {
			this.$emit('deleteFeaturedItem', this.featuredItem);
		},
		checkFeaturedLink(featuredItem) {
			if (featuredItem.featuredLink) {
				return featuredItem.featuredLink.includes('youtube');
			}
			return false;
		},
	},
	mounted() {},
};
</script>
