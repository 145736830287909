<template>
	<div>
		<b-overlay :show="isLoading" rounded="sm">
			<Card body-classes="p-3" :class="getAccountLevel()">
				<div class="d-flex justify-content-between align-items-center">
					<div class="d-flex align-items-center mr-5">
						<div class="avatar-xl d-flex align-items-center"></div>

						<h5 class="surtitle text-white">{{ userData.accountLevel }}</h5>
					</div>
				</div>
			</Card>
		</b-overlay>
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import Badge from '../../../components/Badge.vue';
export default {
	props: {
		isLoading: true,
		userData: {
			type: Object,
			description: 'User Information',
		},
	},
	data() {
		return {
			userData,
		};
	},
	components: { Card, Badge },
	methods: {
		getAccountLevel() {
			return 'bg-gradient-secondary';
		},
	},
};
</script>
