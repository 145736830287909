<template>
	<div>
		<Modal
			:show="showModal"
			@close="closeModal"
			body-classes="m-0 pt-0 pb-0"
			:hideFooter="true"
			footer-classes="pt-0"
			header-classes="pb-1"
			size="lg"
		>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
			</template>

			<b-overlay :show="isLoading" rounded="sm">
				<div class="pt-2">
					<h5 style="flex: auto; text-align: center">
						Upload a new freqently asked question to the app.
					</h5>
					<h6 v-if="errorMessage" style="color: red; flex: auto; text-align: center">
						{{ errorMessage }}
					</h6>
				</div>

				<form ref="form" class="pt-1 pb-1">
					<BaseInput
						label="Question Title"
						id="question title"
						placeholder="Question Title"
						required
						v-model="faqData.title"
					/>
					<BaseInput label="Question Answer">
						<textarea
							placeholder="Enter question answer..."
							v-model="faqData.description"
							maxlength="350"
							class="form-control"
							id="faq answer"
							rows="4"
							required
						/>
					</BaseInput>
					<BaseInput placeholder="Index">
						<select class="form-control" id="index" v-model="faqData.index">
							<option v-for="value in arrayOfNumbers" :key="value">
								{{ value }}
							</option>
						</select>
					</BaseInput>
					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<BaseButton
							type="primary"
							:loading="isLoading"
							:shake="shake"
							@click="submit"
							>Add FAQ</BaseButton
						>
					</b-row>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import { collection, doc, setDoc } from '@firebase/firestore';
import { mapGetters } from 'vuex';
import BaseButton from '../../components/BaseButton.vue';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import Modal from '../../components/Modals/Modal.vue';
import { getOrganizationFirestore } from '../../resources/organizationFirebase';

export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	props: {
		headerTitle: {
			type: String,
			default: 'New FAQ',
		},
		businessData: {
			type: Object,
			default: null,
		},
		showModal: {
			type: Boolean,
			default: false,
		},
		editFaqItem: {
			type: Object,
			default: null,
		},
	},
	watch: {
		editFaqItem: {
			handler(val) {
				if (val) {
					this.faqData.title = val.faqTitle;
					this.faqData.description = val.faqDescription;
					return;
				} else {
					return this.resetView();
				}
			},
			deep: true,
		},
	},
	components: { Modal, BaseInput, BaseButton },
	data() {
		return {
			shake: false,
			isLoading: false,
			faqData: {
				title: null,
				description: null,
				index: 0,
			},
			arrayOfNumbers: [],
			errorMessage: null,
		};
	},
	methods: {
		resetView() {
			this.faqData.title = null;
			this.faqData.description = null;
			this.errorMessage = null;
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		submit() {
			this.shake = false;

			const faqTitle = this.faqData.title;
			const faqDescription = this.faqData.description;

			if (
				(this.editFaqItem == null && this.$helpers.isBlank(faqTitle)) ||
				this.$helpers.isBlank(faqDescription)
			) {
				this.errorMessage = 'Please complete all the necessary fields.';
				this.shake = true;
				return;
			} else {
				this.errorMessage = null;

				const firestore = getOrganizationFirestore(this.organization);
				const businessRef = collection(
					firestore,
					`business/${this.businessData.businessId}/faqItems`
				);
				var faqIdRef = null;
				var faqId = null;
				if (this.editFaqItem != null) {
					faqIdRef = doc(firestore, `${businessRef.path}/${this.editFaqItem.faqId}`);
					faqId = this.editFaqItem.faqId;
				} else {
					faqIdRef = doc(businessRef);
					faqId = faqIdRef.id;
				}

				this.isLoading = true;

				var faqInfo = {
					faqId: faqId,
					faqTitle: faqTitle,
					faqDescription: faqDescription,
					index: parseInt(this.faqData.index),
					uploadTimestamp: new Date(),
				};

				setDoc(faqIdRef, faqInfo, { merge: true })
					.then(() => {
						this.isLoading = false;
						this.$emit('newFaq', faqInfo);
						this.resetView();
						this.closeModal();
					})
					.catch((error) => {
						this.isLoading = false;
						this.errorMessage = error.message;
						this.shake = true;
					});
			}
		},
	},
	mounted() {
		for (let index = 0; index < 4; index++) {
			this.arrayOfNumbers.push(`${index}`);
		}
	},
};
</script>
