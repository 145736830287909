<template>
	<div>
		<Card v-if="userData" body-classes="p-3">
			<b-row align-v="center" slot="header">
				<b-col cols="8">
					<h3 class="mb-0">Account Information</h3>
				</b-col>
				<b-col cols="4" class="text-right">
					<BaseButton
						:disabled="!isUpdatingProfile"
						@click="askToUpdateProfile"
						size="sm"
						>Update Profile</BaseButton
					>
				</b-col>
			</b-row>

			<b-row align-v="center" slot="footer">
				<h6 class="text-muted m-0 px-2">
					This information is only visible to you and your studio admins. This
					will not be shared with any other users.
				</h6>
			</b-row>

			<b-form>
				<div class="px-lg-4">
					<b-row>
						<b-col>
							<base-input
								type="text"
								label="First Name"
								placeholder="First Name"
								name="firstName"
								v-model="firstName"
							>
							</base-input>
						</b-col>

						<b-col>
							<base-input
								type="text"
								label="Last Name"
								placeholder="Last Name"
								name="lastName"
								v-model="lastName"
							>
							</base-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<base-input
								type="email"
								label="Email address"
								placeholder="Email"
								name="email"
								v-model="email"
							>
							</base-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<base-input
								type="text"
								label="Member Id"
								placeholder="Member Id"
								disabled
								:value="`${userData.uid.substring(0, 10)}****`"
							>
							</base-input>
						</b-col>
					</b-row>
					<b-row> </b-row>
				</div>
				<hr class="my-4" />

				<!-- Additional Info -->
				<h6 class="heading-small text-muted mb-2">Additional Information</h6>

				<div class="px-lg-4">
					<b-row>
						<b-col>
							<base-input
								type="text"
								label="Contact Number"
								placeholder="Enter Phone Number"
								v-model="phoneNumber"
								@input="maskPhoneNumber"
							>
							</base-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<base-input
								type="date"
								label="Birthday"
								placeholder="Enter Date Of Birth"
								name="date of birth"
								v-model="dateOfBirth"
							>
							</base-input>
						</b-col>
					</b-row>
				</div>
				<hr class="my-4" />

				<!-- Additional Info -->
				<h6 class="heading-small text-muted mb-2">Business Information</h6>

				<div class="px-lg-4">
					<b-row>
						<b-col>
							<base-input
								type="text"
								label="Studio Name"
								placeholder="Studio Name"
								name="businessName"
								v-model="businessName"
							/>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<base-input
								type="text"
								label="Studio Address"
								placeholder="Studio Address"
								name="businessAddress"
								v-model="businessAddress"
							/>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<base-input
								type="text"
								label="Studio Website"
								placeholder="Studio Website"
								name="businessWebsite"
								v-model="businessWebsite"
							/>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<base-input
								type="text"
								label="Studio Role"
								placeholder="Studio Role"
								name="businessRole"
								v-model="businessRole"
							/>
						</b-col>
					</b-row>
				</div>
			</b-form>
		</Card>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@close="showQuestionModal = false"
			@yesClicked="updateProfile"
		/>
	</div>
</template>

<script>
import Card from '../../../components/Cards/Card.vue';
import BaseButton from '../../../components/BaseButton.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import { mapActions, mapGetters } from 'vuex';
import { functions } from '../../../resources/firebase';
import { httpsCallable } from 'firebase/functions';
import { getOrganizationFirestore } from '../../../resources/organizationFirebase';
import moment from 'moment';
import { doc, setDoc } from '@firebase/firestore';

export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	props: {
		userData: {
			type: Object,
			description: 'User Information',
		},
	},
	components: { Card, BaseButton, QuestionModal },
	data() {
		return {
			isUpdatingProfile: false,
			showQuestionModal: false,
			modalQuestion: "Are you sure you want to update this user's profile?",
			firstName: null,
			lastName: null,
			email: null,
			address: null,
			phoneNumber: null,
			dateOfBirth: null,
			businessName: null,
			businessAddress: null,
			businessWebsite: null,
			businessRole: null,
		};
	},
	watch: {
		firstName(val) {
			if (this.$helpers.isBlank(val)) {
				this.firstName = this.userData.firstName;
			}
			this.isUpdatingProfile = this.checkIfUpdateNeeded();
		},
		lastName(val) {
			if (this.$helpers.isBlank(val)) {
				this.lastName = this.userData.lastName;
			}
			this.isUpdatingProfile = this.checkIfUpdateNeeded();
		},
		email(val) {
			if (this.$helpers.isBlank(val)) {
				this.email = this.userData.email;
			}
			this.isUpdatingProfile = this.checkIfUpdateNeeded();
		},
		address(val) {
			this.isUpdatingProfile = this.checkIfUpdateNeeded();
		},
		phoneNumber(val) {
			this.isUpdatingProfile = this.checkIfUpdateNeeded();
		},
		dateOfBirth(val) {
			this.isUpdatingProfile = this.checkIfUpdateNeeded();
		},
		businessName(val) {
			if (this.$helpers.isBlank(val)) {
				this.businessName = this.userData.businessName;
			}
			this.isUpdatingProfile = this.checkIfUpdateNeeded();
		},
	},
	methods: {
		...mapActions(['showNotification']),
		resetAccount() {
			this.firstName = this.userData.firstName;
			this.lastName = this.userData.lastName;
			this.email = this.userData.email;
			this.address = this.userData.address;
			this.phoneNumber = this.userData.phoneNumber;
			if (this.userData.dateOfBirth) {
				this.dateOfBirth = this.$helpers.formatDate(
					new Date(this.userData.dateOfBirth),
					'yyyy-MM-dd'
				);
			}
			this.businessName = this.userData.businessName;
		},
		updateAccount() {
			this.userData.firstName = this.firstName;
			this.userData.lastName = this.lastName;
			this.userData.email = this.email;
			this.userData.address = this.address;
			this.userData.phoneNumber = this.phoneNumber;
			if (this.dateOfBirth) {
				var date = moment(this.dateOfBirth, 'yyyy-MM-DD').toDate();
				this.userData.dateOfBirth = this.$helpers.formatDate(date);
			}
			this.userData.businessName = this.businessName;
		},
		checkIfUpdateNeeded() {
			if (
				this.firstName != this.userData.firstName ||
				this.lastName != this.userData.lastName ||
				this.email != this.userData.email ||
				this.address != this.userData.address ||
				this.phoneNumber != this.userData.phoneNumber ||
				this.businessName != this.userData.businessName
			) {
				return true;
			} else if (this.dateOfBirth != null) {
				if (this.userData.dateOfBirth == null) {
					return true;
				}
				if (
					this.dateOfBirth !=
					this.$helpers.formatDate(
						new Date(this.userData.dateOfBirth),
						'yyyy-MM-dd'
					)
				) {
					return true;
				}
			}
			return false;
		},
		askToUpdateProfile() {
			this.showQuestionModal = true;
			return;
		},
		updateProfile() {
			if (this.email != this.userData.email) {
				this.updateEmail((isSuccess) => {
					if (isSuccess) {
						const newEmail = this.email;
						this.userData.email = newEmail;
						// update other fields.
						this.completeUpdate();
					}
				});
			} else {
				// update other fields
				this.completeUpdate();
			}
		},
		completeUpdate() {
			const firestore = getOrganizationFirestore(this.organization);
			const firebaseRef = doc(firestore, `users/${this.userData.uid}`);

			var updateInfo = {};

			if (this.firstName != this.userData.firstName) {
				updateInfo['firstName'] = this.firstName;
			}

			if (this.lastName != this.userData.lastName) {
				updateInfo['lastName'] = this.lastName;
			}

			if (this.address != this.userData.address) {
				updateInfo['address'] = this.address;
			}

			if (this.phoneNumber != this.userData.phoneNumber) {
				updateInfo['phoneNumber'] = this.phoneNumber;
			}

			if (this.dateOfBirth != null) {
				if (this.userData.dateOfBirth == null) {
					var date = moment(this.dateOfBirth, 'yyyy-MM-DD').toDate();
					updateInfo['dateOfBirth'] = this.$helpers.formatDate(date);
				} else if (
					this.dateOfBirth !=
					this.$helpers.formatDate(
						new Date(this.userData.dateOfBirth),
						'yyyy-MM-dd'
					)
				) {
					var date = moment(this.dateOfBirth, 'yyyy-MM-DD').toDate();
					updateInfo['dateOfBirth'] = this.$helpers.formatDate(date);
				}
			}

			if (this.businessName != this.userData.businessName) {
				updateInfo['businessName'] = this.businessName;
			}

			setDoc(firebaseRef, updateInfo, { merge: true })
				.then(() => {
					this.updateAccount();
					this.showNotification({
						type: 'success',
						message: 'Profile Updated.',
					});
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: `${error.message}`,
					});
				});
		},
		updateEmail(callback) {
			const updateUserEmail = httpsCallable(functions, 'updateUserEmail');
			updateUserEmail({
				organization: this.organization,
				uid: this.userData.uid,
				updatedEmail: this.email,
			})
				.then(() => {
					callback(true);
				})
				.catch((error) => {
					callback(false);
					this.showNotification({
						type: 'danger',
						message: `${error.message}`,
					});
				});
		},
		maskPhoneNumber() {
			this.phoneNumber = this.$helpers.maskPhoneNumber(this.phoneNumber);
		},
	},
	mounted() {
		this.resetAccount();
	},
};
</script>
