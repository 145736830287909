<template>
	<div>
		<Card no-body>
			<b-row align-v="center" slot="header">
				<b-col cols="8">
					<h3 class="mb-0">Purchase History</h3>
				</b-col>
				<b-col cols="4" class="text-right">
					<h3 class="mb-0">{{ allCharges.length }} Total</h3>
				</b-col>
			</b-row>
			<b-list-group>
				<b-list-group-item
					v-for="userCharge in allCharges"
					:key="userCharge.id"
					@click="openPayment(userCharge)"
				>
					<div class="d-flex justify-content-between align-items-center">
						<div>
							<h5
								class="mb-0 mr-2"
								style="
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-line-clamp: 1;
									-webkit-box-orient: vertical;
								"
							>
								{{ userCharge.description }}
							</h5>
							<h6
								class="text-muted m-0"
								style="
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-line-clamp: 1;
									-webkit-box-orient: vertical;
								"
							>
								{{ returnChargeInfo(userCharge).bottomText }}
							</h6>
							<h6
								class="text-muted m-0"
								style="
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-line-clamp: 1;
									-webkit-box-orient: vertical;
								"
							>
								Purchased
								{{ $helpers.formatDate(userCharge.created * 1000) }}
							</h6>
						</div>
						<div class="d-flex justify-content-end align-items-center m-0 p-0">
							<h5 class="m-0" :class="`${returnChargeInfo(userCharge).color}`">
								{{ returnChargeInfo(userCharge).text }}
							</h5>
							<i
								class="ml-2"
								:class="`${returnChargeInfo(userCharge).icon} ${
									returnChargeInfo(userCharge).color
								}`"
							></i>
							<i class="fas fa-chevron-right ml-2 text-muted"></i>
						</div>
					</div>
				</b-list-group-item>
			</b-list-group>
		</Card>
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';

export default {
	props: {
		allCharges: [],
	},
	components: { Card },
	methods: {
		returnChargeInfo(charge) {
			if (charge.subscription != null && charge.subscriptionQuantity != null) {
				if (charge.refunded) {
					return {
						color: 'text-orange',
						text: `Qty: ${charge.subscriptionQuantity}`,
						bottomText: 'Subscription',
						icon: 'fas fa-undo',
					};
				} else {
					return {
						color: 'text-primary',
						text: `Qty: ${charge.subscriptionQuantity}`,
						bottomText: 'Subscription Credit',
						icon: 'fas fa-check-circle',
					};
				}
			} else if (charge.refunded) {
				return {
					color: 'text-orange',
					text: `${this.$helpers.amountToString(charge.amount / 100)}`,
					bottomText: `Receipt sent to: ${charge.receipt_email}`,
					icon: 'fas fa-undo',
				};
			} else if (charge.status == 'succeeded') {
				return {
					color: 'text-success',
					text: `${this.$helpers.amountToString(charge.amount / 100)}`,
					bottomText: `Receipt sent to: ${charge.receipt_email}`,
					icon: 'fas fa-check-circle',
				};
			} else if (charge.status == 'pending') {
				return {
					color: 'text-yellow',
					text: `${this.$helpers.amountToString(charge.amount / 100)}`,
					bottomText: `Receipt sent to: ${charge.receipt_email}`,
					icon: 'fas fa-exclamation-circle',
				};
			} else {
				return {
					color: 'text-danger',
					text: `${this.$helpers.amountToString(charge.amount / 100)}`,
					bottomText: `Receipt sent to: ${charge.receipt_email}`,
					icon: 'fas fa-times-circle',
				};
			}
		},
		openPayment(charge) {
			this.$emit('openPayment', charge);
		},
	},
};
</script>
