<template>
	<div>
		<DashboardHeaderVue :title="$route.name" />
	</div>
</template>
<script>
import DashboardHeaderVue from '../HeaderFooterComponents/DashboardHeader.vue';

export default {
	name: 'settings',
	components: {
		DashboardHeaderVue,
	},
	data() {
		return {};
	},
};
</script>
