<template>
	<div>
		<Card no-body>
			<b-row v-if="headerTitle" align-v="center" slot="header">
				<b-col>
					<h3 class="mb-0">{{ headerTitle }}</h3>
				</b-col>
				<b-col v-if="headerButtonData" cols="4" class="text-right">
					<BaseButton
						@click="headerButtonClicked"
						:disabled="headerButtonData.isDisabled"
						size="sm"
						>{{ headerButtonData.title }}</BaseButton
					>
				</b-col>
			</b-row>
			<BaseInput
				id="filter-input"
				v-model="filter"
				type="search"
				class="mb-0 mt-3 mx-3"
				placeholder="Type to Search"
				prependIcon="fa fa-search"
			></BaseInput>
			<b-table
				:fields="tableHeaders"
				:items="tableData"
				:busy="isLoading"
				:filter="filter"
				small
				hover
				responsive="sm"
				@row-clicked="rowClicked"
				class="px-1"
			>
				<template #table-busy>
					<b-overlay :show="isLoading" rounded="sm" no-wrap />
				</template>
				<template #cell(nameAndPhoto)="data">
					<b-avatar
						class="mr-2"
						rounded="sm"
						style="
							background-color: transparent;
							object-fit: cover;
							border: 1px solid lightgray;
						"
						:src="data.item.businessLogo"
						:text="data.item.businessName"
					>
					</b-avatar>
					{{ data.item.businessName }}
				</template>
				<template #cell(businessId)="data">
					{{ data.item.businessId }}
				</template>
				<template #cell(businessBio)="data">
					<span
						style="
							display: block;
							overflow: hidden;
							width: 200px;
							white-space: nowrap;
							text-overflow: ellipsis;
						"
					>
						{{ data.item.businessBio }}
					</span>
				</template>
				<template #cell(isActive)="data">
					<BaseSwitch
						:value="data.value"
						:isDisabled="true"
						:type="data.value ? 'success' : 'primary'"
					/>
				</template>
				<template #cell(actions)="row">
					<BaseButton
						v-if="false"
						size="sm"
						outline
						@click="viewClicked(row.item)"
						>View</BaseButton
					>
					<BaseButton
						v-if="false"
						size="sm"
						type="danger"
						@click="deleteClicked(row.item)"
						>Report</BaseButton
					>
				</template>
			</b-table>
		</Card>
	</div>
</template>
<script>
import BaseButton from '../../../components/BaseButton.vue';
import BaseSwitch from '../../../components/BaseSwitch.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import Card from '../../../components/Cards/Card.vue';
export default {
	components: { BaseButton, BaseSwitch, BaseInput, Card },
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	props: {
		isLoading: false,
		filter: null,
		headerTitle: {
			type: String,
			default: null,
		},
		headerButtonData: {
			type: Object,
			default: null,
		},
		tableHeaders: {
			type: Array,
			description: 'The fields or table headers',
		},
		tableData: {
			type: Array,
			description: 'Data for table',
		},
	},
	data() {
		return {};
	},
	methods: {
		headerButtonClicked() {
			this.$emit('headerButtonClicked');
		},
		switchChanged(value, data) {
			this.$emit('onChanged', {
				value: value,
				data: data.item,
			});
		},
		viewClicked(item) {
			this.$emit('viewClicked', item);
		},
		deleteClicked(item) {
			this.$emit('deleteClicked', item);
		},
		rowClicked(item) {
			this.$emit('viewClicked', item);
		},
	},
};
</script>
