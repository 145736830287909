<template>
	<div>
		<Card no-body class="py-1">
			<FeedHeader :business="business"></FeedHeader>
			<FeedContent :type="type"></FeedContent>
			<FeedSocialButtons></FeedSocialButtons>
		</Card>
	</div>
</template>
<script>
import Card from '../Cards/Card.vue';
import FeedContent from './FeedContent.vue';
import FeedHeader from './FeedHeader.vue';
import FeedSocialButtons from './FeedSocialButtons.vue';

export default {
	components: { Card, FeedHeader, FeedContent, FeedSocialButtons },
	props: {
		business: {
			type: Object,
			default: null,
		},
		type: {
			type: String,
			default: null,
			description: 'Whether the content is ',
		},
	},
};
</script>
