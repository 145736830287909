<template>
	<div>
		<DashboardHeader
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<div class="container-fluid mt--6">
			<div class="row">
				<div class="col">
					<MembersTable
						:isLoading="isLoading"
						:tableData="allUsers"
						:tableHeaders="userTitles"
						@viewClicked="viewClicked"
						@deleteClicked="deleteClicked"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { collection, doc, getDoc, getDocs } from '@firebase/firestore';
import { httpsCallable } from '@firebase/functions';
import { mapActions, mapGetters } from 'vuex';
import { functions } from '../../../resources/firebase';
import { getOrganizationFirestore } from '../../../resources/organizationFirebase';
import MembersTable from '../../Components/MemberViews/MembersTable.vue';
import DashboardHeader from '../HeaderFooterComponents/DashboardHeader.vue';
import ReportsManager from '../../../models/ReportsManager';

export default {
	name: 'lead list',
	computed: {
		...mapGetters({
			organization: 'getOrganization',
			buisness: 'getBusiness',
		}),
	},
	components: { DashboardHeader, MembersTable },
	data() {
		return {
			isLoading: true,
			buttonItems: [
				{
					index: 0,
					title: 'Export List',
					type: 'primary',
					icon: 'fas fa-download',
				},
			],
			allUsers: [],
			userTitles: [
				{
					key: 'nameAndPhoto',
					label: 'Name',
					sortable: true,
					tdClass: 'align-middle',
					sortByFormatted: (value, key, item) => {
						return item.firstName + item.lastName;
					},
				},
				{
					key: 'email',
					label: 'Email',
					sortable: true,
					tdClass: 'align-middle',
				},
				{
					key: 'username',
					label: 'Username',
					tdClass: 'align-middle',
				},
				{
					key: 'uploadTimestamp',
					label: 'Created',
					sortable: true,
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return value != null ? this.$helpers.formatDate(value.toDate()) : null;
					},
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.generateLeadList();
			}
		},
		getLists() {
			this.isLoading = true;
			const firestore = getOrganizationFirestore(this.organization);
			const firestoreRef = collection(
				firestore,
				`business/${this.buisness.businessId}/leadList`
			);
			getDocs(firestoreRef).then((snapshot) => {
				if (!snapshot.empty) {
					const total = snapshot.size;
					var counter = 0;
					snapshot.forEach((item) => {
						var uid = item.data().uid;
						var uploadTimestamp = item.data().uploadTimestamp;
						if (uid) {
							const userRef = doc(firestore, `users/${uid}`);
							getDoc(userRef).then((snapshot) => {
								if (snapshot.exists()) {
									const user = snapshot.data();
									var userData = user;
									userData['uploadTimestamp'] = uploadTimestamp;
									this.allUsers.push(user);
									this.allUsers.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
								}
								if (counter == total - 1) {
									this.isLoading = false;
								}
								counter += 1;
							});
						} else {
							if (counter == total - 1) {
								this.isLoading = false;
							}
							counter += 1;
						}
					});
				} else {
					this.isLoading = false;
				}
			});
		},
		generateLeadList() {
			if (this.allUsers.length <= 0) {
				return;
			}
			this.isLoading = true;
			ReportsManager.generateLeadList(this.organization, this.buisness.businessId)
				.then((reportData) => {
					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: 'Success',
					});
					setTimeout(() => {
						window.open(reportData.reportDownloadLink, '_blank');
					});
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: `${error.message}`,
					});
				});
		},
	},
	mounted() {
		if (this.buisness) {
			this.getLists();
		}
	},
};
</script>
