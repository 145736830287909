<template>
	<div>
		<div v-if="type == 'image'" class="my-1">
			<VueAspectRatio
				:ar="isLandscape ? '16:9' : '1:1'"
				style="background-color: black"
			>
				<b-img fluid-grow :src="feedImage" />
			</VueAspectRatio>
		</div>
		<div
			v-if="type == 'group'"
			class="mx-2"
			style="border: 1px solid lightgray; border-radius: 5px; overflow: hidden"
		>
			<VueAspectRatio ar="16:9" style="background-color: black">
				<div class="b-img-helpers">
					<b-img
						fluid
						class="b-img-background-noblur"
						src="https://www.lhumc.org/hp_wordpress/wp-content/uploads/2021/04/youth-group-WEBSITE.jpg"
					/>
					<div class="b-img-overlay"></div>
					<div class="b-img-base-bottom p-3">
						<h2 class="mb-0 text-white">Title of Group</h2>
						<p class="mb-0 small text-white">description</p>
						<b-row class="text-white">
							<b-col class="d-flex align-items-center">
								<i class="mr-1 fas fa-lock-open" />
								<h6 class="mb-0 text-white">Public Grouop</h6>
							</b-col>
							<b-col class="d-flex align-items-center">
								<i class="mr-1 fas fa-users" />
								<h6 class="mb-0 text-white">0 Members</h6>
							</b-col>
							<b-col class="d-flex align-items-center">
								<i class="mr-1 fas fa-clock" />
								<h6 class="mb-0 text-white">Created: January 15th 2023</h6>
							</b-col>
						</b-row>
					</div>
				</div>
			</VueAspectRatio>
		</div>
		<div
			v-if="type == 'audio'"
			class="d-flex mx-2 align-items-center justify-content-between"
			style="border: 1px solid lightgray; border-radius: 5px; overflow: hidden"
		>
			<VueAspectRatio ar="1:1" width="100px" style="background-color: black">
				<b-img
					fluid
					src="https://i1.sndcdn.com/avatars-6VxdpBdK1BXXu4ki-YtmG1A-t240x240.jpg"
				/>
			</VueAspectRatio>
			<div style="width: 100%" class="mx-2 text-start">
				<h4 class="mb-0">Something New Is Coming | Pastor Ken</h4>
				<h6 class="mb-0 text-muted">Pastor Ken Write - 16 Plays</h6>
			</div>
			<BaseButton disabled icon="fas fa-play-circle" type="null" link></BaseButton>
		</div>
		<div
			v-if="type == 'video'"
			class="mx-2 align-items-center justify-content-between"
			style="border: 1px solid lightgray; border-radius: 5px; overflow: hidden"
		>
			<VueAspectRatio ar="16:9" style="background-color: black">
				<div class="b-img-helpers">
					<b-img
						fluid
						class="b-img-background-noblur"
						src="https://i.ytimg.com/vi/_ng6tdZNAo8/maxresdefault.jpg"
					/>
					<div class="b-img-overlay"></div>
				</div>
			</VueAspectRatio>
			<div class="m-2">
				<h6 class="mb-0 text-muted">250 views - 2hrs ago</h6>
				<h4 class="mb-0">Come Home | William Mcdowell</h4>
			</div>
		</div>
		<div
			v-if="type == 'event'"
			class="mx-2 align-items-center justify-content-between"
			style="border: 1px solid lightgray; border-radius: 5px; overflow: hidden"
		>
			<VueAspectRatio ar="16:9" style="background-color: black">
				<div class="b-img-helpers">
					<b-img
						fluid
						class="b-img-background-noblur"
						src="https://static.wixstatic.com/media/a9c230_d150f30daadf4e198bc42b54c4bc4c24~mv2.jpg/v1/fill/w_560,h_314,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/GuestRevealScreen.jpg"
					/>
					<div class="b-img-overlay"></div>
					<div class="b-img-base-bottom p-3">
						<b-row class="text-white">
							<b-col class="d-flex align-items-center">
								<i class="mr-1 fas fa-lock-open" />
								<h6 class="mb-0 text-white">Public Grouop</h6>
							</b-col>
							<b-col class="d-flex align-items-center">
								<i class="mr-1 fas fa-users" />
								<h6 class="mb-0 text-white">0 Members</h6>
							</b-col>
							<b-col class="d-flex align-items-center">
								<i class="mr-1 fas fa-clock" />
								<h6 class="mb-0 text-white">Created: January 15th 2023</h6>
							</b-col>
						</b-row>
					</div>
				</div>
			</VueAspectRatio>
			<div class="m-2">
				<h6 class="mb-0 text-muted">Today, 6:50pm</h6>
				<h4 class="mb-0">Habitation Conference</h4>
				<p class="small mb-0 text-muted">
					Habitation is an immersive environment. A life transforming environment
					where people who are passionately pursuing God encounter the One who has
					passionately pursued them. We are changed in His presence.
				</p>
				<BaseButton class="my-2" type="default" disabled block
					>View Event</BaseButton
				>
			</div>
		</div>
	</div>
</template>
<script>
import VueAspectRatio from 'vue-aspect-ratio';
import BaseButton from '../BaseButton.vue';
export default {
	components: { VueAspectRatio, BaseButton },
	props: {
		type: {
			type: String,
			default: null,
			description: 'Whether the content is ',
		},
		isLandscape: {
			type: Boolean,
			default: false,
		},
		feedImage: {
			type: String,
			default:
				'https://betterstudio.com/wp-content/uploads/2019/05/1-1-instagram-1024x1024.jpg',
		},
	},
};
</script>
