<template>
	<div>
		<b-overlay :show="isLoading" rounded="sm" no-wrap />
		<Card v-if="businessData" body-classes="p-3">
			<b-row align-v="center" slot="header">
				<b-col cols="8">
					<h3 class="mb-0">Account Information</h3>
				</b-col>
				<b-col cols="4" class="text-right">
					<BaseButton
						:disabled="!isUpdatedNeed"
						@click="updateBusinessInformation"
						size="sm"
						>Update Profile</BaseButton
					>
				</b-col>
			</b-row>

			<b-row align-v="center" slot="footer">
				<h6 class="text-muted m-0 px-2"></h6>
			</b-row>

			<b-form>
				<div class="px-lg-4">
					<b-row>
						<b-col>
							<base-input
								type="text"
								label="Business Name"
								placeholder="Business Name"
								name="businessName"
								v-model="updatedData.businessName"
							>
							</base-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<BaseInput label="Business Bio" class="mb-0">
								<textarea
									placeholder="Enter business bio..."
									v-model="updatedData.businessBio"
									maxlength="350"
									class="form-control"
									id="business bio"
									rows="4"
								/>
							</BaseInput>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<base-input
								type="email"
								label="Email"
								placeholder="Business Email"
								name="email"
								v-model="updatedData.businessSocial.email"
							>
							</base-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<base-input
								type="text"
								label="Website"
								placeholder="Business Website"
								name="website"
								v-model="updatedData.businessSocial.website"
							>
							</base-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<base-input
								type="text"
								label="Business Id"
								placeholder="Business Id"
								disabled
								:value="`${businessData.businessId.substring(0, 10)}****`"
							>
							</base-input>
						</b-col>
					</b-row>
					<b-row> </b-row>
				</div>
				<hr class="my-4" />

				<!-- Additional Info -->
				<h6 class="heading-small text-muted mb-2">Additional Information</h6>

				<div class="px-lg-4">
					<b-row>
						<b-col>
							<base-input
								type="text"
								label="Contact Number"
								placeholder="Enter Phone Number"
								v-model="updatedData.businessSocial.phoneNumber"
								@input="maskPhoneNumber"
							>
							</base-input>
						</b-col>
					</b-row>
					<b-row v-if="isFullAdmin">
						<b-col>
							<BaseInput
								label="Account Level"
								placeholder="Account Level"
								:disabled="!isFullAdmin"
							>
								<select
									class="form-control"
									id="business type"
									v-model="updatedData.businessLevel"
								>
									<option v-for="value in arrayOfLevels" :key="value">
										{{ value }}
									</option>
								</select>
							</BaseInput>
						</b-col>
					</b-row>
				</div>
				<hr class="my-4" />

				<!-- Additional Info -->
				<h6 class="heading-small text-muted mb-2">Social Information</h6>

				<div class="px-lg-4">
					<b-row>
						<b-col>
							<BaseInput
								label="Facebook"
								id="facebook"
								placeholder="Facebook"
								v-model="updatedData.businessSocial.facebook"
							/>
						</b-col>
						<b-col>
							<BaseInput
								label="Instagram"
								id="instagram"
								placeholder="Instagram"
								v-model="updatedData.businessSocial.instagram"
							/>
						</b-col>
						<b-col>
							<BaseInput
								label="Youtube"
								id="youtube"
								placeholder="Youtube"
								v-model="updatedData.businessSocial.youtube"
							/>
						</b-col>
					</b-row>
				</div>
			</b-form>
		</Card>
	</div>
</template>

<script>
import Card from '../../../components/Cards/Card.vue';
import BaseButton from '../../../components/BaseButton.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import { mapActions, mapGetters } from 'vuex';
import { functions } from '../../../resources/firebase';
import { httpsCallable } from 'firebase/functions';
import { getOrganizationFirestore } from '../../../resources/organizationFirebase';
import moment from 'moment';
import { doc, setDoc } from '@firebase/firestore';
import BaseInput from '../../../components/Inputs/BaseInput.vue';

export default {
	computed: {
		...mapGetters({
			isFullAdmin: 'isFullAdmin',
			organization: 'getOrganization',
		}),
	},
	props: {
		businessData: {
			type: Object,
			description: 'Business Information',
		},
	},
	components: { Card, BaseButton, QuestionModal, BaseInput },
	data() {
		return {
			isLoading: false,
			isUpdatedNeed: false,
			arrayOfLevels: [null, 'bronze', 'silver', 'gold'],
			updatedData: {
				businessName: null,
				businessLevel: null,
				businessBio: null,
				businessSocial: {
					email: null,
					facebook: null,
					instagram: null,
					phoneNumber: null,
					website: null,
				},
			},
		};
	},
	watch: {
		updatedData: {
			handler(val) {
				return (this.isUpdatedNeed = this.checkIfUpdateNeeded());
			},
			deep: true,
		},
	},
	methods: {
		...mapActions(['showNotification']),
		setupView() {
			this.updatedData = {
				businessName: this.businessData.businessName,
				businessLevel: this.getValue(this.businessData.businessLevel),
				businessBio: this.businessData.businessBio,
				businessSocial: {
					email: this.getValue(this.businessData.businessSocial.email),
					facebook: this.getValue(this.businessData.businessSocial.facebook),
					instagram: this.getValue(this.businessData.businessSocial.instagram),
					phoneNumber: this.getValue(this.businessData.businessSocial.phoneNumber),
					website: this.getValue(this.businessData.businessSocial.website),
					youtube: this.getValue(this.businessData.businessSocial.youtube),
				},
			};
		},
		checkIfUpdateNeeded() {
			if (this.updatedData.businessName !== this.businessData.businessName) {
				return true;
			} else if (this.updatedData.businessBio !== this.businessData.businessBio) {
				return true;
			} else if (
				this.updatedData.businessLevel !== this.businessData.businessLevel
			) {
				return true;
			} else if (
				this.getValue(this.updatedData.businessSocial.email) !==
				this.getValue(this.businessData.businessSocial.email)
			) {
				return true;
			} else if (
				this.getValue(this.updatedData.businessSocial.facebook) !==
				this.getValue(this.businessData.businessSocial.facebook)
			) {
				return true;
			} else if (
				this.getValue(this.updatedData.businessSocial.instagram) !==
				this.getValue(this.businessData.businessSocial.instagram)
			) {
				return true;
			} else if (
				this.getValue(this.updatedData.businessSocial.phoneNumber) !==
				this.getValue(this.businessData.businessSocial.phoneNumber)
			) {
				return true;
			} else if (
				this.getValue(this.updatedData.businessSocial.website) !==
				this.getValue(this.businessData.businessSocial.website)
			) {
				return true;
			} else if (
				this.getValue(this.updatedData.businessSocial.youtube) !==
				this.getValue(this.businessData.businessSocial.youtube)
			) {
				return true;
			}
			return false;
		},
		updateBusinessInformation() {
			this.isLoading = true;
			const firestore = getOrganizationFirestore(this.organization);
			const businessRef = doc(
				firestore,
				`business/${this.businessData.businessId}`
			);
			setDoc(businessRef, this.updatedData, { merge: true })
				.then(() => {
					this.isLoading = false;
					this.$emit('updatedProfile', this.updatedData);
					this.showNotification({
						type: 'success',
						message: 'Update Complete',
					});
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: error.message,
					});
				});
		},
		maskPhoneNumber() {
			this.phoneNumber = this.$helpers.maskPhoneNumber(this.phoneNumber);
		},
		getValue(value) {
			if (value === undefined || value === null || this.$helpers.isBlank(value)) {
				return null;
			}
			return value;
		},
	},
	mounted() {
		this.setupView();
	},
};
</script>
