<template>
	<div>
		<Card no-body>
			<b-row v-if="headerTitle" align-v="center" slot="header">
				<b-col>
					<h3 class="mb-0">{{ headerTitle }}</h3>
				</b-col>
				<b-col v-if="headerButtonData" cols="4" class="text-right">
					<BaseButton
						@click="headerButtonClicked"
						:disabled="headerButtonData.isDisabled"
						size="sm"
						>{{ headerButtonData.title }}</BaseButton
					>
				</b-col>
			</b-row>
			<BaseInput
				id="filter-input"
				v-model="filter"
				type="search"
				class="mb-0 mt-3 mx-3"
				placeholder="Type to Search"
				prependIcon="fa fa-search"
			></BaseInput>
			<b-table
				:fields="tableHeaders"
				:items="tableData"
				:busy="isLoading"
				:filter="filter"
				small
				fixed
				hover
				responsive="sm"
				@row-clicked="rowClicked"
				class="px-1"
			>
				<template #table-busy>
					<b-overlay :show="isLoading" rounded="sm" no-wrap />
				</template>
				<template #cell(nameAndPhoto)="data">
					<b-img-lazy
						:src="
							data.item.speakerPhoto
								? data.item.speakerPhoto
								: 'https://firebasestorage.googleapis.com/v0/b/mysunday-v2.appspot.com/o/users%2FdefaultProfilePicture.png?alt=media&token=8488a06a-64ee-44b1-a4e3-10250d478545'
						"
						:text="data.item.speakerName"
						rounded="circle"
						class="avatar mr-2"
					/>
					{{ data.item.speakerName }}
				</template>
				<template #cell(speakerBio)="data">
					<div class="text-truncate">
						{{ data.item.speakerBio }}
					</div>
				</template>
				<template #cell(actions)="row">
					<b-row class="justify-content-center align-items-center">
						<b-col>
							<BaseButton
								class="my-1"
								block
								size="sm"
								outline
								@click="editClicked(row.item)"
								>Edit</BaseButton
							>
						</b-col>
						<b-col>
							<BaseButton
								class="my-1"
								block
								size="sm"
								type="danger"
								@click="removeClicked(row.item)"
								>Remove</BaseButton
							>
						</b-col>
					</b-row>
				</template>
			</b-table>
		</Card>
	</div>
</template>
<script>
import BaseButton from '../../../components/BaseButton.vue';
import BaseSwitch from '../../../components/BaseSwitch.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import Card from '../../../components/Cards/Card.vue';
export default {
	components: { BaseButton, BaseSwitch, BaseInput, Card },
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	props: {
		isLoading: false,
		filter: null,
		headerTitle: {
			type: String,
			default: null,
		},
		headerButtonData: {
			type: Object,
			default: null,
		},
		tableHeaders: {
			type: Array,
			description: 'The fields or table headers',
		},
		tableData: {
			type: Array,
			description: 'Data for table',
		},
	},
	data() {
		return {};
	},
	methods: {
		headerButtonClicked() {
			this.$emit('headerButtonClicked');
		},
		switchChanged(value, data) {
			this.$emit('onChanged', {
				value: value,
				data: data.item,
			});
		},
		removeClicked(item) {
			this.$emit('removeClicked', item);
		},
		editClicked(item) {
			this.$emit('editClicked', item);
		},
		rowClicked(item) {
			this.$emit('editClicked', item);
			//this.$emit('viewClicked', item);
		},
	},
};
</script>
