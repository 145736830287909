<template>
	<div>
		<Card no-body class="p-2">
			<OrganizationHeader
				:business="business"
				@editButtonClicked="editButtonClicked"
				@editLogoButtonClicked="showChangeImageModal = true"
			></OrganizationHeader>
			<b-tabs
				v-if="false"
				fill
				class="m-2"
				active-nav-item-class="bg-white font-weight-bold description"
				nav-class="bg-white description"
			>
				<b-tab title="Posts" active>
					<b-list-group class="py-2 p-0">
						<b-list-group-item class="m-0 p-0">
							<FeedItem :business="business" type="image" />
							<FeedItem :business="business" type="image" />
							<FeedItem :business="business" type="image" />
						</b-list-group-item>
					</b-list-group>
				</b-tab>
			</b-tabs>
		</Card>
		<ChangeImage
			:showModal="showChangeImageModal"
			@updatedImage="addedImage"
			@close="showChangeImageModal = false"
		/>
	</div>
</template>
<script>
import { doc, setDoc } from '@firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from '@firebase/storage';
import { mapActions, mapGetters } from 'vuex';
import Card from '../../../components/Cards/Card.vue';
import FeedItem from '../../../components/Feed/FeedItem.vue';
import OrganizationHeader from '../../../components/Profile/OrganizationHeader.vue';
import {
	getOrganizationFirestore,
	getOrganizationStorage,
} from '../../../resources/organizationFirebase';
import ChangeImage from '../../mainForms/ChangeImage.vue';

export default {
	components: { Card, OrganizationHeader, FeedItem, ChangeImage },
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	props: {
		business: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			showChangeImageModal: false,
		};
	},
	methods: {
		...mapActions(['showNotification']),
		editButtonClicked() {
			this.$emit('editButtonClicked');
		},
		addedImage(file) {
			this.isLoading = true;
			const updatedImage = file[0];
			const firestore = getOrganizationFirestore(this.organization);
			const storage = getOrganizationStorage(this.organization);

			const businessRef = doc(firestore, `business/${this.business.businessId}`);
			const businessStorageRef = ref(
				storage,
				`business/${this.business.businessId}/logo`
			);
			const metadata = {
				contentType: 'image/jpg',
			};
			uploadBytes(businessStorageRef, updatedImage, metadata).then((snapshot) => {
				getDownloadURL(snapshot.ref)
					.then((downloadURL) => {
						setDoc(businessRef, { businessLogo: downloadURL }, { merge: true })
							.then(() => {
								this.$emit('updateBusiness');
							})
							.catch((error) => {
								this.showNotification({
									type: 'danger',
									message: error.message,
								});
							});
					})
					.catch((error) => {
						this.showNotification({
							type: 'danger',
							message: error.message,
						});
					});
			});
		},
	},
};
</script>
