import Login from '../views/mainViews/Login.vue';
import DashboardLayout from '../views/mainViews/DashboardLayout.vue';
import Home from '../views/mainViews/DashboardViews/Home.vue';
import BusinessProfile from '../views/mainViews/DashboardViews/BusinessProfile.vue';
import Schedule from '../views/mainViews/DashboardViews/Schedule.vue';
import Settings from '../views/mainViews/DashboardViews/Settings.vue';
import Speakers from '../views/mainViews/DashboardViews/Speakers.vue';
import Vendors from '../views/mainViews/DashboardViews/Vendors.vue';
import VendorView from '../views/mainViews/DashboardViews/SecondaryViews/VendorView.vue';
import LeadList from '../views/mainViews/DashboardViews/LeadList.vue';
import Members from '../views/mainViews/DashboardViews/Members.vue';
import TempMembers from '../views/mainViews/DashboardViews/TempMembers.vue';
import MemberView from '../views/mainViews/DashboardViews/SecondaryViews/MemberView.vue';
import AdminNotifications from '../views/mainViews/DashboardViews/AdminNotifications.vue';
import Videos from '../views/mainViews/DashboardViews/Videos.vue';
import Notifications from '../views/mainViews/DashboardViews/Notifications.vue';
import Messages from '../views/mainViews/DashboardViews/Messages.vue';
import Billing from '../views/mainViews/DashboardViews/Billing.vue';
import EventItemView from '../views/mainViews/DashboardViews/SecondaryViews/EventItemView';
import EventItemUsersView from '../views/mainViews/DashboardViews/SecondaryViews/EventItemUsersView.vue';
import NotFoundPage from '../views/mainViews/NotFoundPage';

export const LoginPaths = {
	login: {
		id: 'login',
		name: 'Login',
		path: '/login',
		view: Login,
		meta: { alreadyAuth: true },
	},
};

export const DashboardPaths = {
	home: {
		id: 'home',
		name: 'Dashboard',
		path: '/',
		redirect: '/dashboard',
		view: DashboardLayout,
		meta: { requiresAuth: true },
	},
	notFound: {
		id: 'home',
		name: 'Page Not Found',
		path: '*',
		view: NotFoundPage,
	},
};

export const DashboardChildren = {
	home: {
		id: 'dashboardHome',
		name: 'Home',
		icon: 'fas fa-home',
		path: '/dashboard',
		view: Home,
		meta: { isBusiness: true },
	},
	schedule: {
		id: 'schedule',
		name: 'Schedule',
		icon: 'fas fa-calendar-alt',
		path: '/dashboard/schedule',
		view: Schedule,
	},
	eventView: {
		id: 'eventView',
		name: 'Event View',
		path: '/dashboard/schedule/event/:eventId',
		view: EventItemView,
		props: {
			default: (route) => ({
				eventId: route.params.eventId,
				eventStart: route.query.eventStart,
			}),
		},
	},
	eventViewUsers: {
		id: 'eventViewUsers',
		name: 'Event View Users',
		path: '/dashboard/schedule/event/:eventId/users',
		view: EventItemUsersView,
		props: {
			default: (route) => ({
				eventId: route.params.eventId,
				eventStart: route.query.eventStart,
			}),
		},
	},

	businessProfile: {
		id: 'businessProfile',
		name: 'Account',
		icon: 'fas fa-briefcase',
		path: '/dashboard/business',
		view: BusinessProfile,
		meta: { isBusiness: true },
	},
	leadList: {
		id: 'leadList',
		name: 'Lead List',
		icon: 'fas fa-user-friends',
		path: '/dashboard/leadlist',
		view: LeadList,
		meta: { isBusiness: true },
	},
	speakers: {
		id: 'speakers',
		name: 'Speakers',
		icon: 'fas fa-user-circle',
		path: '/dashboard/speakers',
		view: Speakers,
	},
	videos: {
		id: 'videos',
		name: 'Videos',
		icon: 'fas fa-video',
		path: '/dashboard/videos',
		view: Videos,
	},
	vendors: {
		id: 'exhibitors',
		name: 'Exhibitors',
		icon: 'fas fa-briefcase',
		path: '/dashboard/exhibitors',
		view: Vendors,
	},
	vendorView: {
		id: 'vendorView',
		name: 'Shop View',
		path: '/dashboard/exhibitors/:businessId',
		view: VendorView,
		props: {
			default: (route) => ({
				businessId: route.params.businessId,
			}),
		},
	},

	messages: {
		id: 'messages',
		name: 'Messages',
		icon: 'fas fa-envelope',
		path: '/dashboard/messages',
		view: Messages,
		isHidden: true,
	},
	members: {
		id: 'members',
		name: 'Members',
		icon: 'fas fa-user-friends',
		path: '/dashboard/members',
		view: Members,
	},
	tempMembers: {
		id: 'tempMembers',
		name: 'Pending Members',
		icon: 'fas fa-user-shield',
		path: '/dashboard/pendingmembers',
		view: TempMembers,
	},
	memberProfile: {
		id: 'profile',
		name: 'Profile',
		path: '/dashboard/members/profile',
		view: MemberView,
		props: { default: (route) => ({ uid: route.query.uid }) },
	},
	notifications: {
		id: 'notifications',
		name: 'Notifications',
		icon: 'fas fa-bell',
		path: '/dashboard/notifications',
		view: Notifications,
	},
	adminNotifications: {
		id: 'adminNotifications',
		name: 'Admin Notifications',
		path: '/dashboard/admin-notifications',
		view: AdminNotifications,
	},
	billing: {
		id: 'billing',
		name: 'Billing',
		icon: 'fas fa-wallet',
		path: '/dashboard/billing',
		isComingSoon: true,
		view: Billing,
	},
	settings: {
		id: 'settings',
		name: 'Settings',
		icon: 'fas fa-cog',
		path: '/dashboard/settings',
		view: Settings,
		isHidden: true,
	},
};
