import { getApps, initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import store from '../store/store';

function initialOrganizationFirebase() {
	const organization = store.state.organization;

	const firebaseConfig = {
		apiKey: organization.apiKey,
		authDomain: organization.authDomain,
		projectId: organization.projectId,
		databaseURL: organization.databaseURL,
		storageBucket: organization.storageBucket,
		messagingSenderId: organization.messagingSenderId,
		appId: organization.appId,
		measurementId: organization.measurementId,
	};

	const app = getApps().find((x) => x.name === organization.appName);

	if (app) {
		return app;
	} else {
		// Initialize Firebase
		const app = initializeApp(firebaseConfig, organization.appName);
		return app;
	}
}

function getOrganizationStorage() {
	const app = initialOrganizationFirebase();
	//Storage
	const storage = getStorage(app);

	return storage;
}

function getOrganizationFirestore() {
	const app = initialOrganizationFirebase();
	// Firestore
	const firestore = getFirestore(app);

	return firestore;
}

export { getOrganizationStorage, getOrganizationFirestore };
