<template>
	<div>
		<DashboardHeaderVue
			v-if="businessData"
			:title="businessData.businessName"
			:buttonItems="buttonItems"
			@childClick="childClick"
		/>
		<div v-if="businessData" class="container-fluid mt--6">
			<b-overlay :show="isLoading" rounded="sm">
				<b-row v-if="false">
					<b-col>
						<Card body-classes="p-3">
							<div
								class="text-center d-flex justify-content-between align-items-center"
							>
								<div class="d-flex justify-content-start align-items-center">
									<b-img-lazy
										:src="businessData.businessLogo"
										:text="businessData.buisnessName"
										rounded="circle"
										class="mr-3 avatar-xl"
									/>

									<div class="align-items-left text-left">
										<h2 class="title m-0">
											{{ businessData.businessName }}
										</h2>
										<h6 class="m-0">
											{{ businessData.businessSocial.website }}
										</h6>
									</div>
								</div>
							</div>
						</Card>
					</b-col>
				</b-row>
				<b-row>
					<b-col style="max-width: 1000px">
						<VenderProfile
							:business="businessData"
							@editButtonClicked="openFeaturedModal"
							@updateBusiness="getBusiness"
						/>
					</b-col>
					<b-col class="d-none d-md-inline" md="6">
						<b-row>
							<b-col>
								<FeaturedCard
									:allFeaturedItems="allFeaturedItems"
									@deleteFeaturedItem="deleteFeaturedItem"
								/>
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<ResourceCard :businessId="businessData.businessId" />
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<FAQCard :businessData="businessData" />
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<BasicInformationCard1
									:businessData="businessData"
									@updatedProfile="getBusiness"
								/>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-overlay>
		</div>
		<NewResource
			:headerTitle="buttonItems[0].children[this.newResourceId].title"
			:showModal="showVendorModal"
			:businessData="businessData"
			@close="showVendorModal = false"
			@newResource="onNewResource"
		/>
		<NewFeatured
			:headerTitle="buttonItems[0].children[this.newFeaturedId].title"
			:showModal="showFeaturedModal"
			:businessData="businessData"
			@close="showFeaturedModal = false"
			@newFeatured="onNewFeatured"
		/>
		<NewFAQ
			:headerTitle="buttonItems[0].children[this.newFaqId].title"
			:showModal="showFAQModal"
			:businessData="businessData"
			@close="showFAQModal = false"
			@newFaq="onNewFAQ"
		/>
		<ChangeImage
			:showModal="showChangeImageModal"
			@updatedImage="addedImage"
			@close="showChangeImageModal = false"
		/>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@yesClicked="deleteVendor"
			@close="showQuestionModal = false"
		/>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {
	getOrganizationFirestore,
	getOrganizationStorage,
} from '../../../../resources/organizationFirebase';
import { DashboardChildren } from '../../../../routes/routesNames';
import DashboardHeaderVue from '../../HeaderFooterComponents/DashboardHeader.vue';
import Card from '../../../../components/Cards/Card.vue';
import BaseButton from '../../../../components/BaseButton.vue';
import BaseSwitch from '../../../../components/BaseSwitch.vue';
import BasicInformationCard from '../../../Components/MemberViews/BasicInformationCard.vue';
import SubscriptionCard from '../../../Components/MemberViews/SubscriptionCard.vue';
import Badge from '../../../../components/Badge.vue';
import CreditCard from '../../../Components/MemberViews/CreditCard.vue';
import PaymentHistoryCard from '../../../Components/MemberViews/PaymentHistoryCard.vue';
import { DominionTerminalFunctions } from '../../../../resources/DominionInformation';
import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	limit,
	orderBy,
	query,
	setDoc,
} from '@firebase/firestore';
import {
	uploadBytes,
	getDownloadURL,
	ref as firebaseStorageRef,
	deleteObject,
	ref,
} from 'firebase/storage';
import AccountLevelCard from '../../../Components/MemberViews/AccountLevelCard.vue';
import BasicInformationCard1 from '../../../Components/VendorViews/BasicInformationCard.vue';
import ResourceCard from '../../../Components/VendorViews/ResourceCard.vue';
import NewVendor from '../../../mainForms/NewVendor.vue';
import NewResource from '../../../mainForms/NewResource.vue';
import FeaturedCard from '../../../Components/VendorViews/FeaturedCard.vue';
import FAQCard from '../../../Components/VendorViews/FAQCard.vue';
import NewFeatured from '../../../mainForms/NewFeatured.vue';
import { httpsCallable } from '@firebase/functions';
import { functions } from '../../../../resources/firebase';
import VenderProfile from '../../../Components/VendorViews/VenderProfile.vue';
import { allSettled } from 'q';
import ChangeImage from '../../../mainForms/ChangeImage.vue';
import NewFAQ from '../../../mainForms/NewFAQ.vue';
import ReportsManager from '../../../../models/ReportsManager';
import QuestionModal from '../../../../components/Modals/QuestionModal.vue';

export default {
	name: 'member',
	computed: {
		...mapGetters({
			isFullAdmin: 'isFullAdmin',
			organization: 'getOrganization',
		}),
	},
	components: {
		DashboardHeaderVue,
		Card,
		BaseButton,
		BaseSwitch,
		BasicInformationCard,
		SubscriptionCard,
		Badge,
		CreditCard,
		PaymentHistoryCard,
		AccountLevelCard,
		BasicInformationCard1,
		ResourceCard,
		NewVendor,
		NewResource,
		FeaturedCard,
		FAQCard,
		NewFeatured,
		VenderProfile,
		ChangeImage,
		NewFAQ,
		QuestionModal,
	},
	props: {
		businessId: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isLoading: false,
			businessData: null,
			allFeaturedItems: [],
			showVendorModal: false,
			showFAQModal: false,
			showFeaturedModal: false,
			showChangeImageModal: false,
			showQuestionModal: false,
			modalQuestion: '',
			contactId: 4,
			newFeaturedId: 0,
			newResourceId: 1,
			generateLeadListId: 3,
			newFaqId: 2,
			deleteVendorId: 5,
			buttonItems: [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [],
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		setButtons() {
			this.buttonItems[0].children = [
				{
					index: this.newFeaturedId,
					title: 'Add New Featured',
				},
				{
					index: this.newResourceId,
					title: 'Add New Resource',
				},
				{
					index: this.newFaqId,
					title: 'Add New FAQ',
				},
				{
					index: this.generateLeadListId,
					title: 'Generate Lead List',
				},
				{
					index: -1,
					title: '',
					isDivider: true,
				},
				{
					index: this.contactId,
					title: 'Contact Business',
				},
			];
			if (this.isFullAdmin) {
				this.buttonItems[0].children.push(
					{
						index: -2,
						title: '',
						isDivider: true,
					},
					{
						index: this.deleteVendorId,
						type: 'danger',
						title: 'Remove Vendor',
					}
				);
			}
		},
		getBusiness() {
			const firebase = getOrganizationFirestore(this.organization);
			const firebaseRef = doc(firebase, `business/${this.businessId}`);
			getDoc(firebaseRef).then((snapshot) => {
				if (snapshot.exists) {
					this.businessData = snapshot.data();
				}
			});
		},
		getFeatured() {
			const firebase = getOrganizationFirestore(this.organization);
			const featuredRef = collection(
				firebase,
				`business/${this.businessId}/featuredItems`
			);
			const queryRef = query(featuredRef, orderBy('index'));

			getDocs(queryRef).then((snapshot) => {
				if (!snapshot.empty) {
					snapshot.forEach((document) => {
						this.allFeaturedItems.push(document.data());
					});
				}
			});
		},

		onNewFeatured(item) {
			this.allFeaturedItems.push(item);
			this.allFeaturedItems.sort((a, b) => {
				return a.index - b.index;
			});
			this.showNotification({
				type: 'success',
				message: `New Featured item has been added.`,
			});
		},
		onNewResource() {
			this.businessData = this.businessData;
			this.showNotification({
				type: 'success',
				message: `New Resource item has been added. Please refresh.`,
			});
		},
		onNewFAQ() {
			this.businessData = this.businessData;
			this.showNotification({
				type: 'success',
				message: `New FAQ item has been added. Please refresh.`,
			});
		},
		deleteFeaturedItem(featuredItem) {
			const firestore = getOrganizationFirestore(this.organization);
			const storage = getOrganizationStorage(this.organization);
			const featuredStoreRef = firebaseStorageRef(
				storage,
				`business/${this.businessData.businessId}/featuredItems/${featuredItem.featuredId}`
			);
			const featuredItemRef = doc(
				firestore,
				`business/${this.businessData.businessId}/featuredItems/${featuredItem.featuredId}`
			);
			if (!featuredItem.imageItem.imageUrl.includes('i.ytimg.com')) {
				deleteObject(featuredStoreRef)
					.then(() => {
						deleteDoc(featuredItemRef)
							.then(() => {
								this.isLoading = true;
								this.allFeaturedItems = this.allFeaturedItems.filter(
									(item) => item.featuredId != featuredItem.featuredId
								);

								this.isLoading = false;
								this.showNotification({
									type: 'success',
									message: `${featuredItem.featuredId} Deleted.`,
								});
							})
							.catch((error) => {
								this.showNotification({
									type: 'danger',
									message: error.message,
								});
							});
					})
					.catch((error) => {
						this.showNotification({
							type: 'danger',
							message: error.message,
						});
					});
			} else {
				deleteDoc(featuredItemRef)
					.then(() => {
						this.isLoading = true;
						this.allFeaturedItems = this.allFeaturedItems.filter(
							(item) => item.featuredId != featuredItem.featuredId
						);

						this.isLoading = false;
						this.showNotification({
							type: 'success',
							message: `${featuredItem.featuredId} Deleted.`,
						});
					})
					.catch((error) => {
						this.showNotification({
							type: 'danger',
							message: error.message,
						});
					});
			}
		},
		generateLeadList() {
			this.isLoading = true;
			ReportsManager.generateLeadList(
				this.organization,
				this.businessData.businessId
			)
				.then((reportData) => {
					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: 'Success',
					});
					setTimeout(() => {
						window.open(reportData.reportDownloadLink, '_blank');
					});
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: `${error.message}`,
					});
				});
		},
		addedImage(file) {
			console.log(file);
		},
		deleteVendor() {
			const firebase = getOrganizationFirestore(this.organization);
			const firebaseRef = doc(firebase, `business/${this.businessId}`);
			deleteDoc(firebaseRef)
				.then(() => {
					this.showNotification({
						type: 'success',
						message: 'Success',
					});
					this.$router.replace({
						name: DashboardChildren.vendors.name,
					});
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: `${error.message}`,
					});
				});
		},
		openEmail() {
			if (this.businessData.businessSocial.email != null) {
				setTimeout(() => {
					window
						.open(`mailto:${this.businessData.businessSocial.email}`, '_blank')
						.focus();
				});
			}
		},
		childClick(childClicked) {
			this.selectedId = childClicked.index;
			switch (childClicked.index) {
				case this.newResourceId:
					this.showVendorModal = true;
					break;
				case this.newFeaturedId:
					this.openFeaturedModal();
					break;
				case this.newFaqId:
					this.openFAQModal();
					break;
				case this.generateLeadListId:
					this.generateLeadList();
					break;
				case this.contactId:
					this.openEmail();
					break;
				case this.deleteVendorId:
					this.modalQuestion = 'Are you sure you want to delete this vendor?';
					this.showQuestionModal = true;
					break;
				default:
					break;
			}
		},
		openFeaturedModal() {
			this.showFeaturedModal = true;
		},
		openFAQModal() {
			this.showFAQModal = true;
		},
		openChangeImageModal() {
			this.showChangeImageModal = true;
		},
	},
	mounted() {
		if (!this.businessId) {
			this.$router.replace({
				name: DashboardChildren.home.name,
			});
		} else {
			this.setButtons();
			this.getBusiness();
			this.getFeatured();
		}
	},
};
</script>
