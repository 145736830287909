<template>
	<div>
		<Notifications />
		<!-- Page content -->

		<b-container class="mt-8 pb-5">
			<b-row class="justify-content-center">
				<b-col lg="5" md="7">
					<b-card v-if="organization" no-body class="mb-0">
						<b-overlay :show="isLoading" rounded="sm">
							<b-card-body class="px-lg-4 py-lg-4">
								<div class="d-flex pb-4 justify-content-center">
									<b-img
										fluid
										:src="organization.appLogo"
										style="width: 30%; border-radius: 10px; object-fit: cover"
									/>
								</div>
								<h4 class="text-center m-0">Welcome to {{ organization.appName }}!</h4>
								<h6 class="text-center mb-2">
									Please login with your admin portal credentials.
								</h6>
								<hr class="my-3 mx-3" />
								<validation-observer v-slot="{ handleSubmit }" ref="formValidator">
									<b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
										<base-input
											alternative
											class="mb-3"
											name="Email"
											:rules="{ required: true }"
											prepend-icon="ni ni-email-83"
											placeholder="Email"
											v-model="model.email"
										>
										</base-input>

										<base-input
											alternative
											class="mb-3"
											name="Password"
											:rules="{ required: true, min: 6 }"
											prepend-icon="ni ni-lock-circle-open"
											type="password"
											placeholder="Password"
											v-model="model.password"
										>
										</base-input>

										<div class="text-center">
											<base-button type="primary" native-type="submit" class="my-4" block
												>Sign in</base-button
											>
										</div>
									</b-form>
								</validation-observer>
								<div class="bg-transparent" style="display: none">
									<div class="btn-wrapper text-center">
										<div class="text-center text-muted mb-4">
											<small>Or Sign In With</small>
										</div>
										<a href="#" class="btn btn-neutral btn-icon">
											<span class="btn-inner--icon"
												><img src="img/icons/common/github.svg"
											/></span>
											<span class="btn-inner--text">Github</span>
										</a>
										<a href="#" class="btn btn-neutral btn-icon">
											<span class="btn-inner--icon"
												><img src="img/icons/common/google.svg"
											/></span>
											<span class="btn-inner--text">Google</span>
										</a>
									</div>
								</div>
							</b-card-body>
						</b-overlay>
					</b-card>
					<b-card v-else no-body class="mb-0">
						<b-overlay :show="isLoading" rounded="sm">
							<b-card-body class="px-lg-4 py-lg-4">
								<h2 class="text-center m-0" style="font-weight: bold">
									Select A Conference:
								</h2>
								<b-row>
									<b-col
										v-for="conference in conferencesData"
										:key="conference.orgId"
										class="m-4 specialOrg"
										@click="openConferenceAdmin(conference.adminPortal)"
									>
										<h6 class="text-center">{{ conference.appName }}</h6>
										<b-img
											fluid
											:src="conference.appLogo"
											style="width: 100%; border-radius: 10px; object-fit: cover"
										/>
									</b-col>
								</b-row>
							</b-card-body>
						</b-overlay>
					</b-card>

					<b-row class="mt-3">
						<div style="flex-grow: 1"></div>
						<router-link to="" class="text-light text-center"
							><small
								>If you don't see your conference, please double check with your
								conference admins for the correct link.</small
							></router-link
						>
						<div style="flex-grow: 1"></div>
					</b-row>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Notifications from '../../components/NotificationPlugin/Notifications.vue';
import store from '../../store/store';

export default {
	name: 'login',
	components: {
		Notifications,
	},
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	data() {
		return {
			isLoading: false,
			conferencesData: [],
			model: {
				email: '',
				password: '',
				rememberMe: false,
			},
		};
	},
	methods: {
		...mapActions(['login', 'getAppUserData', 'getConferences']),
		openConferenceAdmin(link) {
			var open = window.open(link, '_blank');
			if (open == null || typeof open == 'undefined') {
				alert('Turn off your pop-up blocker!');
			} else {
				open.focus();
			}
		},
		onSubmit() {
			this.isLoading = true;
			this.login({
				email: this.model.email,
				password: this.model.password,
			})
				.then(() => {
					this.getAppUserData()
						.then(() => {
							this.isLoading = false;
							this.$router.push('/');
						})
						.catch((error) => {
							this.isLoading = false;
							this.$notify({
								verticalAlign: 'top',
								horizontalAlign: 'center',
								type: 'danger',
								message:
									'You do not have access to this portal. Please contact your support team.',
							});
						});
					/*
					this.isLoading = false;
					this.$router.push('/');
					*/
				})
				.catch((error) => {
					this.isLoading = false;
					this.$notify({
						verticalAlign: 'top',
						horizontalAlign: 'center',
						type: 'danger',
						message: 'The username or password you entered is incorrect.',
					});
				});
		},
	},
	mounted() {
		if (!this.organization) {
			this.getConferences().then((data) => {
				this.conferencesData = data;
				console.log('fetch conference', data);
			});
		}
	},
};
</script>
<style>
.specialOrg {
	transition: all 0.2s ease-in-out;
}
.specialOrg:hover {
	transform: scale(1.1);
	cursor: pointer;
}
</style>
