<template>
	<div v-if="eventData">
		<DashboardHeaderVue
			:title="eventData.eventTitle"
			:buttonItems="buttonItems"
			@childClick="childClick"
		/>
		<div class="container-fluid mt--6">
			<div class="row">
				<div class="col">
					<MembersTable
						:isLoading="isLoading"
						:headerTitle="`${eventData.eventTitle} · ${$helpers.formatDate(
							new Date(eventData.eventStart)
						)}`"
						:headerButtonData="{
							index: 0,
							title: 'Save',
							isDisabled: !isUpdating,
						}"
						:tableData="eventAttendees"
						:tableHeaders="userTitles"
						@onChanged="onSwitchChange"
						@viewClicked="viewClicked"
						@headerButtonClicked="headerButtonClicked"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { get, ref, update } from '@firebase/database';
import { mapActions, mapGetters } from 'vuex';
import { getOrganizationDatabase } from '../../../../resources/organizationFirebase';
import { DashboardChildren } from '../../../../routes/routesNames';
import DashboardHeaderVue from '../../HeaderFooterComponents/DashboardHeader.vue';
import MembersTable from '../../../Components/MemberViews/MembersTable.vue';

export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	props: {
		eventId: {
			type: String,
			default: null,
		},
		eventStart: {
			type: [String, Number],
			default: null,
		},
	},
	components: {
		DashboardHeaderVue,
		MembersTable,
	},
	data() {
		return {
			isLoading: false,
			eventData: null,
			eventAttendees: [],
			updatedEventAttendees: [],
			isUpdating: false,
			selectedId: null,
			buttonItems: [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [],
				},
			],
			userTitles: [
				{
					key: 'nameAndPhoto',
					label: 'User',
					sortable: true,
					tdClass: 'align-middle',
					thStyle: { width: '80%' },
					sortByFormatted: (value, key, item) => {
						return item.fullname;
					},
				},
				{
					key: 'waiverStatus',
					label: 'Waiver',
					sortable: true,
					tdClass: 'align-middle',
					thStyle: { width: '10%' },
					sortByFormatted: (value, key, item) => {
						return (
							this.$helpers.userReturnSignedWaiver(item, this.activeWaiverId) !=
							null
						);
					},
					formatter: (value, key, item) => {
						return (
							this.$helpers.userReturnSignedWaiver(item, this.activeWaiverId) !=
							null
						);
					},
				},
				{
					key: 'quantity',
					label: 'Quantity',
					sortable: true,
					tdClass: 'align-middle',
					thStyle: { width: '10%' },
					sortByFormatted: (value, key, item) => {
						return item.quantity;
					},
					formatter: (value, key, item) => {
						return item.quantity;
					},
				},
				{
					key: 'attendanceStatus',
					label: 'Attendance',
					sortable: true,
					tdClass: 'align-middle',
					sortByFormatted: (value, key, item) => {
						return item.attendance;
					},
					formatter: (value, key, item) => {
						return item.attendance;
					},
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		setButtons() {
			this.buttonItems[0].children = [
				{
					index: 0,
					title: 'Add Attendees',
				},
				{
					index: 1,
					title: 'Remove Attendees',
				},
				{
					index: -1,
					title: '',
					isDivider: true,
				},
				{
					index: 3,
					title: 'Send Payment Link',
				},
			];
		},
		getEvent() {
			const database = getOrganizationDatabase(this.organization);
			const eventRef = ref(database, `calendarEvents/${this.eventId}`);

			get(eventRef).then((snapshot) => {
				if (snapshot.exists()) {
					const event = snapshot.val();
					if (this.eventStart != null && event.reoccurrence) {
						let dates = this.$helpers.getReoccurrenceDates(
							event.eventStart,
							event.eventEnd,
							event.reoccurrence
						);
						let found = dates.find((e) => e.getTime() == this.eventStart);
						if (found) {
							var data = event;
							data.eventStart = found.getTime();
							this.getReoccurrenceInfo(data, (eventData) => {
								this.eventData = eventData;
								this.getEventAttendees();
							});
						} else {
							console.log(`Moses We Got No Event!`);
						}
					} else {
						var data = event;
						var attendees = [];
						if (data.attending) {
							Object.entries(data.attending).forEach((info) => {
								attendees.push({
									userUid: info[0],
									info: info,
								});
							});
						}

						if (data.attendance) {
							Object.entries(data.attendance).forEach((info) => {
								var foundIndex = attendees.findIndex(
									(e) => e.userUid == info[0]
								);
								if (foundIndex) {
									attendees[foundIndex].attendance = info[1];
								}
							});
						}

						data.attending = attendees;
						this.eventData = data;
						this.getEventAttendees();
					}
				} else {
					console.log(`Moses We Got No Event!`);
				}
			});
		},
		getReoccurrenceInfo(data, callback) {
			var checkDate = this.getReoccurrenceDBDate(data);
			var attendees = [];
			if (data.attending) {
				if (data.attending[`${checkDate.getTime()}`]) {
					Object.entries(data.attending[`${checkDate.getTime()}`]).forEach(
						(info) => {
							attendees.push({
								userUid: info[0],
								info: info,
							});
						}
					);
				}
			}

			if (data.attendance) {
				if (data.attendance[`${checkDate.getTime()}`]) {
					Object.entries(data.attendance[`${checkDate.getTime()}`]).forEach(
						(info) => {
							var foundIndex = attendees.findIndex((e) => e.userUid == info[0]);
							if (foundIndex != null) {
								attendees[foundIndex].attendance = info[1];
							}
						}
					);
				}
			}

			data.attending = attendees;

			if (data.reoccurrenceEvents) {
				if (data.reoccurrenceEvents[`${checkDate.getTime()}`]) {
					const reoccurenceOptions =
						data.reoccurrenceEvents[`${checkDate.getTime()}`];

					if (reoccurenceOptions.eventLeader != null) {
						data.eventLeader = reoccurenceOptions.eventLeader;
					}

					if (reoccurenceOptions.isCancelled) {
						data.isCancelled = reoccurenceOptions.isCancelled;
					}

					if (reoccurenceOptions.eventPriceQuantity != null) {
						data.eventPriceQuantity = reoccurenceOptions.eventPriceQuantity;
					}
				}
			}

			callback(data);
		},
		getEventAttendees() {
			Object.values(this.eventData.attending).forEach((entry) => {
				const uid = entry.userUid;
				this.getUserInfo(uid);
			});
		},
		getUserInfo(uid) {
			const database = getOrganizationDatabase(this.organization);
			const userRef = ref(database, `users/${uid}`);
			get(userRef).then((snapshot) => {
				if (snapshot.exists()) {
					var userData = snapshot.val();
					if (!this.eventAttendees.find((e) => e.uid == userData.uid)) {
						var userEventData = this.eventData.attending.find(
							(e) => e.userUid == userData.uid
						);

						var userAttendance = userEventData.attendance;
						var quantity = userEventData.info[1];

						if (userAttendance == null) {
							userAttendance = false;
						}

						if (quantity == null) {
							quantity = 1;
						}

						userData.attendance = userAttendance;
						userData.quantity = quantity;

						this.eventAttendees.push(userData);
					}
				}
			});
		},
		headerButtonClicked() {
			this.updateAttendance();
		},
		updateAttendance() {
			const database = getOrganizationDatabase(this.organization);
			var databaseRef = null;

			if (this.eventData.reoccurrence) {
				databaseRef = ref(
					database,
					`calendarEvents/${
						this.eventData.eventId
					}/attendance/${this.getReoccurrenceDBDate(this.eventData).getTime()}`
				);
			} else {
				databaseRef = ref(
					database,
					`calendarEvents/${this.eventData.eventId}/attendance`
				);
			}

			var updateInfo = {};

			Object.values(this.updatedEventAttendees).forEach((value) => {
				updateInfo[value.uid] = value.attendance;
			});

			update(databaseRef, updateInfo)
				.then(() => {
					this.showNotification({
						type: 'success',
						message: 'Attendance Updated.',
					});
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: `${error.message}`,
					});
				});
		},
		childClick(childClicked) {
			this.selectedId = childClicked.index;
		},
		onSwitchChange(item) {
			const newValue = item.value;
			const userUid = item.data.uid;

			var previousValue = this.eventAttendees.find(
				(e) => e.uid == userUid
			).attendance;

			if (previousValue != newValue) {
				if (!this.updatedEventAttendees.find((e) => e.uid == userUid)) {
					this.updatedEventAttendees.push({
						uid: userUid,
						attendance: newValue,
					});
				}
			} else if (previousValue == null) {
				this.updatedEventAttendees = this.updatedEventAttendees.filter(
					(e) => e.uid != userUid
				);
			} else {
				this.updatedEventAttendees = this.updatedEventAttendees.filter(
					(e) => e.uid != userUid
				);
			}

			this.isUpdating = this.updatedEventAttendees.length != 0;
		},
		viewClicked(item) {
			this.$router.push({
				name: DashboardChildren.memberProfile.name,
				query: {
					uid: item.uid,
				},
			});
		},
		getReoccurrenceDBDate(data) {
			var reoccurrenceDate = new Date(data.eventStart); //this.reoccurrenceDate;
			reoccurrenceDate.setHours(0, 0, 0, 0);
			return reoccurrenceDate;
		},
		navigateToSchedule() {
			this.$router.replace({
				name: DashboardChildren.schedule.view,
			});
		},
	},
	mounted() {
		if (!this.eventId) {
			this.navigateToSchedule();
		} else {
			this.getEvent();
			this.setButtons();
		}
	},
};
</script>
