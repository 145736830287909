<template>
	<div>
		<Modal
			:show="showModal"
			@close="closeModal"
			body-classes="m-0 pt-0 pb-0"
			:hideFooter="true"
			footer-classes="pt-0"
			header-classes="pb-1"
		>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
			</template>
			<b-overlay :show="isLoading" rounded="sm">
				<b-row class="mt-2">
					<h5 style="flex: auto; text-align: center">
						{{ headerDescription }}
					</h5>
					<h6
						v-if="model.showErrorMessage"
						style="color: red; flex: auto; text-align: center"
					>
						{{ model.errorMessage }}
					</h6>
				</b-row>
				<form style="flex-grow: 1">
					<base-input>
						<textarea
							placeholder="Enter your message..."
							name="message"
							v-model="model.message"
							class="form-control"
							id="notification"
							rows="9"
							@input="onInput"
						/>
						<b-row class="mr-0 mt-1">
							<div style="flex-grow: 1"></div>
							<h6 class="">
								{{ model.messageCharCount }}/{{ model.messageCharMaxCount }}
							</h6>
						</b-row>
						<b-row class="mr-0">
							<div style="flex-grow: 1"></div>
							<base-button
								type="primary"
								:loading="isLoading"
								:shake="shake"
								@click="submit"
								>Send</base-button
							>
						</b-row>
					</base-input>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import Modal from '../../components/Modals/Modal.vue';
import Card from '../../components/Cards/Card.vue';

export default {
	props: {
		headerTitle: {
			type: String,
			default: 'New Notification',
		},
		headerDescription: {
			type: String,
			default: 'Send your message as a notification to all users.',
		},
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Modal,
		Card,
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			model: {
				message: '',
				messageCharCount: 0,
				messageCharMinCount: 10,
				messageCharMaxCount: 500,
				showErrorMessage: false,
				errorMessage: 'Please enter a valid notification message.',
			},
		};
	},
	methods: {
		closeModal() {
			if (!this.isLoading) {
				this.$emit('update:show', false);
				this.$emit('close');
			}
		},
		onInput() {
			this.model.messageCharCount = this.model.message.length;
			if (this.model.messageCharCount > 0) {
				this.model.errorMessage == null;
				this.model.showErrorMessage = false;
			} else {
				this.model.showErrorMessage = true;
			}
			if (this.model.messageCharCount >= this.model.messageCharMaxCount) {
				this.model.message = this.model.message.substring(
					0,
					this.model.messageCharMaxCount
				);
				this.model.messageCharCount = this.model.message.length;
			}
		},
		submit() {
			this.shake = false;
			if (this.model.messageCharCount < this.model.messageCharMinCount) {
				this.model.showErrorMessage = true;
				this.shake = true;
				return;
			} else {
				this.isLoading = true;

				this.$emit('sendNotification', this.model.message);
			}
		},
	},
};
</script>
