<template>
	<div>
		<Modal
			:show="showModal"
			@close="closeModal"
			:hideFooter="true"
			size="lg"
			body-classes="pt-0 pb-0"
			footer-classes="pt-0"
			header-classes="pb-1"
		>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
			</template>
			<b-overlay :show="isLoading" rounded="sm">
				<div class="pt-2">
					<h5 style="flex: auto; text-align: center">
						Upload a new calendar item to the calendar.
					</h5>
					<h6
						v-if="calendarItem.errorMessage"
						style="color: red; flex: auto; text-align: center"
					>
						{{ calendarItem.errorMessage }}
					</h6>
				</div>

				<form>
					<base-input
						label="Calendar Item Title"
						id="title"
						placeholder="Calendar Item Title"
						required
						v-model="calendarItem.title"
					>
					</base-input>
					<base-input label="Calendar Item Description">
						<textarea
							placeholder="Enter your description..."
							v-model="calendarItem.description"
							class="form-control"
							id="description"
							rows="4"
							required
						/>
					</base-input>
					<base-input
						required
						label="Calendar Item Start Time"
						type="datetime-local"
						v-model="calendarItem.startTime"
						id="start time"
					/>
					<base-input
						required
						label="Calendar Item End Time"
						type="datetime-local"
						v-model="calendarItem.endTime"
						id="end time"
					/>
					<b-row class="mt-2">
						<b-col cols="auto" class="mr-auto">
							<h5>Select Calendar Color Tag</h5>
						</b-col>
						<b-col cols="auto">
							<Verte
								:enableAlpha="false"
								model="hex"
								menuPosition="center"
								v-model="calendarItem.color"
							/>
						</b-col>
					</b-row>

					<b-row class="mt-2">
						<b-col cols="auto" class="mr-auto">
							<h5>Calendar Item is Reoccuring</h5>
						</b-col>
						<b-col cols="auto">
							<base-switch
								v-model="calendarItem.reoccuringSwitch"
							></base-switch>
						</b-col>
					</b-row>
					<base-input
						v-if="calendarItem.reoccuringSwitch"
						:required="calendarItem.reoccuringSwitch"
						placeholder="Select Reoccuring Option"
					>
						<select
							class="form-control"
							id="reoccuring"
							v-model="calendarItem.reoccuring"
						>
							<option
								v-for="value in reoccuringOptions"
								:key="value.value"
								:value="value.value"
							>
								{{ value.name }}
							</option>
						</select>
					</base-input>
					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<base-button
							type="primary"
							@click="submit"
							:loading="isLoading"
							:shake="shake"
							>Add Item</base-button
						>
					</b-row>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import { push, ref, set } from '@firebase/database';
import Verte from 'verte';
import 'verte/dist/verte.css';
import { mapGetters } from 'vuex';
import Modal from '../../components/Modals/Modal.vue';
import reoccuringOptions from '../../data/reoccuringOptions';
import { getOrganizationDatabase } from '../../resources/organizationFirebase';

export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	props: {
		headerTitle: {
			type: String,
			default: 'New Calendar Item',
		},
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Modal,
		Verte,
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			reoccuringOptions,
			calendarItem: {
				title: '',
				description: '',
				startTime: null,
				endTime: null,
				color: '#153f7c',
				reoccuringSwitch: false,
				reoccuring: '',
				errorMessage: null,
			},
		};
	},
	methods: {
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		submit() {
			this.shake = false;
			const calendarItemTitle = this.calendarItem.title;
			const calendarItemDescription = this.calendarItem.description;
			const calendarItemStart = this.calendarItem.startTime;
			const calendarItemEnd = this.calendarItem.endTime;
			const calendarItemColor = this.calendarItem.color;
			const calendarItemReoccuring = this.calendarItem.reoccuring;

			if (
				this.isEmpty(calendarItemTitle) ||
				this.isEmpty(calendarItemDescription)
			) {
				this.calendarItem.errorMessage =
					'Please complete all the necessary fields.';
				this.shake = true;
				return;
			} else if (calendarItemStart == null || calendarItemEnd == null) {
				this.calendarItem.errorMessage = 'Please select a start and end date.';
				this.shake = true;
				return;
			} else if (
				new Date(calendarItemStart).getTime() >
				new Date(calendarItemEnd).getTime()
			) {
				this.calendarItem.errorMessage =
					'End time must be after the start date.';
				this.shake = true;
				return;
			} else if (
				this.calendarItem.reoccuringSwitch &&
				this.isEmpty(calendarItemReoccuring)
			) {
				this.calendarItem.errorMessage = 'Please select a reoccuring type.';
				this.shake = true;
				return;
			} else {
				this.calendarItem.errorMessage = null;
				this.shake = false;

				this.loading = true;

				const database = getOrganizationDatabase(this.organization);
				const databaseRef = ref(database, 'calendarItems');
				const newDatabaseRef = push(databaseRef);

				const timestamp = new Date().getTime();

				var calendarItemInfo = {
					calendarItemId: newDatabaseRef.key,
					timestamp: timestamp,
					calendarItemTitle: calendarItemTitle,
					calendarItemDescription: calendarItemDescription,
					calendarItemStart: new Date(calendarItemStart).getTime(),
					calendarItemEnd: new Date(calendarItemEnd).getTime(),
					calendarColorCode: calendarItemColor,
				};

				if (this.calendarItem.reoccuringSwitch) {
					calendarItemInfo['reoccurrence'] = calendarItemReoccuring;
				}

				set(newDatabaseRef, calendarItemInfo)
					.then(() => {
						this.isLoading = false;
						this.$emit('newCalendarItem', calendarItemInfo);
						this.closeModal();
					})
					.catch((error) => {
						this.isLoading = false;
						this.event.errorMessage = error.message;
					});
			}
		},
		isEmpty(str) {
			return this.$helpers.isBlank(str);
		},
	},
};
</script>
