<template>
	<div>
		<Modal
			:show="showModal"
			@close="closeModal"
			body-classes="m-0 pt-0 pb-0"
			:hideFooter="true"
			footer-classes="pt-0"
			header-classes="pb-1"
			size="lg"
		>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
			</template>

			<b-overlay :show="isLoading" rounded="sm">
				<div class="pt-2">
					<h5 style="flex: auto; text-align: center">
						Upload a new vendor to the app.
					</h5>
					<h6 v-if="errorMessage" style="color: red; flex: auto; text-align: center">
						{{ errorMessage }}
					</h6>
				</div>

				<form ref="form" class="pt-1 pb-1">
					<VueDropZone
						class="p-0 m-0"
						id="logo"
						style="border: 0; z-index: 1"
						v-on:vdropzone-files-added="addedImage"
						:options="dropzoneOptions"
					>
					</VueDropZone>

					<BaseInput
						label="Business Name"
						id="business name"
						placeholder="Business Name"
						required
						v-model="business.businessName"
					/>
					<BaseInput label="Business Bio">
						<textarea
							placeholder="Enter business bio..."
							v-model="business.businessBio"
							maxlength="350"
							class="form-control"
							id="business bio"
							rows="4"
							required
						></textarea>
					</BaseInput>

					<BaseInput label="Account Level" placeholder="Account Level">
						<select
							class="form-control"
							id="business type"
							v-model="business.businessLevel"
						>
							<option
								v-for="level in arrayOfLevels"
								:key="level.value"
								:value="level.value"
							>
								{{ level.name }}
							</option>
						</select>
					</BaseInput>
					<BaseInput
						label="Business Email"
						id="business email"
						placeholder="Business Email"
						v-model="business.businessSocial.email"
					/>
					<BaseInput
						label="Business Website"
						id="business website"
						placeholder="Business Website"
						v-model="business.businessSocial.website"
					/>
					<BaseInput
						label="Business Phone"
						id="business phone"
						placeholder="Business Phone"
						v-model="business.businessSocial.phoneNumber"
					/>
					<b-row>
						<b-col>
							<BaseInput
								label="Facebook"
								id="facebook"
								placeholder="Facebook"
								v-model="business.businessSocial.facebook"
							/>
						</b-col>
						<b-col>
							<BaseInput
								label="Instagram"
								id="instagram"
								placeholder="Instagram"
								v-model="business.businessSocial.instagram"
							/>
						</b-col>
						<b-col>
							<BaseInput
								label="Youtube"
								id="youtube"
								placeholder="Youtube"
								v-model="business.businessSocial.youtube"
							/>
						</b-col>
					</b-row>

					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<BaseButton
							type="primary"
							:loading="isLoading"
							:shake="shake"
							@click="submit"
							>Add Vendor</BaseButton
						>
					</b-row>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import VueDropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Modal from '../../components/Modals/Modal.vue';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import CurrencyInput from '../../components/Inputs/CurrencyInput.vue';
import BaseSwitch from '../../components/BaseSwitch.vue';
import ButtonRadioGroup from '../../components/ButtonRadioGroup.vue';
import reoccuringOptions from '../../data/reoccuringOptions';
import { mapGetters } from 'vuex';
import {
	getOrganizationFirestore,
	getOrganizationStorage,
} from '../../resources/organizationFirebase';
import {
	uploadBytes,
	getDownloadURL,
	ref as firebaseStorageRef,
} from 'firebase/storage';

import BaseButton from '../../components/BaseButton.vue';
import { collection, doc, setDoc } from '@firebase/firestore';

export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	props: {
		headerTitle: {
			type: String,
			default: 'New Product',
		},
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		VueDropZone,
		Modal,
		BaseInput,
		CurrencyInput,
		BaseSwitch,
		ButtonRadioGroup,
		BaseButton,
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			arrayOfNumbers: [],
			arrayOfLevels: [
				{
					value: null,
					name: 'None',
				},
				{
					value: 'bronze',
					name: 'Bronze',
				},
				{
					value: 'silver',
					name: 'Silver',
				},
				{
					value: 'gold',
					name: 'Gold',
				},
			],
			reoccuringOptions,
			dropzoneOptions: {
				url: 'https://httpbin.org/post',
				thumbnailWidth: 100,
				height: 100,
				addRemoveLinks: true,
				acceptedFiles: 'image/*',
				dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
			},
			businessLogo: null,
			business: {
				businessName: null,
				businessBio: null,
				businessLevel: null,
				businessSocial: {
					email: null,
					phoneNumber: null,
					facebook: null,
					instagram: null,
					website: null,
					youtube: null,
				},
			},
			errorMessage: null,
		};
	},
	methods: {
		resetView() {
			this.businessLogo = null;
			this.business.businessName = null;
			this.business.businessBio = null;
			this.business.businessLevel = null;
			this.business.businessSocial.email = null;
			this.business.businessSocial.phoneNumber = null;
			this.business.businessSocial.facebook = null;
			this.business.businessSocial.instagram = null;
			this.business.businessSocial.website = null;
			this.business.businessSocial.youtube = null;
			this.errorMessage = null;
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		addedImage(file) {
			this.businessLogo = file;
		},
		submit() {
			this.shake = false;

			const businessLogo = this.businessLogo;
			const businessName = this.business.businessName;
			const businessBio = this.business.businessBio;
			const businessLevel = this.business.businessLevel;

			if (businessLogo == null) {
				this.errorMessage = 'Please select a product image.';
				this.shake = true;
				return;
			} else if (
				this.$helpers.isBlank(businessName) ||
				this.$helpers.isBlank(businessBio)
			) {
				this.errorMessage = 'Please complete all the necessary fields.';
				this.shake = true;
				return;
			} else {
				this.errorMessage = null;

				const storage = getOrganizationStorage(this.organization);
				const firestore = getOrganizationFirestore(this.organization);
				const businessRef = collection(firestore, 'business');
				const newBusinessRef = doc(businessRef);
				const storageRef = firebaseStorageRef(
					storage,
					`business/${newBusinessRef.id}/logo`
				);

				this.isLoading = true;

				this.uploadImage(storageRef, newBusinessRef);
			}
		},
		uploadImage(storageRef, newBusinessRef) {
			const metadata = {
				contentType: 'image/jpg',
			};

			uploadBytes(storageRef, this.businessLogo[0], metadata)
				.then((result) => {
					const resultRef = result.ref;
					getDownloadURL(resultRef).then((downloadURL) => {
						this.sendBusinessToFirebase(downloadURL, newBusinessRef);
					});
				})
				.catch((error) => {
					//error uploading
					this.isLoading = false;
					this.errorMessage = error.message;
					this.shake = true;
				});
		},
		sendBusinessToFirebase(imageURL, newBusinessRef) {
			const businessId = newBusinessRef.id;
			var businessInfo = this.business;

			for (const [key, value] of Object.entries(businessInfo)) {
				if (value == null) {
					delete businessInfo[key];
				}
			}

			for (const [key, value] of Object.entries(businessInfo.businessSocial)) {
				if (value == null) {
					delete businessInfo.businessSocial[key];
				}
			}

			businessInfo['businessLogo'] = imageURL;
			businessInfo['businessId'] = businessId;
			businessInfo['isActive'] = true;

			setDoc(newBusinessRef, businessInfo)
				.then(() => {
					this.isLoading = false;
					this.$emit('newVendor', businessInfo);
					this.resetView();
					this.closeModal();
				})
				.catch((error) => {
					this.isLoading = false;
					this.errorMessage = error.message;
					this.shake = true;
				});
		},
	},
	mounted() {
		for (let index = 0; index < 101; index++) {
			if (index == 0) {
				this.arrayOfNumbers.push('Unlimited');
			} else {
				this.arrayOfNumbers.push(`${index}`);
			}
		}
	},
};
</script>
