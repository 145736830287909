export default [
	{
		'-N0Bgja1BpYQ4212qJOH': {
			message: 'Awesome! Elena Mangan is going to Int/Adv Hip-Hop With Gerald.',
			messageId: '-N0Bgja1BpYQ4212qJOH',
			timestamp: 1650554239369,
			uid: 'systemUid',
		},
		'-N0BlM_eGzoMuOCIUzZ2': {
			message:
				'Awesome! Jamie Pinsonnault is going to Beginner Hip-Hop With Christina.',
			messageId: '-N0BlM_eGzoMuOCIUzZ2',
			timestamp: 1650555451761,
			uid: 'systemUid',
		},
		'-N0BlNis7lPYbRK0iKqd': {
			message:
				'Awesome! Aicha Diallo is going to MÈNTAL - Personal Dance Development.',
			messageId: '-N0BlNis7lPYbRK0iKqd',
			timestamp: 1650555456446,
			uid: 'systemUid',
		},
		'-N0BmQYqAMlmiicxzRnU': {
			message:
				'Awesome! Jamie Pinsonnault is going to Int/Adv Choreography With Gerald.',
			messageId: '-N0BmQYqAMlmiicxzRnU',
			timestamp: 1650555730172,
			uid: 'systemUid',
		},
		'-N0ClXka-BfVN90mx6_5': {
			message: 'Awesome! Alex is going to Beginner Hip-Hop With Christina.',
			messageId: '-N0ClXka-BfVN90mx6_5',
			timestamp: 1650572274733,
			uid: 'systemUid',
		},
		'-N0CtqgXchfHc0YHVvQl': {
			message:
				'Awesome! Melany Smeriglio is going to Int/Adv Choreography With Gerald.',
			messageId: '-N0CtqgXchfHc0YHVvQl',
			timestamp: 1650574453544,
			uid: 'systemUid',
		},
		'-N0Cx1WAATvHoNbJCUjw': {
			message: 'Awesome! Vu Tran is going to Beginner Hip-Hop With Christina.',
			messageId: '-N0Cx1WAATvHoNbJCUjw',
			timestamp: 1650575288401,
			uid: 'systemUid',
		},
		'-N0D-HwV72yEO8mkGHZb': {
			message:
				'Awesome! Shannon Psaras is going to Beginner Hip-Hop With Christina.',
			messageId: '-N0D-HwV72yEO8mkGHZb',
			timestamp: 1650576142118,
			uid: 'systemUid',
		},
		'-N0D3cgWqAiSTwC4If3z': {
			message:
				'Awesome! Claire Fracasse is going to Beginner Hip-Hop With Christina.',
			messageId: '-N0D3cgWqAiSTwC4If3z',
			timestamp: 1650577279783,
			uid: 'systemUid',
		},
		'-N0D4-cjhqLqDtG-eFSe': {
			message:
				'Awesome! Edele Theodore is going to Beginner Hip-Hop With Christina.',
			messageId: '-N0D4-cjhqLqDtG-eFSe',
			timestamp: 1650577377845,
			uid: 'systemUid',
		},
		'-N0D812dRTFvpgeg8MKw': {
			message:
				'Awesome! Allison Sprafke is going to Beginner Hip-Hop With Christina.',
			messageId: '-N0D812dRTFvpgeg8MKw',
			timestamp: 1650578432240,
			uid: 'systemUid',
		},
		'-N0D81IWOZXv1lISDISm': {
			message:
				'Awesome! Elizabeth Stokes is going to Beginner Hip-Hop With Christina.',
			messageId: '-N0D81IWOZXv1lISDISm',
			timestamp: 1650578433256,
			uid: 'systemUid',
		},
		'-N0D8YoO3rb31DpuqLKV': {
			message:
				'Awesome! Brandon Khiry is going to Beginner Hip-Hop With Christina.',
			messageId: '-N0D8YoO3rb31DpuqLKV',
			timestamp: 1650578570529,
			uid: 'systemUid',
		},
		'-N0DDLDk3RcALuHHV-Wj': {
			message:
				'Awesome! Kelsey Connolly is going to Beginner Hip-Hop With Christina.',
			messageId: '-N0DDLDk3RcALuHHV-Wj',
			timestamp: 1650579825589,
			uid: 'systemUid',
		},
		'-N0DI1avJzUJMFNcu7vT': {
			message:
				'Awesome! Ana Struski is going to Int/Adv Choreography With Gerald.',
			messageId: '-N0DI1avJzUJMFNcu7vT',
			timestamp: 1650581055938,
			uid: 'systemUid',
		},
		'-N0E8mllp-YQOjFS2N82': {
			message: 'Yay! info@dominionteam.com has created a new account.',
			messageId: '-N0E8mllp-YQOjFS2N82',
			timestamp: 1650595409010,
			uid: 'systemUid',
		},
		'-N0EQ4yBo-oi6RyCLd9u': {
			message: 'Yay! info@alliancedancenwtwork.com has created a new account.',
			messageId: '-N0EQ4yBo-oi6RyCLd9u',
			timestamp: 1650599944076,
			uid: 'systemUid',
		},
		'-N0GzkhbTIBzH5jqMrMD': {
			message:
				'Awesome! Kerrin Massey is going to Intermediate Storytelling With Charliece.',
			messageId: '-N0GzkhbTIBzH5jqMrMD',
			timestamp: 1650643110767,
			uid: 'systemUid',
		},
		'-N0HiLyysOP970nBlqa8': {
			message: 'Yay! apkeo88@gmail.com has created a new account.',
			messageId: '-N0HiLyysOP970nBlqa8',
			timestamp: 1650655326142,
			uid: 'systemUid',
		},
		'-N0Higbv5suL-r62-5gX': {
			message:
				'Awesome! Alexis Pissey Keo Keo is going to Int/Adv Heels With Fabiana.',
			messageId: '-N0Higbv5suL-r62-5gX',
			timestamp: 1650655414785,
			uid: 'systemUid',
		},
		'-N0Hu6MMiW_5_EA-0aDz': {
			message: 'Awesome! Joohee Choi is going to Int/Adv Hip-Hop With Eli.',
			messageId: '-N0Hu6MMiW_5_EA-0aDz',
			timestamp: 1650658407902,
			uid: 'systemUid',
		},
		'-N0HxLTEYyUr-Kuv4DSW': {
			message: 'Yay! mmontano08@yahoo.com has created a new account.',
			messageId: '-N0HxLTEYyUr-Kuv4DSW',
			timestamp: 1650659256207,
			uid: 'systemUid',
		},
		'-N0Hx_Q6-p8pijXC5hKR': {
			message:
				'Awesome! Michelle Montano is going to Int/Adv Hip-Hop With Eli.',
			messageId: '-N0Hx_Q6-p8pijXC5hKR',
			timestamp: 1650659317453,
			uid: 'systemUid',
		},
		'-N0I57WutV_TLLtazLyn': {
			message: 'Awesome! Erika Gavino is going to Int/Adv Hip-Hop With Eli.',
			messageId: '-N0I57WutV_TLLtazLyn',
			timestamp: 1650661558402,
			uid: 'systemUid',
		},
		'-N0I5CP2xLnoQ0PuV9NR': {
			message: 'Awesome! Erika Gavino is going to Int/Adv Heels With Fabiana.',
			messageId: '-N0I5CP2xLnoQ0PuV9NR',
			timestamp: 1650661578376,
			uid: 'systemUid',
		},
		'-N0IJWl6XP9Gteo4nD7C': {
			message:
				'Awesome! Cheyenne Lueken is going to Int/Adv Heels With Fabiana.',
			messageId: '-N0IJWl6XP9Gteo4nD7C',
			timestamp: 1650665331789,
			uid: 'systemUid',
		},
		'-N0IMs6behFQoEZhzy4H': {
			message:
				'Awesome! Melany Smeriglio is going to Int/Adv Heels With Fabiana.',
			messageId: '-N0IMs6behFQoEZhzy4H',
			timestamp: 1650666209772,
			uid: 'systemUid',
		},
		'-N0IaJzjiw6D_PBke1bG': {
			message: 'Awesome! Tia Cruz is going to Int/Adv Heels With Fabiana.',
			messageId: '-N0IaJzjiw6D_PBke1bG',
			timestamp: 1650669998070,
			uid: 'systemUid',
		},
		'-N0IgWEmRaJ-gKHWw2rz': {
			message:
				'Awesome! Chantal Edwards Matthews is going to Int/Adv Heels With Fabiana.',
			messageId: '-N0IgWEmRaJ-gKHWw2rz',
			timestamp: 1650671621111,
			uid: 'systemUid',
		},
		'-N0JZyXuRaKYPymQKkrq': {
			message:
				'Awesome! Website User is going to Int/Adv Street Jazz With Michelle.',
			messageId: '-N0JZyXuRaKYPymQKkrq',
			timestamp: 1650686421184,
			uid: 'systemUid',
		},
		'-N0Js-uObYs556wPLLal': {
			message:
				'Awesome! Mya Messino is going to Intermediate Storytelling With Charliece.',
			messageId: '-N0Js-uObYs556wPLLal',
			timestamp: 1650691411615,
			uid: 'systemUid',
		},
		'-N0L4xrgT2ApeP6o5PN8': {
			message:
				'Awesome! Elisha Hillman is going to YOUTH - Dance Team Tryouts.',
			messageId: '-N0L4xrgT2ApeP6o5PN8',
			timestamp: 1650711846386,
			uid: 'systemUid',
		},
		'-N0L599cgyKrYsuqNCAR': {
			message:
				'Awesome! Elisha Hillman is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0L599cgyKrYsuqNCAR',
			timestamp: 1650711896750,
			uid: 'systemUid',
		},
		'-N0L_8H-U-8zknRMJHyf': {
			message:
				'Awesome! Elisha Hillman is going to Int/Adv Street Jazz With Michelle.',
			messageId: '-N0L_8H-U-8zknRMJHyf',
			timestamp: 1650720019590,
			uid: 'systemUid',
		},
		'-N0M0AUF6HpSNXNkc5OY': {
			message: 'Yay! bpurug@gmail.com has created a new account.',
			messageId: '-N0M0AUF6HpSNXNkc5OY',
			timestamp: 1650727368657,
			uid: 'systemUid',
		},
		'-N0M6vpgYzxF3SmYC3W_': {
			message:
				'Awesome! Casey Tye is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0M6vpgYzxF3SmYC3W_',
			timestamp: 1650729139570,
			uid: 'systemUid',
		},
		'-N0MK9BgzGK-G5145dhy': {
			message:
				'Awesome! Mya Messino is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0MK9BgzGK-G5145dhy',
			timestamp: 1650732606258,
			uid: 'systemUid',
		},
		'-N0MKUwut8CR-9fZJCoS': {
			message: 'Awesome! Kailah J is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0MKUwut8CR-9fZJCoS',
			timestamp: 1650732695359,
			uid: 'systemUid',
		},
		'-N0MflgVGtxtipaDKLaf': {
			message:
				'Awesome! Anjenie Hernandez  is going to Int/Adv Street Jazz With Michelle.',
			messageId: '-N0MflgVGtxtipaDKLaf',
			timestamp: 1650738535207,
			uid: 'systemUid',
		},
		'-N0MfohJuTkvkemG0z1K': {
			message:
				'Awesome! Anjenie Hernandez  is going to Intermediate Storytelling With Charliece.',
			messageId: '-N0MfohJuTkvkemG0z1K',
			timestamp: 1650738547546,
			uid: 'systemUid',
		},
		'-N0MnsPRskZFn3HxYbWT': {
			message:
				'Awesome! Tia Cruz is going to Int/Adv Street Jazz With Michelle.',
			messageId: '-N0MnsPRskZFn3HxYbWT',
			timestamp: 1650740659873,
			uid: 'systemUid',
		},
		'-N0Mnw4IbaiG1F8sifjN': {
			message:
				'Awesome! Tia Cruz is going to Intermediate Storytelling With Charliece.',
			messageId: '-N0Mnw4IbaiG1F8sifjN',
			timestamp: 1650740674904,
			uid: 'systemUid',
		},
		'-N0MsZwTufP_4_cNLQBG': {
			message:
				'Awesome! Alejandro Cruz is going to Intermediate Storytelling With Charliece.',
			messageId: '-N0MsZwTufP_4_cNLQBG',
			timestamp: 1650741890851,
			uid: 'systemUid',
		},
		'-N0MtUZJrHX9OAukbWFh': {
			message:
				'Awesome! Maddie Moore  is going to Int/Adv Street Jazz With Michelle.',
			messageId: '-N0MtUZJrHX9OAukbWFh',
			timestamp: 1650742130969,
			uid: 'systemUid',
		},
		'-N0Mt_8MlEOokvd7u7_X': {
			message:
				'Awesome! Jordan Fagon is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0Mt_8MlEOokvd7u7_X',
			timestamp: 1650742153820,
			uid: 'systemUid',
		},
		'-N0MwTU3n32yYTLabhFw': {
			message:
				'Awesome! Meghan Diamond is going to Int/Adv Street Jazz With Michelle.',
			messageId: '-N0MwTU3n32yYTLabhFw',
			timestamp: 1650742912969,
			uid: 'systemUid',
		},
		'-N0N1whUVj__cLZUcEtz': {
			message: 'Yay! willasantiago86@gmail.com has created a new account.',
			messageId: '-N0N1whUVj__cLZUcEtz',
			timestamp: 1650744609631,
			uid: 'systemUid',
		},
		'-N0N4iWzwRRH1G98jAE7': {
			message:
				'Awesome! Sylvia Sylvia Wang is going to Int/Adv Street Jazz With Michelle.',
			messageId: '-N0N4iWzwRRH1G98jAE7',
			timestamp: 1650745337993,
			uid: 'systemUid',
		},
		'-N0N8dN1NlBRVbpALxKp': {
			message:
				'Awesome! Loren Milledge is going to Int/Adv Street Jazz With Michelle.',
			messageId: '-N0N8dN1NlBRVbpALxKp',
			timestamp: 1650746365448,
			uid: 'systemUid',
		},
		'-N0N9fbx2YdDrqMPf7pO': {
			message:
				'Awesome! Chantal Edwards Matthews is going to Int/Adv Street Jazz With Michelle.',
			messageId: '-N0N9fbx2YdDrqMPf7pO',
			timestamp: 1650746636803,
			uid: 'systemUid',
		},
		'-N0NDyebgSd0BUdtvgSs': {
			message:
				'Awesome! Charliece Salters is going to Int/Adv Street Jazz With Michelle.',
			messageId: '-N0NDyebgSd0BUdtvgSs',
			timestamp: 1650747763372,
			uid: 'systemUid',
		},
		'-N0NFktmIgJ5r9qH8o_X': {
			message: 'Awesome! Fab is going to Int/Adv Street Jazz With Michelle.',
			messageId: '-N0NFktmIgJ5r9qH8o_X',
			timestamp: 1650748231287,
			uid: 'systemUid',
		},
		'-N0NY24uP5QzK6ghRzHY': {
			message:
				'Awesome! Kayla Hansen is going to Intro To Hip-Hop With Brandon.',
			messageId: '-N0NY24uP5QzK6ghRzHY',
			timestamp: 1650753024385,
			uid: 'systemUid',
		},
		'-N0Ncvg8Hns5FbgBmC3W': {
			message:
				'Awesome! Loren Milledge is going to Intermediate Storytelling With Charliece.',
			messageId: '-N0Ncvg8Hns5FbgBmC3W',
			timestamp: 1650754566927,
			uid: 'systemUid',
		},
		'-N0Ne1cijLj4fHpE-16P': {
			message:
				'Awesome! S I N Q U E  is going to Intermediate Storytelling With Charliece.',
			messageId: '-N0Ne1cijLj4fHpE-16P',
			timestamp: 1650754857523,
			uid: 'systemUid',
		},
		'-N0PJyVa7A6uyCVSIWJS': {
			message: 'Yay! torres.sa25@gmail.com has created a new account.',
			messageId: '-N0PJyVa7A6uyCVSIWJS',
			timestamp: 1650782890023,
			uid: 'systemUid',
		},
		'-N0Q0Nzh6L8QuOrxM-ZI': {
			message: 'Yay! Tyler M has renewed Bronze Subscription (Drop-Ins).',
			messageId: '-N0Q0Nzh6L8QuOrxM-ZI',
			timestamp: 1650794532850,
			uid: 'systemUid',
		},
		'-N0QMD7tQ0D-IUgfquWC': {
			message:
				'Awesome! Kleber Gordillo is going to Intro To Hip-Hop With Brandon.',
			messageId: '-N0QMD7tQ0D-IUgfquWC',
			timestamp: 1650800255550,
			uid: 'systemUid',
		},
		'-N0QfXS2dV_jA7FPPSbd': {
			message:
				'Awesome! Website User is going to Power Yoga (All-Levels) With Stefanie.',
			messageId: '-N0QfXS2dV_jA7FPPSbd',
			timestamp: 1650805581641,
			uid: 'systemUid',
		},
		'-N0QwgSrTCcM0mQrcj2-': {
			message:
				'Awesome! Carly Lovelace is going to Power Yoga (All-Levels) With Stefanie.',
			messageId: '-N0QwgSrTCcM0mQrcj2-',
			timestamp: 1650810079101,
			uid: 'systemUid',
		},
		'-N0T12q9KchdKlijii3T': {
			message:
				'Awesome! Jane Torrence is going to Advanced Hip-Hop With Daisy.',
			messageId: '-N0T12q9KchdKlijii3T',
			timestamp: 1650845040017,
			uid: 'systemUid',
		},
		'-N0TZqyqzcPKzqUSZl5n': {
			message: 'Yay! princess.shabazz27@gmail.com has created a new account.',
			messageId: '-N0TZqyqzcPKzqUSZl5n',
			timestamp: 1650854162358,
			uid: 'systemUid',
		},
		'-N0XI5Jlwkk8gMbPaDDa': {
			message:
				'Awesome! Website User is going to Intro To Hip-Hop With Brandon.',
			messageId: '-N0XI5Jlwkk8gMbPaDDa',
			timestamp: 1650916615479,
			uid: 'systemUid',
		},
		'-N0X_33XCMNaQfyJbRbX': {
			message:
				'Awesome! Kari Edelson is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0X_33XCMNaQfyJbRbX',
			timestamp: 1650921324841,
			uid: 'systemUid',
		},
		'-N0XdC5dDhlVMTwC7GrS': {
			message:
				'Awesome! Errick Johnson is going to All-Styles Freestyle 101 with Gerald.',
			messageId: '-N0XdC5dDhlVMTwC7GrS',
			timestamp: 1650922410431,
			uid: 'systemUid',
		},
		'-N0Yd_Pb-J9eA2oBcSkM': {
			message: 'Yay! kladance31@gmail.com has created a new account.',
			messageId: '-N0Yd_Pb-J9eA2oBcSkM',
			timestamp: 1650939287208,
			uid: 'systemUid',
		},
		'-N0YtoZomn7vdb-wfEGQ': {
			message:
				'Awesome! Odalys Morales is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0YtoZomn7vdb-wfEGQ',
			timestamp: 1650943543610,
			uid: 'systemUid',
		},
		'-N0YtpXMs6xoQSk7o_9j': {
			message:
				'Awesome! Janely Lopez is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0YtpXMs6xoQSk7o_9j',
			timestamp: 1650943547549,
			uid: 'systemUid',
		},
		'-N0_p1UUJnZpTXkSIxPE': {
			message: 'Yay! martin3778@comcast.net has created a new account.',
			messageId: '-N0_p1UUJnZpTXkSIxPE',
			timestamp: 1650975844320,
			uid: 'systemUid',
		},
		'-N0_qHqAL2DGmVAMXeqr': {
			message:
				'Awesome! Izabela Martin is going to Intro To Hip-Hop With Brandon.',
			messageId: '-N0_qHqAL2DGmVAMXeqr',
			timestamp: 1650976173457,
			uid: 'systemUid',
		},
		'-N0apb2bRo7-dduCZr4T': {
			message:
				'Awesome! Anjenie Hernandez  is going to Int/Adv Choreography with Jaspar.',
			messageId: '-N0apb2bRo7-dduCZr4T',
			timestamp: 1650992771310,
			uid: 'systemUid',
		},
		'-N0apgMpNZgZx4YZ4tt2': {
			message:
				'Awesome! Anjenie Hernandez  is going to Advanced Hip-Hop With Daisy.',
			messageId: '-N0apgMpNZgZx4YZ4tt2',
			timestamp: 1650992793083,
			uid: 'systemUid',
		},
		'-N0axf4tPWnfIFZrEBR_': {
			message:
				'Awesome! Mathew Sebastian is going to Intro To Hip-Hop With Brandon.',
			messageId: '-N0axf4tPWnfIFZrEBR_',
			timestamp: 1650994884994,
			uid: 'systemUid',
		},
		'-N0bGPO2JhxwEs5a4giT': {
			message:
				'Awesome! Demoya Kelly is going to Intro To Hip-Hop With Brandon.',
			messageId: '-N0bGPO2JhxwEs5a4giT',
			timestamp: 1651000059466,
			uid: 'systemUid',
		},
		'-N0bgUcx82Jrt8TetVn2': {
			message:
				'Awesome! Edele Theodore is going to Intro To Hip-Hop With Brandon.',
			messageId: '-N0bgUcx82Jrt8TetVn2',
			timestamp: 1651007158851,
			uid: 'systemUid',
		},
		'-N0boAXFBvP_h9hJQmbR': {
			message:
				'Awesome! Princess Brown is going to Intro To Hip-Hop With Brandon.',
			messageId: '-N0boAXFBvP_h9hJQmbR',
			timestamp: 1651009173653,
			uid: 'systemUid',
		},
		'-N0brXXBdaT6RQtDNLZJ': {
			message:
				'Awesome! Dallas Brown is going to Intro To Hip-Hop With Brandon.',
			messageId: '-N0brXXBdaT6RQtDNLZJ',
			timestamp: 1651010054289,
			uid: 'systemUid',
		},
		'-N0cca8oDgMBmy55pkIt': {
			message:
				'Awesome! Daniella Russell is going to Int/Adv Choreography with Jaspar.',
			messageId: '-N0cca8oDgMBmy55pkIt',
			timestamp: 1651022914170,
			uid: 'systemUid',
		},
		'-N0cchWmGt-mCyXepQ0P': {
			message:
				'Awesome! Daniella Russell is going to Advanced Hip-Hop With Daisy.',
			messageId: '-N0cchWmGt-mCyXepQ0P',
			timestamp: 1651022944376,
			uid: 'systemUid',
		},
		'-N0cye2FkzMnhmvqzUts': {
			message:
				'Awesome! Melany Smeriglio is going to Advanced Hip-Hop With Daisy.',
			messageId: '-N0cye2FkzMnhmvqzUts',
			timestamp: 1651028697301,
			uid: 'systemUid',
		},
		'-N0d-hSqQPokowecp0iz': {
			message:
				'Awesome! Cameron Marro is going to Beginner Hip-Hop With Christina.',
			messageId: '-N0d-hSqQPokowecp0iz',
			timestamp: 1651029235579,
			uid: 'systemUid',
		},
		'-N0dSwKQeZb1DgHqriP0': {
			message: 'Awesome! Taylor Burns is going to Advanced Hip-Hop With Daisy.',
			messageId: '-N0dSwKQeZb1DgHqriP0',
			timestamp: 1651036898657,
			uid: 'systemUid',
		},
		'-N0fMXQfc2a1L9DdFIrf': {
			message:
				'Awesome! Alicia Turney is going to Beg/Int Choreography With Gerald.',
			messageId: '-N0fMXQfc2a1L9DdFIrf',
			timestamp: 1651068774131,
			uid: 'systemUid',
		},
		'-N0fbACRgZdfZf2P5e8Y': {
			message:
				'Awesome! Kleber Gordillo is going to Beg/Int Choreography With Gerald.',
			messageId: '-N0fbACRgZdfZf2P5e8Y',
			timestamp: 1651072873315,
			uid: 'systemUid',
		},
		'-N0fhO1JLiI4ku2DnLwH': {
			message:
				'Awesome! S I N Q U E  is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0fhO1JLiI4ku2DnLwH',
			timestamp: 1651074502809,
			uid: 'systemUid',
		},
		'-N0fhO1UzHYZK2sSw7_J': {
			message:
				'Awesome! max decarlo is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0fhO1UzHYZK2sSw7_J',
			timestamp: 1651074502821,
			uid: 'systemUid',
		},
		'-N0g5LcXwOEBeGhyFhLH': {
			message:
				'Awesome! Diego Pichay is going to Int/Adv Choreography with Jaspar.',
			messageId: '-N0g5LcXwOEBeGhyFhLH',
			timestamp: 1651081046569,
			uid: 'systemUid',
		},
		'-N0g5eD_VfpPpf6I6zsJ': {
			message:
				'Awesome! Brianna Navedo is going to Int/Adv Choreography with Jaspar.',
			messageId: '-N0g5eD_VfpPpf6I6zsJ',
			timestamp: 1651081126827,
			uid: 'systemUid',
		},
		'-N0gExczZsH1ivVU6y7Q': {
			message:
				'Awesome! Carlie O’Sullivan is going to Beginner Street Tap with Justina.',
			messageId: '-N0gExczZsH1ivVU6y7Q',
			timestamp: 1651083565639,
			uid: 'systemUid',
		},
		'-N0gcOeiHTqEhbrHz1ZA': {
			message:
				'Awesome! Elizabeth Finni is going to Int/Adv Choreography with Jaspar.',
			messageId: '-N0gcOeiHTqEhbrHz1ZA',
			timestamp: 1651089971892,
			uid: 'systemUid',
		},
		'-N0gelyigm1MFoUtxmQM': {
			message:
				'Awesome! Raymon Padro is going to Int/Adv Choreography with Jaspar.',
			messageId: '-N0gelyigm1MFoUtxmQM',
			timestamp: 1651090595764,
			uid: 'systemUid',
		},
		'-N0gm2PHpPPq71NoLZjS': {
			message: 'Awesome! Siana Garcia is going to Advanced Hip-Hop With Daisy.',
			messageId: '-N0gm2PHpPPq71NoLZjS',
			timestamp: 1651092502167,
			uid: 'systemUid',
		},
		'-N0hEJ2I0FBy_w7_hdqC': {
			message:
				'Awesome! Amia Serio is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0hEJ2I0FBy_w7_hdqC',
			timestamp: 1651100172507,
			uid: 'systemUid',
		},
		'-N0hKQcjaPBc8ThLV49E': {
			message:
				'Awesome! Website User is going to Beginner Hip-Hop With Christina.',
			messageId: '-N0hKQcjaPBc8ThLV49E',
			timestamp: 1651101776436,
			uid: 'systemUid',
		},
		'-N0hPqpUPyYacDJk3llu': {
			message: 'Awesome! Tia Cruz is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0hPqpUPyYacDJk3llu',
			timestamp: 1651103198564,
			uid: 'systemUid',
		},
		'-N0hUDbg0GJqHLg31iJ3': {
			message:
				'Awesome! Special Guest is going to Advanced Hip-Hop With Daisy.',
			messageId: '-N0hUDbg0GJqHLg31iJ3',
			timestamp: 1651104344561,
			uid: 'systemUid',
		},
		'-N0hULpNgEqiXR8HYw5w': {
			message:
				'Awesome! Dajah Jackson is going to Advanced Hip-Hop With Daisy.',
			messageId: '-N0hULpNgEqiXR8HYw5w',
			timestamp: 1651104378207,
			uid: 'systemUid',
		},
		'-N0hnPAJs4Ey0GqHgHb7': {
			message:
				'Awesome! Kylee Latta is going to Int/Adv Contemporary With Katie.',
			messageId: '-N0hnPAJs4Ey0GqHgHb7',
			timestamp: 1651109634778,
			uid: 'systemUid',
		},
		'-N0hvhFvFMMNbPF8u3zS': {
			message:
				'Awesome! Casey Tye is going to Beg/Int Choreography With Gerald.',
			messageId: '-N0hvhFvFMMNbPF8u3zS',
			timestamp: 1651111810116,
			uid: 'systemUid',
		},
		'-N0igv1y6Kc4v_qc9G06': {
			message:
				'Awesome! Kari Edelson is going to Beg/Int Choreography With Gerald.',
			messageId: '-N0igv1y6Kc4v_qc9G06',
			timestamp: 1651124711619,
			uid: 'systemUid',
		},
		'-N0ixQcSNn8_sK_0nrAC': {
			message:
				'Awesome! Samuel Torres is going to Beginner Hip-Hop With Christina.',
			messageId: '-N0ixQcSNn8_sK_0nrAC',
			timestamp: 1651129039399,
			uid: 'systemUid',
		},
		'-N0kHZETPcUJEE4SKZxQ': {
			message:
				'Awesome! Brynna Hekeler is going to Int/Adv Contemporary With Katie.',
			messageId: '-N0kHZETPcUJEE4SKZxQ',
			timestamp: 1651151356900,
			uid: 'systemUid',
		},
		'-N0kIM9XDFbqW3dQJMrN': {
			message:
				'Awesome! Alejandro Cruz is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0kIM9XDFbqW3dQJMrN',
			timestamp: 1651151565479,
			uid: 'systemUid',
		},
		'-N0kwfKXm3Tk1iMrp9fZ': {
			message:
				'Awesome! Casey Tye is going to Beginner Street Tap with Justina.',
			messageId: '-N0kwfKXm3Tk1iMrp9fZ',
			timestamp: 1651162396009,
			uid: 'systemUid',
		},
		'-N0kwlf_P5dVT9WEbB0o': {
			message: 'Awesome! Casey Tye is going to Int/Adv Heels With Fabiana.',
			messageId: '-N0kwlf_P5dVT9WEbB0o',
			timestamp: 1651162421995,
			uid: 'systemUid',
		},
		'-N0kym0QDXsY5h72EZRY': {
			message: 'Awesome! Kailah J is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0kym0QDXsY5h72EZRY',
			timestamp: 1651162947681,
			uid: 'systemUid',
		},
		'-N0l1mRHVOSdtRLAzSJl': {
			message:
				'Awesome! Lesly Estrella is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0l1mRHVOSdtRLAzSJl',
			timestamp: 1651163997975,
			uid: 'systemUid',
		},
		'-N0lK0d5krqAXK_bD4o1': {
			message:
				'Awesome! Edele Theodore is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0lK0d5krqAXK_bD4o1',
			timestamp: 1651168778829,
			uid: 'systemUid',
		},
		'-N0lTzzTPdHuCtqVDIJ6': {
			message:
				'Awesome! Jaidalynn Alicea is going to Int/Adv Contemporary With Katie.',
			messageId: '-N0lTzzTPdHuCtqVDIJ6',
			timestamp: 1651171393510,
			uid: 'systemUid',
		},
		'-N0lfIxfGBhIHfH_QkUn': {
			message:
				'Awesome! Abena Asante is going to Beg/Int Choreography With Gerald.',
			messageId: '-N0lfIxfGBhIHfH_QkUn',
			timestamp: 1651174621042,
			uid: 'systemUid',
		},
		'-N0m06j0p1wrsmN3K_wr': {
			message:
				'Awesome! Jaidalynn Alicea is going to Int/Adv Contemporary With Katie.',
			messageId: '-N0m06j0p1wrsmN3K_wr',
			timestamp: 1651180338120,
			uid: 'systemUid',
		},
		'-N0m06kgknwIoDKTygFu': {
			message:
				'Awesome! Kylee Latta is going to Int/Adv Contemporary With Katie.',
			messageId: '-N0m06kgknwIoDKTygFu',
			timestamp: 1651180338227,
			uid: 'systemUid',
		},
		'-N0m06l0rQKrWx8B2zyt': {
			message:
				'Awesome! Brynna Hekeler is going to Int/Adv Contemporary With Katie.',
			messageId: '-N0m06l0rQKrWx8B2zyt',
			timestamp: 1651180338248,
			uid: 'systemUid',
		},
		'-N0mN3X5pAMAIdC2M5FF': {
			message:
				'Awesome! Lesly Estrella is going to Beg/Int Choreography With Gerald.',
			messageId: '-N0mN3X5pAMAIdC2M5FF',
			timestamp: 1651186354315,
			uid: 'systemUid',
		},
		'-N0mQRhTP7hQlJB4KKWK': {
			message:
				'Awesome! Edele Theodore is going to Beg/Int Choreography With Gerald.',
			messageId: '-N0mQRhTP7hQlJB4KKWK',
			timestamp: 1651187239779,
			uid: 'systemUid',
		},
		'-N0mQtLA9B9orivCFx-K': {
			message:
				'Awesome! Erika Gavino is going to Beginner Street Tap with Justina.',
			messageId: '-N0mQtLA9B9orivCFx-K',
			timestamp: 1651187357072,
			uid: 'systemUid',
		},
		'-N0mQyi3UFs5iRT-lds6': {
			message: 'Awesome! Erika Gavino is going to Int/Adv Heels With Fabiana.',
			messageId: '-N0mQyi3UFs5iRT-lds6',
			timestamp: 1651187379081,
			uid: 'systemUid',
		},
		'-N0mT9dIOUR4WmEHXttA': {
			message: 'Awesome! Kat Vargas is going to Int/Adv Heels With Fabiana.',
			messageId: '-N0mT9dIOUR4WmEHXttA',
			timestamp: 1651187952216,
			uid: 'systemUid',
		},
		'-N0mWtKgR_J6aYo9VQKp': {
			message: 'Awesome! Alex is going to Beg/Int Choreography With Gerald.',
			messageId: '-N0mWtKgR_J6aYo9VQKp',
			timestamp: 1651188929907,
			uid: 'systemUid',
		},
		'-N0nF6nllIhp7bs3Hl0-': {
			message: 'Yay! pendletonga@gmail.com has created a new account.',
			messageId: '-N0nF6nllIhp7bs3Hl0-',
			timestamp: 1651201047793,
			uid: 'systemUid',
		},
		'-N0pByJ8WCE5ozTDkpIY': {
			message:
				'Awesome! Melany Smeriglio is going to Beginner Street Tap with Justina.',
			messageId: '-N0pByJ8WCE5ozTDkpIY',
			timestamp: 1651233776912,
			uid: 'systemUid',
		},
		'-N0pC15z59Ag2rbpf_ft': {
			message:
				'Awesome! Melany Smeriglio is going to Int/Adv Heels With Fabiana.',
			messageId: '-N0pC15z59Ag2rbpf_ft',
			timestamp: 1651233792453,
			uid: 'systemUid',
		},
		'-N0pISIIHDJlKnB8iktu': {
			message:
				'Awesome! Loren Milledge is going to Int/Adv Contemporary With Emily.',
			messageId: '-N0pISIIHDJlKnB8iktu',
			timestamp: 1651235476697,
			uid: 'systemUid',
		},
		'-N0pRJ5k6OpRsFfuBSdu': {
			message:
				'Awesome! Kari Edelson is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0pRJ5k6OpRsFfuBSdu',
			timestamp: 1651237798329,
			uid: 'systemUid',
		},
		'-N0p_SR0YRFb9ial-pOZ': {
			message:
				'Awesome! Charliece Salters is going to Int/Adv Hip-Hop With Tyquise .',
			messageId: '-N0p_SR0YRFb9ial-pOZ',
			timestamp: 1651240195847,
			uid: 'systemUid',
		},
		'-N0phDqC6LFussFzOk0j': {
			message:
				'Awesome! Kari Edelson is going to Beginner Street Tap with Justina.',
			messageId: '-N0phDqC6LFussFzOk0j',
			timestamp: 1651242233235,
			uid: 'systemUid',
		},
		'-N0q9O2G1w_xDuGVM9II': {
			message: 'Awesome! Website User is going to Int/Adv Heels With Fabiana.',
			messageId: '-N0q9O2G1w_xDuGVM9II',
			timestamp: 1651249877213,
			uid: 'systemUid',
		},
		'-N0qDB2lCbXCWlEUW6lE': {
			message:
				'Awesome! Carlie O’Sullivan is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0qDB2lCbXCWlEUW6lE',
			timestamp: 1651250872567,
			uid: 'systemUid',
		},
		'-N0qRxCmvph_HiQwXH0A': {
			message: 'Awesome! Olivia Baker is going to Int/Adv Heels With Fabiana.',
			messageId: '-N0qRxCmvph_HiQwXH0A',
			timestamp: 1651254743928,
			uid: 'systemUid',
		},
		'-N0qZOPmAb2dGmLEh5rM': {
			message:
				'Awesome! Willa Santiago is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0qZOPmAb2dGmLEh5rM',
			timestamp: 1651256694457,
			uid: 'systemUid',
		},
		'-N0qqK9R96jty8-MowMb': {
			message:
				'Awesome! Alicia Koczurshaw is going to Int/Adv Heels With Fabiana.',
			messageId: '-N0qqK9R96jty8-MowMb',
			timestamp: 1651261395619,
			uid: 'systemUid',
		},
		'-N0r2z1UBVmnGacTqrZu': {
			message:
				'Awesome! Jane Torrence is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0r2z1UBVmnGacTqrZu',
			timestamp: 1651264975013,
			uid: 'systemUid',
		},
		'-N0rVj6g7P_zY-erRdWI': {
			message: 'Awesome! Kailah J is going to Int/Adv Hip-Hop With Tyquise .',
			messageId: '-N0rVj6g7P_zY-erRdWI',
			timestamp: 1651272511986,
			uid: 'systemUid',
		},
		'-N0rb-mwnvhidwWFAeYc': {
			message:
				'Awesome! Jackie Comandini is going to Int/Adv Heels With Fabiana.',
			messageId: '-N0rb-mwnvhidwWFAeYc',
			timestamp: 1651274157250,
			uid: 'systemUid',
		},
		'-N0rlcgyZm93eO68Z5D7': {
			message: 'Awesome! Test Test is going to Int/Adv Heels With Fabiana.',
			messageId: '-N0rlcgyZm93eO68Z5D7',
			timestamp: 1651276942149,
			uid: 'systemUid',
		},
		'-N0rlci8phn28VyHSgaW': {
			message: 'Awesome! Test Test is going to Int/Adv Heels With Fabiana.',
			messageId: '-N0rlci8phn28VyHSgaW',
			timestamp: 1651276942224,
			uid: 'systemUid',
		},
		'-N0rlckx2srD9uIx0Qk3': {
			message: 'Awesome! Test Test is going to Int/Adv Heels With Fabiana.',
			messageId: '-N0rlckx2srD9uIx0Qk3',
			timestamp: 1651276942404,
			uid: 'systemUid',
		},
		'-N0rlcy88beJRObvnWZt': {
			message: 'Awesome! TestA TestA is going to Int/Adv Heels With Fabiana.',
			messageId: '-N0rlcy88beJRObvnWZt',
			timestamp: 1651276943247,
			uid: 'systemUid',
		},
		'-N0tuyRbq8CP_l6tqjZ_': {
			message: 'Yay! Tina Torello has renewed Silver Subscription (Drop-Ins).',
			messageId: '-N0tuyRbq8CP_l6tqjZ_',
			timestamp: 1651312944940,
			uid: 'systemUid',
		},
		'-N0tuyee_Q0Q-sT5btuP': {
			message:
				'Yay! Jackie Comandini has renewed Bronze Subscription (Drop-Ins).',
			messageId: '-N0tuyee_Q0Q-sT5btuP',
			timestamp: 1651312945839,
			uid: 'systemUid',
		},
		'-N0uLbqHpyRI8i45lyip': {
			message:
				'Awesome! Carlie O’Sullivan is going to Int/Adv Heels With Fabiana.',
			messageId: '-N0uLbqHpyRI8i45lyip',
			timestamp: 1651320192409,
			uid: 'systemUid',
		},
		'-N0uN4WK_rmEWDGpfloI': {
			message:
				'Awesome! Jorge Cuscul-Lima is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0uN4WK_rmEWDGpfloI',
			timestamp: 1651320576091,
			uid: 'systemUid',
		},
		'-N0ub_ZpDSqTrraWBsI3': {
			message:
				'Awesome! Erika Gavino is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0ub_ZpDSqTrraWBsI3',
			timestamp: 1651324639546,
			uid: 'systemUid',
		},
		'-N0ubdT8eceYE84nXcD0': {
			message:
				'Awesome! Erika Gavino is going to Int/Adv Hip-Hop With Tyquise .',
			messageId: '-N0ubdT8eceYE84nXcD0',
			timestamp: 1651324655503,
			uid: 'systemUid',
		},
		'-N0uhcgncfXPTVG5-Ycm': {
			message:
				'Awesome! Taylor Burns is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0uhcgncfXPTVG5-Ycm',
			timestamp: 1651326225208,
			uid: 'systemUid',
		},
		'-N0uv7J-BsZJgHr1tSiI': {
			message:
				'Awesome! Alejandro Cruz is going to Int/Adv Hip-Hop With Tyquise .',
			messageId: '-N0uv7J-BsZJgHr1tSiI',
			timestamp: 1651329762566,
			uid: 'systemUid',
		},
		'-N0vIsQ8kUCDOTlFnP-7': {
			message:
				'Awesome! Emerelis Rohena is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0vIsQ8kUCDOTlFnP-7',
			timestamp: 1651336251088,
			uid: 'systemUid',
		},
		'-N0vP_oq0AdZDLxYrio1': {
			message:
				'Awesome! Tay Bogle is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0vP_oq0AdZDLxYrio1',
			timestamp: 1651338009917,
			uid: 'systemUid',
		},
		'-N0vPiGMX33feiXq-rd0': {
			message: 'Awesome! Tia Cruz is going to Int/Adv Hip-Hop With Tyquise .',
			messageId: '-N0vPiGMX33feiXq-rd0',
			timestamp: 1651338044508,
			uid: 'systemUid',
		},
		'-N0vVDm3v8dAYHc79TcD': {
			message:
				'Awesome! Tula Johnson is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0vVDm3v8dAYHc79TcD',
			timestamp: 1651339488398,
			uid: 'systemUid',
		},
		'-N0vVXd4Xb5wHvM8BygD': {
			message:
				'Awesome! Anjenie Hernandez  is going to Int/Adv Hip-Hop With Tyquise .',
			messageId: '-N0vVXd4Xb5wHvM8BygD',
			timestamp: 1651339569742,
			uid: 'systemUid',
		},
		'-N0vXwuMw7NgYIZS99pv': {
			message:
				'Awesome! Edele Theodore is going to Teen/Adult - Dance Team Tryouts.',
			messageId: '-N0vXwuMw7NgYIZS99pv',
			timestamp: 1651340201632,
			uid: 'systemUid',
		},
		'-N0vu_jXewij7o-cCpi8': {
			message:
				'Awesome! Dajmon Peele is going to Int/Adv Hip-Hop With Tyquise .',
			messageId: '-N0vu_jXewij7o-cCpi8',
			timestamp: 1651346398184,
			uid: 'systemUid',
		},
		'-N0wSF5wCG93SNuN6NrU': {
			message:
				'Awesome! Alicia Turney is going to MÈNTAL - Personal Dance Development.',
			messageId: '-N0wSF5wCG93SNuN6NrU',
			timestamp: 1651355484609,
			uid: 'systemUid',
		},
		'-N0wfwa1UIrm3oocZVIs': {
			message: 'Yay! ashleymwashington0@gmail.com has created a new account.',
			messageId: '-N0wfwa1UIrm3oocZVIs',
			timestamp: 1651359336834,
			uid: 'systemUid',
		},
		'-N0wnBpuxAWmFzmPGMBy': {
			message: 'Yay! Latreecesantiago@yahoo.com has created a new account.',
			messageId: '-N0wnBpuxAWmFzmPGMBy',
			timestamp: 1651361238394,
			uid: 'systemUid',
		},
		'-N0wpY4I0SOYT57mULtm': {
			message:
				'Awesome! Website User is going to Power Yoga (All-Levels) With Stefanie.',
			messageId: '-N0wpY4I0SOYT57mULtm',
			timestamp: 1651361853787,
			uid: 'systemUid',
		},
		'-N0x9sLtB3QsBIeCM4Qt': {
			message:
				'Awesome! Jackie Comandini is going to Advanced Hip-Hop With Brandon.',
			messageId: '-N0x9sLtB3QsBIeCM4Qt',
			timestamp: 1651367445952,
			uid: 'systemUid',
		},
		'-N0z3MCbYsJGnjeHQupS': {
			message:
				'Oh no! Mekyra Frison subscription: Bronze Subscription (Drop-Ins) has been cancelled. Please double check with this user to ensure cancellation was intentional.',
			messageId: '-N0z3MCbYsJGnjeHQupS',
			timestamp: 1651399291855,
			uid: 'systemUid',
		},
		'-N0zRfWRrUb9h2vQo1Bk': {
			message:
				'Awesome! Kleber Gordillo is going to Intro To Hip-Hop With Brandon.',
			messageId: '-N0zRfWRrUb9h2vQo1Bk',
			timestamp: 1651405666402,
			uid: 'systemUid',
		},
		'-N0zjqULFKZq2X2Wj2rW': {
			message:
				'Awesome! Thomas Goldbach is going to Teen/Adult Dance Team “Make-Up” Tryouts.',
			messageId: '-N0zjqULFKZq2X2Wj2rW',
			timestamp: 1651410692060,
			uid: 'systemUid',
		},
		'-N1-UHZDbcQHHptu1OLT': {
			message:
				'Awesome! Jamie Pinsonnault is going to Teen/Adult Dance Team “Make-Up” Tryouts.',
			messageId: '-N1-UHZDbcQHHptu1OLT',
			timestamp: 1651423127828,
			uid: 'systemUid',
		},
		'-N10GM7wWsLd3VEx4GiR': {
			message: 'Yay! Heather82006@icloud.com has created a new account.',
			messageId: '-N10GM7wWsLd3VEx4GiR',
			timestamp: 1651436253757,
			uid: 'systemUid',
		},
		'-N10NfrwtvnK_IL7hA4t': {
			message:
				'Awesome! Kayla Hansen is going to Intro To Hip-Hop With Brandon.',
			messageId: '-N10NfrwtvnK_IL7hA4t',
			timestamp: 1651438173698,
			uid: 'systemUid',
		},
		'-N11Y8Y4w3T2D3ExlvWy': {
			message:
				'Awesome! Thomas Goldbach is going to Teen/Adult Dance Team Tryouts.',
			messageId: '-N11Y8Y4w3T2D3ExlvWy',
			timestamp: 1651457693899,
			uid: 'systemUid',
		},
		'-N11_3J1EJqLighG6zE0': {
			message:
				'Awesome! Cheyenne Lueken is going to Teen/Adult Dance Team Tryouts.',
			messageId: '-N11_3J1EJqLighG6zE0',
			timestamp: 1651458196744,
			uid: 'systemUid',
		},
		'-N11ahfKd-CiTJM9owjm': {
			message:
				'Awesome! Anjenie Hernandez  is going to MÈNTAL - Personal Dance Development.',
			messageId: '-N11ahfKd-CiTJM9owjm',
			timestamp: 1651458628315,
			uid: 'systemUid',
		},
		'-N11jgzQ-oTK7v_KYqn1': {
			message:
				'Awesome! Heather Garzon is going to Teen/Adult Dance Team Tryouts.',
			messageId: '-N11jgzQ-oTK7v_KYqn1',
			timestamp: 1651460984802,
			uid: 'systemUid',
		},
		'-N11k1SN3VRa7wYYMSMg': {
			message:
				'Awesome! Heather Garzon is going to Teen/Adult Dance Team Tryouts.',
			messageId: '-N11k1SN3VRa7wYYMSMg',
			timestamp: 1651461072735,
			uid: 'systemUid',
		},
		'-N13D7cC0KDCDwM1ynpP': {
			message:
				'Loren Milledge subscription: Bronze Subscription (Drop-Ins) was unable to renew due to: Your card has insufficient funds. This is attempt: 1 out of 2.',
			messageId: '-N13D7cC0KDCDwM1ynpP',
			timestamp: 1651485740739,
			uid: 'systemUid',
		},
		'-N13l6y81xWyWkDwfzVE': {
			message:
				'Awesome! Kleber Gordillo is going to Teen/Adult Dance Team Tryouts.',
			messageId: '-N13l6y81xWyWkDwfzVE',
			timestamp: 1651494911887,
			uid: 'systemUid',
		},
		'-N14M32KmEltYzJWD1sj': {
			message:
				'Awesome! Destiny Lopez is going to Beginner Hip-Hop With Christina.',
			messageId: '-N14M32KmEltYzJWD1sj',
			timestamp: 1651504857308,
			uid: 'systemUid',
		},
		'-N14Me2HNeLJF7snwmkU': {
			message:
				'Awesome! Alyssa Pompei is going to MÈNTAL - Personal Dance Development.',
			messageId: '-N14Me2HNeLJF7snwmkU',
			timestamp: 1651505012951,
			uid: 'systemUid',
		},
		'-N14TruqEsD7n1CLcGn7': {
			message:
				'Awesome! Carlie O’Sullivan is going to Teen/Adult Dance Team Tryouts.',
			messageId: '-N14TruqEsD7n1CLcGn7',
			timestamp: 1651506904765,
			uid: 'systemUid',
		},
		'-N14U3bgwTnJILlyj4Ki': {
			message: 'Awesome! Tina Torello is going to Int/Adv Hip-Hop With Gerald.',
			messageId: '-N14U3bgwTnJILlyj4Ki',
			timestamp: 1651506956786,
			uid: 'systemUid',
		},
		'-N14uWphr36TzU6CylPb': {
			message:
				'Awesome! Alyssa Pompei is going to Beginner Hip-Hop With Christina.',
			messageId: '-N14uWphr36TzU6CylPb',
			timestamp: 1651514154357,
			uid: 'systemUid',
		},
		'-N14wPEpCxzL1ohGNF4v': {
			message:
				'Awesome! Mikaela Fullam is going to Teen/Adult Dance Team Tryouts.',
			messageId: '-N14wPEpCxzL1ohGNF4v',
			timestamp: 1651514647547,
			uid: 'systemUid',
		},
		'-N157tQpzqf-jL9aqIEF': {
			message: 'Awesome! Shiv Verma is going to Teen/Adult Dance Team Tryouts.',
			messageId: '-N157tQpzqf-jL9aqIEF',
			timestamp: 1651517921018,
			uid: 'systemUid',
		},
		'-N15TWB0VheOw7QgjDg9': {
			message: 'Awesome! Erika Gavino is going to Int/Adv Hip-Hop With Gerald.',
			messageId: '-N15TWB0VheOw7QgjDg9',
			timestamp: 1651523588872,
			uid: 'systemUid',
		},
		'-N15cHpN-tF1e2lYyCWX': {
			message: 'Awesome! S I N Q U E  is going to Int/Adv Hip-Hop With Gerald.',
			messageId: '-N15cHpN-tF1e2lYyCWX',
			timestamp: 1651526151519,
			uid: 'systemUid',
		},
		'-N15cHpzy4wR-k51fjtr': {
			message: 'Awesome! Amia Serio is going to Int/Adv Hip-Hop With Gerald.',
			messageId: '-N15cHpzy4wR-k51fjtr',
			timestamp: 1651526151559,
			uid: 'systemUid',
		},
		'-N15ofPz27MA0NsmiQ9N': {
			message: 'Yay! morgan31r@gmail.com has created a new account.',
			messageId: '-N15ofPz27MA0NsmiQ9N',
			timestamp: 1651529397951,
			uid: 'systemUid',
		},
		'-N15xYJAYPAqedA1hlkU': {
			message:
				'Awesome! Jane Torrence is going to Int/Adv Hip-Hop with Charliece.',
			messageId: '-N15xYJAYPAqedA1hlkU',
			timestamp: 1651531724049,
			uid: 'systemUid',
		},
		'-N163ROEBbZ4OKwaPlbB': {
			message:
				'Awesome! Karrington Trice is going to Int/Adv Hip-Hop With Gerald.',
			messageId: '-N163ROEBbZ4OKwaPlbB',
			timestamp: 1651533530711,
			uid: 'systemUid',
		},
		'-N164Dv2qbD437ZdBIE6': {
			message:
				'Awesome! Jasmin Forrest is going to Int/Adv Hip-Hop With Gerald.',
			messageId: '-N164Dv2qbD437ZdBIE6',
			timestamp: 1651533737674,
			uid: 'systemUid',
		},
		'-N16BF27nk5qPbIPKIOJ': {
			message:
				'Awesome! Errick Johnson is going to Int/Adv Hip-Hop With Gerald.',
			messageId: '-N16BF27nk5qPbIPKIOJ',
			timestamp: 1651535577294,
			uid: 'systemUid',
		},
		'-N16BFGwuvXy-j65WaB0': {
			message: 'Awesome! Tyquise is going to Int/Adv Hip-Hop With Gerald.',
			messageId: '-N16BFGwuvXy-j65WaB0',
			timestamp: 1651535578241,
			uid: 'systemUid',
		},
		'-N17QvZfOX3Lv6T82YZO': {
			message:
				'Awesome! Kari Edelson is going to Int/Adv Contemporary With Emily.',
			messageId: '-N17QvZfOX3Lv6T82YZO',
			timestamp: 1651556464948,
			uid: 'systemUid',
		},
		'-N18rV2pdor9e-QD_Hb0': {
			message:
				'Awesome! Karrington Trice is going to Int/Adv Contemporary With Emily.',
			messageId: '-N18rV2pdor9e-QD_Hb0',
			timestamp: 1651580469498,
			uid: 'systemUid',
		},
		'-N19_uNbT8smYl_dazsW': {
			message:
				'Awesome! Jess Murray is going to Int/Adv Contemporary With Emily.',
			messageId: '-N19_uNbT8smYl_dazsW',
			timestamp: 1651592635948,
			uid: 'systemUid',
		},
		'-N19iPjes8osi3j4pU_b': {
			message:
				'Awesome! Anne Kitz is going to Int/Adv Contemporary With Emily.',
			messageId: '-N19iPjes8osi3j4pU_b',
			timestamp: 1651594865648,
			uid: 'systemUid',
		},
		'-N1ADO2wpLO6wzuR2yfu': {
			message:
				'Awesome! Casey Tye is going to Int/Adv Contemporary With Emily.',
			messageId: '-N1ADO2wpLO6wzuR2yfu',
			timestamp: 1651603247361,
			uid: 'systemUid',
		},
		'-N1ADR_nW6soq6fD2GiK': {
			message: 'Awesome! Casey Tye is going to Advanced Hip-Hop With Brandon.',
			messageId: '-N1ADR_nW6soq6fD2GiK',
			timestamp: 1651603261817,
			uid: 'systemUid',
		},
		'-N1ATbFosq65cQKcTt_S': {
			message:
				'Awesome! Avery Clouse is going to Int/Adv Contemporary With Emily.',
			messageId: '-N1ATbFosq65cQKcTt_S',
			timestamp: 1651607499834,
			uid: 'systemUid',
		},
		'-N1AieIzRfKTx7K5OC7u': {
			message:
				'Awesome! Megan Drabant is going to Int/Adv Contemporary With Emily.',
			messageId: '-N1AieIzRfKTx7K5OC7u',
			timestamp: 1651611706628,
			uid: 'systemUid',
		},
		'-N1AmLnMwSl7HwJUtqkM': {
			message:
				'Awesome! Mikaela Fullam is going to Int/Adv Contemporary With Emily.',
			messageId: '-N1AmLnMwSl7HwJUtqkM',
			timestamp: 1651612675295,
			uid: 'systemUid',
		},
		'-N1B5m6KPFuJltsQShG4': {
			message:
				'Awesome! Isabella Savona is going to Advanced Hip-Hop With Brandon.',
			messageId: '-N1B5m6KPFuJltsQShG4',
			timestamp: 1651618030043,
			uid: 'systemUid',
		},
		'-N1B6GFzb5isd-XE5XWc': {
			message:
				'Awesome! Taelyn Richards is going to Advanced Hip-Hop With Brandon.',
			messageId: '-N1B6GFzb5isd-XE5XWc',
			timestamp: 1651618157638,
			uid: 'systemUid',
		},
		'-N1BCWD_gCO98OBq30U0': {
			message:
				'Awesome! Christian Yelling is going to Beginner Hip-Hop With Christina.',
			messageId: '-N1BCWD_gCO98OBq30U0',
			timestamp: 1651619795884,
			uid: 'systemUid',
		},
		'-N1BHOJZYEC1sdBC1rBX': {
			message:
				'Awesome! Eishell Ruiz-Mendoza is going to Intermediate Hip-Hop With Maddie And Anjiee.',
			messageId: '-N1BHOJZYEC1sdBC1rBX',
			timestamp: 1651621074218,
			uid: 'systemUid',
		},
		'-N1CTh8muNMKwDR3zX7x': {
			message:
				'Awesome! Loren Milledge is going to Int/Adv Contemporary With Emily.',
			messageId: '-N1CTh8muNMKwDR3zX7x',
			timestamp: 1651641078391,
			uid: 'systemUid',
		},
		'-N1DWDZCiTLsUVTNB9Ea': {
			message: 'Yay! Erika Gavino has renewed Silver Subscription (Drop-Ins).',
			messageId: '-N1DWDZCiTLsUVTNB9Ea',
			timestamp: 1651658516754,
			uid: 'systemUid',
		},
		'-N1E9yXVr1EA22ERczSK': {
			message:
				'Awesome! Aniya Robbins is going to Beg/Int Choreography With Gerald.',
			messageId: '-N1E9yXVr1EA22ERczSK',
			timestamp: 1651669461179,
			uid: 'systemUid',
		},
		'-N1EOKJSU79e3eZ0Ctoc': {
			message:
				'Awesome! Kayla Hansen is going to Beg/Int Choreography With Gerald.',
			messageId: '-N1EOKJSU79e3eZ0Ctoc',
			timestamp: 1651673224487,
			uid: 'systemUid',
		},
		'-N1Ee93AUl3HOdFeZh3n': {
			message: 'Mathew Sebastian has purchased Bronze Subscription (Drop-Ins)!',
			messageId: '-N1Ee93AUl3HOdFeZh3n',
			timestamp: 1651677634933,
			uid: 'systemUid',
		},
		'-N1Ee9X7yXE5acDnzdeY': {
			message: 'Mathew Sebastian has purchased Bronze Subscription (Drop-Ins)!',
			messageId: '-N1Ee9X7yXE5acDnzdeY',
			timestamp: 1651677636749,
			uid: 'systemUid',
		},
		'-N1EeM8PJtCejhureReJ': {
			message:
				'Awesome! Mathew Sebastian is going to Beg/Int Choreography With Gerald.',
			messageId: '-N1EeM8PJtCejhureReJ',
			timestamp: 1651677688417,
			uid: 'systemUid',
		},
		'-N1ErM0XbpCSXIKlJboc': {
			message:
				'Awesome! Siana Garcia is going to Int/Adv Hip-Hop with Charliece.',
			messageId: '-N1ErM0XbpCSXIKlJboc',
			timestamp: 1651681095783,
			uid: 'systemUid',
		},
		'-N1Fk7e-3RlB0sHWhBIJ': {
			message: 'Awesome! Kailah J is going to Int/Adv Hip-Hop with Charliece.',
			messageId: '-N1Fk7e-3RlB0sHWhBIJ',
			timestamp: 1651695979142,
			uid: 'systemUid',
		},
		'-N1G1LrfHlAxpagEdBvr': {
			message: 'Awesome! Alex is going to Beg/Int Choreography With Gerald.',
			messageId: '-N1G1LrfHlAxpagEdBvr',
			timestamp: 1651700755954,
			uid: 'systemUid',
		},
		'-N1GKXwdFyojq5sq39EW': {
			message:
				'Awesome! Maddie Moore  is going to Int/Adv Hip-Hop with Charliece.',
			messageId: '-N1GKXwdFyojq5sq39EW',
			timestamp: 1651705786160,
			uid: 'systemUid',
		},
		'-N1GNhXpUXqluoYsWyDM': {
			message:
				'Awesome! Chantal Edwards Matthews is going to Int/Adv Hip-Hop with Charliece.',
			messageId: '-N1GNhXpUXqluoYsWyDM',
			timestamp: 1651706615995,
			uid: 'systemUid',
		},
		'-N1GNwZt5EXrJD4907Fb': {
			message:
				'Awesome! Errick Johnson is going to Int/Adv Hip-Hop with Charliece.',
			messageId: '-N1GNwZt5EXrJD4907Fb',
			timestamp: 1651706677567,
			uid: 'systemUid',
		},
		'-N1GRlv0mZDg3X8z8I6R': {
			message:
				'Awesome! Ivory McCulley is going to Int/Adv Hip-Hop with Charliece.',
			messageId: '-N1GRlv0mZDg3X8z8I6R',
			timestamp: 1651707682505,
			uid: 'systemUid',
		},
		'-N1GUSGDtW1DiyBjL45b': {
			message:
				'Awesome! Tina Torello is going to Int/Adv Hip-Hop with Charliece.',
			messageId: '-N1GUSGDtW1DiyBjL45b',
			timestamp: 1651708384341,
			uid: 'systemUid',
		},
		'-N1GXjaKxDPow_6Ykn6o': {
			message: 'Yay! avam89256@gmail.com has created a new account.',
			messageId: '-N1GXjaKxDPow_6Ykn6o',
			timestamp: 1651709245845,
			uid: 'systemUid',
		},
		'-N1GpzO0p-w5zJ663QQZ': {
			message: 'Yay! guarinotina@yahoo.com has created a new account.',
			messageId: '-N1GpzO0p-w5zJ663QQZ',
			timestamp: 1651714291266,
			uid: 'systemUid',
		},
		'-N1Gs8UMvP2zHvFqB8mR': {
			message: 'Yay! maimar_maidmar@yahoo.com has created a new account.',
			messageId: '-N1Gs8UMvP2zHvFqB8mR',
			timestamp: 1651714856926,
			uid: 'systemUid',
		},
		'-N1GtEnQoKvIZy6a4Fm6': {
			message:
				'Awesome! Mary Maida is going to Intermediate Hip-Hop With Maddie And Anjiee.',
			messageId: '-N1GtEnQoKvIZy6a4Fm6',
			timestamp: 1651715144928,
			uid: 'systemUid',
		},
		'-N1Gyg0ZzViKTBLER6TK': {
			message:
				'Awesome! Website User is going to Intermediate Hip-Hop With Maddie And Anjiee.',
			messageId: '-N1Gyg0ZzViKTBLER6TK',
			timestamp: 1651716571242,
			uid: 'systemUid',
		},
		'-N1GyvUkJnR1AKDu41qJ': {
			message:
				'Awesome! Tina Guarino is going to Intermediate Hip-Hop With Maddie And Anjiee.',
			messageId: '-N1GyvUkJnR1AKDu41qJ',
			timestamp: 1651716634614,
			uid: 'systemUid',
		},
		'-N1H_0gu1DHUM8h1lJyW': {
			message:
				'Awesome! Erika Gavino is going to Int/Adv Hip-Hop With Tyquise.',
			messageId: '-N1H_0gu1DHUM8h1lJyW',
			timestamp: 1651726621505,
			uid: 'systemUid',
		},
		'-N1JOMcDxUhr4yISlrM6': {
			message:
				'Awesome! Allison Sprafke is going to Intermediate Hip-Hop With Maddie And Anjiee.',
			messageId: '-N1JOMcDxUhr4yISlrM6',
			timestamp: 1651757120020,
			uid: 'systemUid',
		},
		'-N1JORS7ldIKv-Vk6-9k': {
			message:
				'Awesome! Allison Sprafke is going to Int/Adv Heels With Fabiana.',
			messageId: '-N1JORS7ldIKv-Vk6-9k',
			timestamp: 1651757139790,
			uid: 'systemUid',
		},
		'-N1JQX6RnKrJ7xTQgpEp': {
			message:
				'Awesome! Izabela Martin is going to Beginner Hip-Hop With Christina.',
			messageId: '-N1JQX6RnKrJ7xTQgpEp',
			timestamp: 1651757687266,
			uid: 'systemUid',
		},
		'-N1JUAS8sM_dkmXnNmCt': {
			message:
				'Awesome! Taira Cominsky is going to Beginner Hip-Hop With Christina.',
			messageId: '-N1JUAS8sM_dkmXnNmCt',
			timestamp: 1651758643023,
			uid: 'systemUid',
		},
		'-N1JYXZhCrLMO0rx5mve': {
			message:
				'Awesome! Mathew Sebastian is going to Beginner Hip-Hop With Christina.',
			messageId: '-N1JYXZhCrLMO0rx5mve',
			timestamp: 1651759786291,
			uid: 'systemUid',
		},
		'-N1Jg02NpFPpVUep2I1V': {
			message:
				'Awesome! Ashley Naomi is going to MÈNTAL - Personal Dance Development.',
			messageId: '-N1Jg02NpFPpVUep2I1V',
			timestamp: 1651762008287,
			uid: 'systemUid',
		},
		'-N1K-2KcPWS4Ry5by7FD': {
			message: 'Siana Garcia has purchased Bronze Subscription (Drop-Ins)!',
			messageId: '-N1K-2KcPWS4Ry5by7FD',
			timestamp: 1651767260526,
			uid: 'systemUid',
		},
		'-N1K-2KkFc563ZriB2NJ': {
			message: 'Siana Garcia has purchased Bronze Subscription (Drop-Ins)!',
			messageId: '-N1K-2KkFc563ZriB2NJ',
			timestamp: 1651767260534,
			uid: 'systemUid',
		},
		'-N1K-Cl5RrLYcPOP9lOC': {
			message: 'Awesome! Siana Garcia is going to Int/Adv Hip-Hop With Sinque.',
			messageId: '-N1K-Cl5RrLYcPOP9lOC',
			timestamp: 1651767303245,
			uid: 'systemUid',
		},
		'-N1KVfbpSZh8CJ15_nzv': {
			message:
				'Awesome! Anjenie Hernandez  is going to Int/Adv Hip-Hop With Tyquise.',
			messageId: '-N1KVfbpSZh8CJ15_nzv',
			timestamp: 1651775814139,
			uid: 'systemUid',
		},
		'-N1KZg9HRxyZf64yr-GW': {
			message:
				'Awesome! Jamie Pinsonnault is going to Beginner Hip-Hop With Christina.',
			messageId: '-N1KZg9HRxyZf64yr-GW',
			timestamp: 1651776864920,
			uid: 'systemUid',
		},
		'-N1L-ccUVPu-sqosKY8c': {
			message:
				'Awesome! Katelin Adintori is going to Int/Adv Hip-Hop With Tyquise.',
			messageId: '-N1L-ccUVPu-sqosKY8c',
			timestamp: 1651784190501,
			uid: 'systemUid',
		},
		'-N1L0flsMJTeRuWQhvae': {
			message:
				'Awesome! Christian Reyes is going to Beginner Hip-Hop With Christina.',
			messageId: '-N1L0flsMJTeRuWQhvae',
			timestamp: 1651784465534,
			uid: 'systemUid',
		},
		'-N1L43-7ZR6ydbbDZPUY': {
			message:
				'Awesome! Jane Torrence is going to Int/Adv Hip-Hop With Tyquise.',
			messageId: '-N1L43-7ZR6ydbbDZPUY',
			timestamp: 1651785351183,
			uid: 'systemUid',
		},
		'-N1LEoLDBsnaGwC02rd2': {
			message:
				'Awesome! Mya Messino is going to Beginner Hip-Hop With Christina.',
			messageId: '-N1LEoLDBsnaGwC02rd2',
			timestamp: 1651788170644,
			uid: 'systemUid',
		},
		'-N1LFqh2ymTDvywrTB4H': {
			message: 'Yay! trainwithchristie@gmail.com has created a new account.',
			messageId: '-N1LFqh2ymTDvywrTB4H',
			timestamp: 1651788442435,
			uid: 'systemUid',
		},
		'-N1LH3mSJR5JWtd29LzT': {
			message:
				'Awesome! Christie Roberts is going to Beginner Hip-Hop With Christina.',
			messageId: '-N1LH3mSJR5JWtd29LzT',
			timestamp: 1651788762275,
			uid: 'systemUid',
		},
		'-N1LI5cWk7yPDjGstSrM': {
			message:
				'Awesome! Dajmon Peele is going to Int/Adv Hip-Hop With Tyquise.',
			messageId: '-N1LI5cWk7yPDjGstSrM',
			timestamp: 1651789031974,
			uid: 'systemUid',
		},
		'-N1LUhlcih97_dvUWlhg': {
			message:
				'Awesome! Lindsay Frank is going to Int/Adv Hip-Hop With Tyquise.',
			messageId: '-N1LUhlcih97_dvUWlhg',
			timestamp: 1651792338029,
			uid: 'systemUid',
		},
		'-N1LcQJUJWVc7rd25wY-': {
			message: 'Awesome! Tia Cruz is going to Int/Adv Hip-Hop With Tyquise.',
			messageId: '-N1LcQJUJWVc7rd25wY-',
			timestamp: 1651794621733,
			uid: 'systemUid',
		},
		'-N1LfR9lA-sYSbMhdLeM': {
			message:
				'Awesome! Errick Johnson is going to Int/Adv Hip-Hop With Tyquise.',
			messageId: '-N1LfR9lA-sYSbMhdLeM',
			timestamp: 1651795411639,
			uid: 'systemUid',
		},
		'-N1LfmFNibcV4dpC5Z-g': {
			message:
				'Awesome! Alejandro Cruz is going to Int/Adv Hip-Hop With Tyquise.',
			messageId: '-N1LfmFNibcV4dpC5Z-g',
			timestamp: 1651795502110,
			uid: 'systemUid',
		},
		'-N1MMYmKtMkuhrPrn3CK': {
			message: 'Yay! Jaydavaughn@gmail.com has created a new account.',
			messageId: '-N1MMYmKtMkuhrPrn3CK',
			timestamp: 1651806977173,
			uid: 'systemUid',
		},
		'-N1N2tNrPvm0osP4KFN2': {
			message:
				'Awesome! Website User is going to Intro To Hip-Hop With Brandon.',
			messageId: '-N1N2tNrPvm0osP4KFN2',
			timestamp: 1651818599998,
			uid: 'systemUid',
		},
		'-N1O4RDA5-exi5uDpluq': {
			message: 'Awesome! Casey Tye is going to Int/Adv Heels With Fabiana.',
			messageId: '-N1O4RDA5-exi5uDpluq',
			timestamp: 1651835782033,
			uid: 'systemUid',
		},
		'-N1Objz0VUK-vLJln-RJ': {
			message:
				'Awesome! Melany Smeriglio is going to Int/Adv Heels With Fabiana.',
			messageId: '-N1Objz0VUK-vLJln-RJ',
			timestamp: 1651844775880,
			uid: 'systemUid',
		},
		'-N1PioRwFnUJ7n31Ap0c': {
			message:
				'Awesome! Lesly Estrella is going to Intermediate Hip-Hop With Maddie And Anjiee.',
			messageId: '-N1PioRwFnUJ7n31Ap0c',
			timestamp: 1651863406403,
			uid: 'systemUid',
		},
		'-N1PvrMrL5f5mVRbZQE5': {
			message:
				'Awesome! Tiana Vazquez-Ford is going to Int/Adv Heels With Fabiana.',
			messageId: '-N1PvrMrL5f5mVRbZQE5',
			timestamp: 1651866826243,
			uid: 'systemUid',
		},
		'-N1QR3cJbI7ngvMuL76d': {
			message:
				'Awesome! Aj Montgomery is going to Intermediate Hip-Hop With Maddie And Anjiee.',
			messageId: '-N1QR3cJbI7ngvMuL76d',
			timestamp: 1651875269147,
			uid: 'systemUid',
		},
		'-N1QRDOOBGjBRncB66gx': {
			message:
				'Awesome! Chantal Edwards Matthews is going to Intermediate Hip-Hop With Maddie And Anjiee.',
			messageId: '-N1QRDOOBGjBRncB66gx',
			timestamp: 1651875309153,
			uid: 'systemUid',
		},
		'-N1QRKBBh-OxoobqkF5R': {
			message:
				'Awesome! Test Test is going to Intermediate Hip-Hop With Maddie And Anjiee.',
			messageId: '-N1QRKBBh-OxoobqkF5R',
			timestamp: 1651875336978,
			uid: 'systemUid',
		},
		'-N1QS2xPgtBVepXhmByM': {
			message:
				'Awesome! TestA TestA is going to Intermediate Hip-Hop With Maddie And Anjiee.',
			messageId: '-N1QS2xPgtBVepXhmByM',
			timestamp: 1651875528544,
			uid: 'systemUid',
		},
		'-N1QVvJltN9AnkdtktL_': {
			message:
				'Awesome! Jackie Comandini is going to Int/Adv Heels With Fabiana.',
			messageId: '-N1QVvJltN9AnkdtktL_',
			timestamp: 1651876541751,
			uid: 'systemUid',
		},
		'-N1QeLt4MFynSL9LeT-P': {
			message:
				'Awesome! Meghan Diamond is going to Int/Adv Heels With Fabiana.',
			messageId: '-N1QeLt4MFynSL9LeT-P',
			timestamp: 1651879013963,
			uid: 'systemUid',
		},
		'-N1QeVXiWwu4oDnYUg3k': {
			message: 'Yay! kbolling321@gmail.com has created a new account.',
			messageId: '-N1QeVXiWwu4oDnYUg3k',
			timestamp: 1651879053487,
			uid: 'systemUid',
		},
		'-N1QeecFxB5ZBeuPuR2N': {
			message:
				'Awesome! Karina Bolling is going to Int/Adv Heels With Fabiana.',
			messageId: '-N1QeecFxB5ZBeuPuR2N',
			timestamp: 1651879094806,
			uid: 'systemUid',
		},
		'-N1ToQdFrMO31EaEnKaq': {
			message:
				'Awesome! Anjenie Hernandez  is going to Int/Adv Hip-Hop With Cheddi + Charliece.',
			messageId: '-N1ToQdFrMO31EaEnKaq',
			timestamp: 1651931986519,
			uid: 'systemUid',
		},
		'-N1Tsg7dWWAQkZ-yas3S': {
			message:
				'Awesome! Juliana Oliveras is going to MÈNTAL - Personal Dance Development.',
			messageId: '-N1Tsg7dWWAQkZ-yas3S',
			timestamp: 1651933102639,
			uid: 'systemUid',
		},
		'-N1UBqW95SI1rNPBcdUI': {
			message:
				'Awesome! Kerrin Massey is going to Int/Adv Hip-Hop With Cheddi + Charliece.',
			messageId: '-N1UBqW95SI1rNPBcdUI',
			timestamp: 1651938388050,
			uid: 'systemUid',
		},
		'-N1UPEETh6GhKvOlRnI1': {
			message:
				'Awesome! Jane Torrence is going to MÈNTAL - Personal Dance Development.',
			messageId: '-N1UPEETh6GhKvOlRnI1',
			timestamp: 1651941897188,
			uid: 'systemUid',
		},
		'-N1VCgRH8TjvgvYL1Ksh': {
			message:
				'Awesome! Taylor Burns is going to Int/Adv Hip-Hop With Cheddi + Charliece.',
			messageId: '-N1VCgRH8TjvgvYL1Ksh',
			timestamp: 1651955386136,
			uid: 'systemUid',
		},
		'-N1VFmuhEE4FwYrNH40J': {
			message:
				'Awesome! Tina Torello is going to Int/Adv Hip-Hop With Cheddi + Charliece.',
			messageId: '-N1VFmuhEE4FwYrNH40J',
			timestamp: 1651956199091,
			uid: 'systemUid',
		},
		'-N1VdOeYINs01dcKJEeB': {
			message:
				'Awesome! Dajmon Peele is going to Int/Adv Hip-Hop With Cheddi + Charliece.',
			messageId: '-N1VdOeYINs01dcKJEeB',
			timestamp: 1651962649258,
			uid: 'systemUid',
		},
		'-N1VfnvvEXFk6iHFunQz': {
			message:
				'Awesome! Alejandro Cruz is going to Int/Adv Hip-Hop With Cheddi + Charliece.',
			messageId: '-N1VfnvvEXFk6iHFunQz',
			timestamp: 1651963281154,
			uid: 'systemUid',
		},
		'-N1_eiW5ob984h5obe2r': {
			message: 'Yay! brnr707@gmail.com has created a new account.',
			messageId: '-N1_eiW5ob984h5obe2r',
			timestamp: 1652046882887,
			uid: 'systemUid',
		},
		'-N1_hvMg6_AIqsCFDKiu': {
			message:
				'Awesome! Website User is going to MÈNTAL - Personal Dance Development.',
			messageId: '-N1_hvMg6_AIqsCFDKiu',
			timestamp: 1652047721971,
			uid: 'systemUid',
		},
		'-N1dRRKfIg5LkYCbvAGj': {
			message: 'Awesome! Website User is going to Int/Adv Musical Theater.',
			messageId: '-N1dRRKfIg5LkYCbvAGj',
			timestamp: 1652110247282,
			uid: 'systemUid',
		},
		'-N1dSbWVjSWt689ye38g': {
			message: 'Awesome! Kleber Gordillo is going to Beg/Int Hip-Hop.',
			messageId: '-N1dSbWVjSWt689ye38g',
			timestamp: 1652110555239,
			uid: 'systemUid',
		},
		'-N1dWSdQvKIeBx1WmqG_': {
			message: 'Awesome! Demoya Kelly is going to Intro To Hip-Hop.',
			messageId: '-N1dWSdQvKIeBx1WmqG_',
			timestamp: 1652111563362,
			uid: 'systemUid',
		},
		'-N1db9qTlXVqFfg3k1ul': {
			message: 'Awesome! Demoya Kelly is going to Beg/Int Hip-Hop.',
			messageId: '-N1db9qTlXVqFfg3k1ul',
			timestamp: 1652113059237,
			uid: 'systemUid',
		},
		'-N1dbcO8XoETGSGXE4Lt': {
			message: 'Yay! hannahflinter@gmail.com has created a new account.',
			messageId: '-N1dbcO8XoETGSGXE4Lt',
			timestamp: 1652113180233,
			uid: 'systemUid',
		},
		'-N1dmgeKpoMWPD3TdFAB': {
			message: 'Awesome! Hannah Flinter is going to Intro To Hip-Hop.',
			messageId: '-N1dmgeKpoMWPD3TdFAB',
			timestamp: 1652116081308,
			uid: 'systemUid',
		},
		'-N1drynRJF2qPGTwNvJW': {
			message: 'Awesome! Jamie Pinsonnault is going to Int/Adv R&B Grooves.',
			messageId: '-N1drynRJF2qPGTwNvJW',
			timestamp: 1652117466339,
			uid: 'systemUid',
		},
		'-N1e6YbK10hQKATRLVwP': {
			message: 'Yay! jglatt44@gmail.com has created a new account.',
			messageId: '-N1e6YbK10hQKATRLVwP',
			timestamp: 1652121549269,
			uid: 'systemUid',
		},
		'-N1eUtBRqJw9QHZCxxcY': {
			message:
				'Awesome! Tina Torello is going to Advanced Choreography With Errick.',
			messageId: '-N1eUtBRqJw9QHZCxxcY',
			timestamp: 1652127929123,
			uid: 'systemUid',
		},
		'-N1eYLWR4gIvPNIN4AMR': {
			message: 'Awesome! Erika Gavino is going to Int/Adv R&B Grooves.',
			messageId: '-N1eYLWR4gIvPNIN4AMR',
			timestamp: 1652128835682,
			uid: 'systemUid',
		},
		'-N1edwY8FabmV0f1Df-7': {
			message:
				'Awesome! Jane Torrence is going to Advanced Choreography With Errick.',
			messageId: '-N1edwY8FabmV0f1Df-7',
			timestamp: 1652130564302,
			uid: 'systemUid',
		},
		'-N1eem8QHpXcWR45xvg8': {
			message:
				'Awesome! Hannah Gluck is going to Advanced Choreography With Errick.',
			messageId: '-N1eem8QHpXcWR45xvg8',
			timestamp: 1652130783841,
			uid: 'systemUid',
		},
		'-N1efmhzci8xjSRb1HcF': {
			message:
				'Awesome! Erika Gavino is going to Advanced Choreography With Errick.',
			messageId: '-N1efmhzci8xjSRb1HcF',
			timestamp: 1652131048324,
			uid: 'systemUid',
		},
		'-N1egALMgDw85kVKNyE-': {
			message:
				'Awesome! Simone Mcrae is going to Advanced Choreography With Errick.',
			messageId: '-N1egALMgDw85kVKNyE-',
			timestamp: 1652131149212,
			uid: 'systemUid',
		},
		'-N1ek6gVkgDzjvsPULRy': {
			message:
				'Awesome! Charliece Salters is going to Advanced Choreography With Errick.',
			messageId: '-N1ek6gVkgDzjvsPULRy',
			timestamp: 1652132182823,
			uid: 'systemUid',
		},
		'-N1enD-zqnDvryBT4_jm': {
			message:
				'Awesome! Chantal Edwards Matthews is going to Advanced Choreography With Errick.',
			messageId: '-N1enD-zqnDvryBT4_jm',
			timestamp: 1652132995141,
			uid: 'systemUid',
		},
		'-N1f9oJ1hlD-jP8OBdMx': {
			message: 'Awesome! mason hemstock is going to Intro To Hip-Hop.',
			messageId: '-N1f9oJ1hlD-jP8OBdMx',
			timestamp: 1652139181320,
			uid: 'systemUid',
		},
		'-N1h_pJgC0863y3ZwhXG': {
			message: 'Awesome! Izabela Martin is going to Intro To Hip-Hop.',
			messageId: '-N1h_pJgC0863y3ZwhXG',
			timestamp: 1652179817781,
			uid: 'systemUid',
		},
		'-N1iHl0r9HDTNz8q_LPS': {
			message: 'Awesome! Mathew Sebastian is going to Intro To Hip-Hop.',
			messageId: '-N1iHl0r9HDTNz8q_LPS',
			timestamp: 1652191596669,
			uid: 'systemUid',
		},
		'-N1iHpB6l8tKP993zTeT': {
			message: 'Awesome! Mathew Sebastian is going to Beg/Int Hip-Hop.',
			messageId: '-N1iHpB6l8tKP993zTeT',
			timestamp: 1652191613753,
			uid: 'systemUid',
		},
		'-N1ixOVlgGDz3K5ITzw6': {
			message:
				'Awesome! Akira Rodriguez is going to Advanced Choreography With Jaspar.',
			messageId: '-N1ixOVlgGDz3K5ITzw6',
			timestamp: 1652202772537,
			uid: 'systemUid',
		},
		'-N1j3adhOGL7WNCaoLPt': {
			message: 'Awesome! Aniya Robbins is going to Intro To Hip-Hop.',
			messageId: '-N1j3adhOGL7WNCaoLPt',
			timestamp: 1652204661363,
			uid: 'systemUid',
		},
		'-N1j9jhXYKOV3KmI2Bsu': {
			message: 'Awesome! Website User is going to Beg/Int Hip-Hop.',
			messageId: '-N1j9jhXYKOV3KmI2Bsu',
			timestamp: 1652206271337,
			uid: 'systemUid',
		},
		'-N1jJewD2zky9UucI5qJ': {
			message: 'Awesome! Tina Torello is going to Beg/Int Hip-Hop.',
			messageId: '-N1jJewD2zky9UucI5qJ',
			timestamp: 1652208873236,
			uid: 'systemUid',
		},
		'-N1jpAEzKKVX7dBG9Q8B': {
			message: 'Awesome! Christie Roberts is going to Intro To Hip-Hop.',
			messageId: '-N1jpAEzKKVX7dBG9Q8B',
			timestamp: 1652217394182,
			uid: 'systemUid',
		},
		'-N1jsfQPPHIKr7jLZOhH': {
			message:
				'Awesome! Carlie O’Sullivan is going to Beginner Street Tap With Justina.',
			messageId: '-N1jsfQPPHIKr7jLZOhH',
			timestamp: 1652218312421,
			uid: 'systemUid',
		},
		'-N1jwzWptjXSun0DDsYd': {
			message: 'Awesome! Test Test is going to Intro To Hip-Hop.',
			messageId: '-N1jwzWptjXSun0DDsYd',
			timestamp: 1652219443322,
			uid: 'systemUid',
		},
		'-N1k3cb6i3dbIc2TALyv': {
			message: 'Awesome! Test Test is going to Intro To Hip-Hop.',
			messageId: '-N1k3cb6i3dbIc2TALyv',
			timestamp: 1652221446605,
			uid: 'systemUid',
		},
		'-N1k5BA8Y1A2oYCneFHd': {
			message: 'Awesome! Dallas Brown is going to Intro To Hip-Hop.',
			messageId: '-N1k5BA8Y1A2oYCneFHd',
			timestamp: 1652221854415,
			uid: 'systemUid',
		},
		'-N1kFTQmELP9eXZOdqxR': {
			message: 'Awesome! Vu Tran is going to Beg/Int Hip-Hop.',
			messageId: '-N1kFTQmELP9eXZOdqxR',
			timestamp: 1652224550649,
			uid: 'systemUid',
		},
		'-N1kT94TLTCd4tI-5uoO': {
			message: 'Awesome! Charliece Salters is going to Int/Adv R&B Grooves.',
			messageId: '-N1kT94TLTCd4tI-5uoO',
			timestamp: 1652228137316,
			uid: 'systemUid',
		},
		'-N1kTw-WqImENeUfK770': {
			message: 'Awesome! Dallas Brown is going to Beg/Int Hip-Hop.',
			messageId: '-N1kTw-WqImENeUfK770',
			timestamp: 1652228341799,
			uid: 'systemUid',
		},
		'-N1o5IsOYN5efPQ6B2Jo': {
			message: 'Awesome! Tia Cruz is going to Int/Adv R&B Grooves.',
			messageId: '-N1o5IsOYN5efPQ6B2Jo',
			timestamp: 1652288994848,
			uid: 'systemUid',
		},
		'-N1oGZS8NNjBvdV2G-GC': {
			message:
				'Awesome! Alex Woznyk is going to Advanced Choreography With Jaspar.',
			messageId: '-N1oGZS8NNjBvdV2G-GC',
			timestamp: 1652291946320,
			uid: 'systemUid',
		},
		'-N1oIQPFWJZ-6uLzhXpX': {
			message: 'Yay! ginad_619@yahoo.com has created a new account.',
			messageId: '-N1oIQPFWJZ-6uLzhXpX',
			timestamp: 1652292433553,
			uid: 'systemUid',
		},
		'-N1oJ9etH-XAOCmHhGa1': {
			message: 'Yay! nenaa_08@yahoo.com has created a new account.',
			messageId: '-N1oJ9etH-XAOCmHhGa1',
			timestamp: 1652292627136,
			uid: 'systemUid',
		},
		'-N1oq16hM78WhNmfbvQv': {
			message:
				'Awesome! Chantal Edwards Matthews is going to Int/Adv R&B Grooves.',
			messageId: '-N1oq16hM78WhNmfbvQv',
			timestamp: 1652301505016,
			uid: 'systemUid',
		},
		'-N1pIfKxVotx3QhJAIRo': {
			message: 'Awesome! Tina Torello is going to Int/Adv R&B Grooves.',
			messageId: '-N1pIfKxVotx3QhJAIRo',
			timestamp: 1652309276035,
			uid: 'systemUid',
		},
		'-N1pUx-IAtYyLgnNc816': {
			message: 'Yay! aziastreater@gmail.com has created a new account.',
			messageId: '-N1pUx-IAtYyLgnNc816',
			timestamp: 1652312494100,
			uid: 'systemUid',
		},
		'-N1pV5RRYTcSiL5_zM6t': {
			message: 'Awesome! Karrington Trice is going to Int/Adv R&B Grooves.',
			messageId: '-N1pV5RRYTcSiL5_zM6t',
			timestamp: 1652312532771,
			uid: 'systemUid',
		},
		'-N1pVxTLLRDI_jsR0Ce3': {
			message: 'Awesome! Azia Streater is going to Int/Adv Sensual Movement.',
			messageId: '-N1pVxTLLRDI_jsR0Ce3',
			timestamp: 1652312758172,
			uid: 'systemUid',
		},
		'-N1pWmhnKrEge4YtJ1oQ': {
			message: 'Awesome! Alejandro Cruz is going to Int/Adv R&B Grooves.',
			messageId: '-N1pWmhnKrEge4YtJ1oQ',
			timestamp: 1652312976249,
			uid: 'systemUid',
		},
		'-N1swUeVngYcPqZyvu67': {
			message: 'Awesome! Website User is going to Int/Adv K-Pop.',
			messageId: '-N1swUeVngYcPqZyvu67',
			timestamp: 1652370307750,
			uid: 'systemUid',
		},
	},
];
