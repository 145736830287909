<template>
	<div>
		<DashboardHeaderVue :title="$route.name" :buttonItems="buttonItems" />
	</div>
</template>
<script>
import DashboardHeaderVue from '../HeaderFooterComponents/DashboardHeader.vue';

export default {
	name: 'billing',
	components: {
		DashboardHeaderVue,
	},
	data() {
		return {
			buttonItems: [
				{
					index: 0,
					title: 'New Coupons',
					type: 'primary',
					icon: 'fas fa-plus',
				},
			],
		};
	},
};
</script>
